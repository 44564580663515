@import '../utils/functions';
@import '../base/macros';

.simple-image-slider {
  &__images-container {
    @extend %no-scrollbar;

    height: 100%;
    overflow-x: auto;
  }

  &__images-wrapper {
    height: 100%;
    display: flex;
    position: relative;
    transition: transform 0.5s ease-in-out;
    gap: clamp-it(0.625rem, 0.7rem);
  }

  &__image {
    flex: 0 0 auto;
    object-fit: cover;
    position: relative !important;
    height: 100%;
    width: auto;
  }
}
