@import '../utils/functions';
@import './search';

$flexGap: clamp-it(0.5rem, 1.2rem);
$pillMarginBottom: clamp-it(0.75rem, 1.4rem);

.search-for-results {
  position: relative;

  &__collapse {
    background-color: $white;
    box-shadow: 0 7px 15px -4px $gray-600;
    margin-top: clamp-it(0.85rem, 1.35rem);
    position: absolute;
    z-index: 100;
    left: -1.5rem;
    width: calc(100% + 3rem);

    &--single {
      margin-top: clamp-it(0.85rem, 1.35rem);
      position: absolute;
      z-index: 100;
      box-shadow: 0 3px 5px -1px $gray-600;
      background-color: $white;
      left: 0;
      top: 100%;
      width: max(100%, 12.5rem);
    }
  }

  .checkbox__label {
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $flexGap;

    > div {
      &:not(:first-child):not(:nth-child(2)) {
        // TODO: will be calc((100% / 3) - ($flexGap / 3 * 2), when .search-for-results__radio below is rendered again
        flex: 1 1 calc(50% - $flexGap / 2);
        max-width: calc(50% - $flexGap / 2);

        @include large {
          // TODO: will be calc(20% - ($flexGap / 5 * 4)), when .search-for-results__radio below is rendered again
          flex: 1 1 calc(25% - ($flexGap / 4 * 3));
          max-width: calc(25% - ($flexGap / 4 * 3));
        }
      }

      &:first-child,
      &:nth-child(2) {
        flex: 1 1 calc(50% - ($flexGap / 2));
        max-width: calc(50% - ($flexGap / 2));

        @include large {
          // TODO: will be calc(20% - ($flexGap / 5 * 4)), when .search-for-results__radio below is rendered again
          flex: 1 1 calc(25% - ($flexGap / 4 * 3));
          max-width: calc(25% - ($flexGap / 4 * 3));
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: clamp-it(3.359rem, 4.75rem);
    padding: 0 clamp-it(0.8rem, 1.2rem);
    color: $primary;
    border: 1px solid $primary-light-green;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.25s linear;
    box-shadow: 0 3px 10px -5px $black;
    position: relative;

    > div:first-child {
      display: flex;
      align-items: center;

      > svg {
        margin-right: clamp-it(0.4rem, 0.8rem);
      }

      > div {
        word-break: break-word;
        padding-right: 0.35rem;
        line-height: 1;
      }
    }

    > svg:last-child {
      transform: rotate(90deg);
      transition: transform 0.25s linear;
    }

    &--selected {
      background-color: $white;

      > svg:last-child {
        transform: rotate(-90deg);
      }
    }
  }

  &__radio {
    // TODO: hide for now, will be activated later
    //display: flex;
    display: none;
    align-items: center;

    .checkbox.radio {
      padding-right: 0;
      padding-left: clamp-it(0.8rem, 1.35rem);
      justify-content: flex-end;

      .checkbox {
        &__label {
          margin-right: clamp-it(0.5rem, 0.75rem);
        }
      }
    }
  }

  &__mobile {
    .mobile-overlay {
      padding: 0;

      @include medium {
        display: flex;
      }

      @include xmedium {
        display: none;
      }

      &__header {
        justify-content: space-between !important;
        padding: clamp-it(1rem, 8rem) clamp-it(1rem, 8rem) 0;
      }
    }

    &__button.btn.btn-primary {
      background-color: $primary-light-blue;
      border: 0;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05) !important;
      color: $primary;
      width: 100%;
      display: flex;

      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      svg path {
        stroke: $primary !important;
      }
    }

    &__content {
      margin-top: clamp-it(2.5rem, 3.5rem);
      width: 100dvw;
      position: relative;

      &__inner {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        width: 300dvw;
        transform: translateX(0);
        transition: transform 0.3s ease-in;
      }

      &__step {
        padding: 0 clamp-it(1rem, 8rem) clamp-it(1rem, 8rem);
        width: 100dvw;
      }

      &__overview {
        .radio-results,
        .radio-results--nav-item {
          &:first-child {
            border-top: 1px solid $gray-400;
          }

          svg {
            margin-right: clamp-it(0.8rem, 1rem);
          }
        }
      }

      .search__content__mobile {
        margin-top: 0;
      }
    }

    .month-selector {
      &__selected-year--desktop {
        @include medium {
          display: none;
        }
      }
    }
  }

  &__dropdown {
    @include set-scrollbar($gray-300, $primary-light-green);
    padding-bottom: 4rem;

    @include xmedium {
      max-height: 16rem;
      overflow: auto;
    }

    .radio-results {
      @include xmedium {
        padding: clamp-it(0.65rem, 1rem) 0;
      }
    }

    .checkbox-results {
      border-bottom: 1px solid $gray-400;

      @include xmedium {
        padding: clamp-it(0.65rem, 1rem) 0;
      }
    }

    &.month-selector__months--mobile {
      .checkbox-results {
        padding: clamp-it(1.3rem, 1.8rem) 0;
      }
    }
  }

  &__airports {
    padding: clamp-it(0.55rem, 0.9rem) clamp-it(0.55rem, 0.9rem) 0;
  }
}

.search__content {
  &__submit {
    padding: clamp-it(0.5rem, 1.5rem);
    display: flex;
    justify-content: center;

    button.btn.btn-primary {
      background-color: $primary;
      border-color: $primary;

      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
  }

  &__pills {
    @extend %no-scrollbar;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: clamp-it(1.4rem, 2rem);
    overflow: auto;
    cursor: grab;

    @include xmedium {
      flex-wrap: wrap;
    }

    &__container {
      position: relative;
    }

    &__fog {
      position: absolute;
      top: 0;
      width: clamp-it(5rem, 6.5rem);
      height: 100%;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s ease-in;
      pointer-events: none;

      &--visible {
        opacity: 1;
      }

      @include xmedium {
        display: none;
      }

      &--left {
        left: 0;
        background: linear-gradient(90deg, rgba(228, 243, 248, 1) -8%, rgba(228, 243, 248, 0) 100%);
      }

      &--right {
        right: 0;
        background: linear-gradient(90deg, rgba(228, 243, 248, 0) -8%, rgba(228, 243, 248, 1) 100%);
      }
    }

    &__remove-all {
      text-decoration: underline;
      font-weight: 500;
      position: relative;
      top: calc($pillMarginBottom * -0.5);
      cursor: pointer;
      white-space: nowrap;
    }

    .pill {
      margin-right: clamp-it(0.85rem, 1.25rem);
      margin-bottom: $pillMarginBottom;
      cursor: pointer;
    }

    &--grab {
      cursor: grabbing;
      user-select: none;

      a {
        pointer-events: none;
      }
    }
  }
}

.pill {
  @include fluid-fonts(0.6875rem, 0.875rem, 100%, 100%);
  padding: clamp-it(0.7rem, 0.95rem) clamp-it(0.975rem, 1.125rem);
  border-radius: clamp-it(4rem, 6.25rem);
  background-color: $primary-dark-blue;
  color: $primary;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.25s linear;

  &--disabled {
    background-color: lighten($primary-dark-blue, 5%);
    color: lighten($primary, 6%);
  }

  &:hover {
    background-color: darken($primary-dark-blue, 10%);
  }

  > span {
    margin-right: clamp-it(0.9375rem, 1.375rem);
  }
}

.search__submit {
  &__mobile-container {
    &--results {
      button.search__submit {
        background-color: $primary;
        color: $white;

        svg path {
          stroke: $white;
        }

        @include medium {
          @include fluid-fonts(0.875rem, 1rem, 100%, 100%);
          background-color: $primary;
          color: $white;
          cursor: pointer;
          height: $searchSubmitHeight;
          padding: 0.15rem;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex-wrap: wrap;
          position: relative;
          max-width: initial;
          transition: none;
        }
      }
    }
  }

  svg:last-child {
    height: 1.2rem;
    width: 1.2rem;
    margin-left: 0.625rem;
  }
}
