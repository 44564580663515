@import '../variables';
@import '../utils/functions';

.toggle {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  margin-bottom: 0;

  input {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
  }

  &__label {
    @include fluid-fonts(0.75rem, 0.875rem, 100%, 100%);
    margin-right: clamp-it(0.65rem, 0.9rem);
  }

  &__field {
    width: 2.25rem;
    height: 0.875rem;
    border-radius: 0.4rem;
    background-color: $primary-dark-blue;
    position: relative;
    transition: background-color 0.25s ease-in;

    &::after {
      display: inline-block;
      content: '';
      position: absolute;
      height: 1.125rem;
      width: 1.125rem;
      border-radius: 0.5625rem;
      left: -0.125rem;
      top: -0.125rem;
      background-color: $white;
      transition: left 0.25s ease-in;
    }
  }

  &--checked {
    .toggle__field {
      background-color: $primary;

      &::after {
        left: 1.25rem;
      }
    }
  }
}
