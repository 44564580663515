@import '../utils/functions';
@import '../base/grid';
@import '../base/macros';
@import './progressBar';

$pageTop: clamp-it(0.6rem, 1rem);
$footerPaddingBottom: clamp-it(1.75rem, 2.25rem);
$outerPaddingTop: clamp-it(0.75rem, 1.5rem);
$outerPaddingBottom: clamp-it(4.5rem, 5.25rem);
$outerPaddingRight: clamp-it(0.375rem, 0.75rem);

.checkout {
  padding-top: calc($mobileContainerHeight + 5px);
  background-color: $secondary-sand;

  @include xlarge {
    padding-top: 0;
  }

  .contact-bar {
    display: none;

    @include xlarge {
      display: flex;
    }
  }

  .grid-section {
    padding-top: clamp-it(2rem, 4rem);
    padding-bottom: clamp-it(2rem, 4rem);
  }

  .page__top {
    position: absolute;
    top: calc($mobileContainerHeight + 5px + $pageTop);
    left: clamp-it(1rem, 3rem);

    @include xmedium {
      top: $pageTop;
    }

    .logo {
      display: none;

      @include xlarge {
        display: inline-block;
      }

      svg {
        width: clamp-it(7.5rem, 10.625rem);
      }
    }

    &--regular {
      position: relative;
      top: 0;
      left: 0;

      @include xmedium {
        top: 0;
      }
    }
  }

  &--last {
    .page__top {
      width: 100%;
      left: 0;
      top: 0;

      .logo {
        display: inline-flex !important;
      }
    }

    .header__outer {
      overflow-x: initial !important;
      position: fixed !important;
    }
  }

  .header__outer {
    overflow-x: hidden;
    position: absolute;
  }

  .page__center {
    position: relative;

    .hero {
      height: clamp-it(12.5rem, 15.5rem);

      @include xmedium {
        height: clamp-it(12.5rem, 20.5rem);
      }

      &__image {
        object-fit: cover;
        height: auto;
      }
    }
  }

  .custom-spinner::after {
    background: $secondary-sand;
  }

  &--last {
    padding-top: 0;
  }

  &__headline {
    margin-bottom: clamp-it(0.35rem, 0.75rem);

    + p {
      margin-bottom: clamp-it(1rem, 2.25rem);
    }
  }

  &__error {
    @extend %body-large;
    color: $red;
    font-weight: 500;
    position: relative;

    > .grid-section {
      padding-top: clamp-it(3rem, 4.5rem);
      padding-bottom: 0;
    }

    &__inner {
      padding: clamp-it(0.5rem, 0.95rem) clamp-it(1rem, 2rem);
      box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.4);
      border-radius: 0.75rem;
      position: relative;

      > svg {
        font-size: clamp-it(1.2rem, 1.4rem);
        color: $primary;
        position: absolute;
        right: clamp-it(0.35rem, 0.55rem);
        top: clamp-it(0.35rem, 0.55rem);
        cursor: pointer;
      }
    }
  }

  &__infos {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    color: $white;
    text-align: left;
    padding: clamp-it(1rem, 3rem);
    width: 100%;

    @include xmedium {
      width: inherit;
      text-align: center;
    }

    h1 {
      @include fluid-fonts(1.375rem, 3rem, 110%, 110%);
      margin-bottom: clamp-it(2rem, 2.5rem);

      @include xmedium {
        margin-bottom: clamp-it(1.2rem, 1.65rem);
      }
    }

    &__label {
      font-size: 1rem;
      margin-bottom: clamp-it(1.2rem, 1.675rem);

      @include xmedium {
        font-size: 0.875rem;
        margin-bottom: clamp-it(0.85rem, 1.375rem);
      }
    }

    &__product.checkout__data {
      @include fluid-fonts(0.6875rem, 0.875rem, 100%, 100%);
      display: flex;
      flex-wrap: wrap;

      @include xmedium {
        justify-content: center;
      }

      > div {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: clamp-it(1rem, 1.5rem);

        @include xmedium {
          margin-bottom: clamp-it(0.55rem, 0.9rem);
        }

        &:not(:last-child) {
          margin-right: clamp-it(0.75rem, 1.25rem);

          @include xmedium {
            padding-right: clamp-it(0.75rem, 1.25rem);

            &::after {
              display: inline-block;
              content: '|';
              position: absolute;
              right: 0;
            }
          }
        }

        > svg {
          margin-right: clamp-it(0.3rem, 0.6rem);
        }
      }
    }

    &__product.checkout__data__price {
      display: flex;
      margin-top: clamp-it(0.5rem, 0.9rem);

      > svg {
        margin-right: clamp-it(0.5rem, 1rem);
      }

      .checkout__data__price__alt {
        width: 100%;
        font-weight: 500;
      }
    }

    .checkout__infos__product__database {
      font-size: clamp-it(0.725rem, 0.825rem);
    }
  }

  &__progress-container {
    width: 100%;

    @include xmedium {
      width: 60%;
      margin: clamp-it(2rem, 3.5rem) auto 0;
    }
  }

  &__content {
    &__inner {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      transition: transform 1s ease-in;
    }

    &__step {
      margin-top: clamp-it(1rem, 2rem);
      transition: max-height 1.4s ease-in;
      min-height: 5px;
      max-height: 5px;

      &--active {
        max-height: 563rem;
      }

      .grid-section.grid-section--last-element {
        padding-bottom: 0.8rem;
      }
    }

    &__container {
      display: flex;
      max-width: 120.313rem;
      margin: 0 auto;

      @include xlarge {
        grid-gap: clamp-it(1rem, 2rem);
      }
    }

    &__left {
      overflow: hidden;
      width: 100%;
      position: relative;
      flex: 1 1 100%;

      @include xlarge {
        flex: 0 1 auto;

        .grid-section {
          padding-right: 0;
          transition: padding-right 1s ease-in;
        }
      }

      &--no-padding {
        @include xlarge {
          .grid-section {
            padding-right: $section-spacing-x;
          }
        }
      }
    }

    &__right {
      flex: 0;
      padding-top: clamp-it(6.5rem, 7.5rem);
      transition: max-width 1s ease-in;
      max-width: 23.5rem;

      @include xlarge {
        flex: 1 0 clamp-it(16rem, 23.5rem);
      }

      &--hidden {
        max-width: 0;
        overflow-y: hidden;
      }
    }
  }

  &__price {
    background-color: $primary-light-blue;
    width: 100%;
    position: sticky;
    top: 0;
    padding: $outerPaddingTop $outerPaddingRight $outerPaddingBottom $outerPaddingTop;
    display: none;

    @include xlarge {
      display: block;
    }

    &__inner {
      @include set-scrollbar($gray-100, $gray-400);
      min-height: 21rem;
      max-height: calc(100dvh - $outerPaddingTop - $outerPaddingBottom);
      overflow-y: auto;
      padding-right: $outerPaddingRight;
    }
  }

  &__footer {
    background-color: $secondary-sand;
    margin-top: clamp-it(2rem, 4rem);
    padding-bottom: $footerPaddingBottom;
    position: sticky;
    bottom: 0;
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);

    @include xlarge {
      position: relative;
      box-shadow: none;
    }

    &--high {
      padding: clamp-it(1rem, 1.4rem) 0;

      @include xlarge {
        padding: 0 0 calc($footerPaddingBottom + 3.75rem) 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 $section-spacing-x;

      @include xlarge {
        justify-content: center;
        padding: 0;
      }

      > button:first-child {
        margin-right: clamp-it(1rem, 2.5rem);
        border-color: $primary;
        color: $primary;
        background-color: transparent;
        display: none;

        @include xlarge {
          display: inline-block;
        }

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }

      &--mobile {
        > div {
          &:first-child {
            @extend %body-large;
            font-weight: 600;
            margin-bottom: 0.35rem;
          }
        }

        @include xlarge {
          display: none;
        }
      }
    }

    &__price-button {
      @extend %body-small;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: clamp-it(0.4rem, 0.75rem);
        font-size: clamp-it(1rem, 1.5rem);
      }

      span {
        text-decoration: underline;
      }
    }

    &__hr {
      margin: clamp-it(2rem, 2.5rem) calc($section-spacing-x + 1.5rem) clamp-it(1.75rem, 2.25rem);
      background-color: $primary;
      display: none;

      @include xlarge {
        display: block;
      }
    }

    &__lower {
      margin: 0 calc($section-spacing-x + 2.25rem);
      display: none;
      justify-content: space-between;

      @include xlarge {
        display: flex;
      }

      .trustpilot-widget {
        max-width: clamp-it(9rem, 12.95rem);
        min-width: 6.875rem;
      }
    }

    &__left {
      margin-right: clamp-it(1rem, 1.5rem);
    }

    &__right {
      display: flex;
      gap: clamp-it(1rem, 2rem);
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: flex-start;

      img {
        max-height: clamp-it(2.5rem, 2.75rem);
        height: 100%;
        width: auto;
      }
    }
  }
  &__insurancePL {
    &__checkbox {
      margin-top: clamp-it(0.412rem, 0.4375rem);
      display: flex;
      align-items: flex-start;

      .checkbox {
        margin-right: clamp-it(1rem, 1.75rem);
        position: relative;
        top: clamp-it(0.412rem, 0.4375rem);

        + p {
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__t-and-c {
    hr {
      border-top-color: $primary;
    }

    h5 {
      font-weight: 500;
      margin-top: clamp-it(1.125rem, 1.875rem);
      margin-bottom: clamp-it(0.875rem, 1.5rem);
    }

    a {
      text-decoration: underline;
    }

    &__checkbox {
      display: flex;
      align-items: flex-start;

      .checkbox {
        margin-right: clamp-it(1rem, 1.75rem);
        position: relative;
        top: clamp-it(0.412rem, 0.4375rem);

        + p {
          cursor: pointer;
        }
      }

      &--error {
        color: $red;

        a {
          color: $red;
        }

        .checkbox__field {
          border-color: $red;
        }
      }
    }
  }

  &__loading {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #f8f5f1cc;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}
