@charset "utf-8";

// All of bootstrap, but our own vars file
@import '~bootstrap/scss/functions';
@import '~@ibe/components/dist/Styles/styles';

// only bootstrap overrides/extends here

html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}
