@import '../variables';
@import '../utils/functions';
@import '../base/macros';

.results__list__cal {
  position: relative;
  padding: clamp-it(1rem, 2.75rem) 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  @include large {
    padding: clamp-it(0.7rem, 1.2rem) 0;
    border-bottom: 1px solid $gray-400;
    flex-direction: row;
    align-items: center;

    &:first-child {
      border-top: 1px solid $gray-400;
    }
  }

  &__image {
    height: auto;
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
    margin-bottom: clamp-it(1rem, 1.5rem);

    @include large {
      margin-bottom: 0;
      margin-right: clamp-it(0.5rem, 1.25rem);
      width: initial;
      max-height: 7rem;
    }

    &__container {
      display: flex;
      position: relative;
      flex-direction: column;

      @include large {
        flex-direction: row;
      }
    }
  }

  &__travel-types {
    @extend %body-small;
    margin-bottom: 0.35rem;
    text-transform: uppercase;
  }

  &__date {
    @extend %body-large;
    font-weight: 500;
    margin-right: clamp-it(0.8rem, 1.5rem);
  }

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__upper {
      display: flex;
      align-items: center;
      margin-bottom: clamp-it(0.45rem, 0.75rem);

      @include large {
        margin-bottom: 0;
      }
    }

    &__lower {
      margin-top: clamp-it(0.65rem, 0.95rem);
      margin-bottom: clamp-it(0.7rem, 1rem);

      @include large {
        margin-top: 0;
        margin-bottom: 0;
      }

      h5 {
        font-weight: 500;
        line-height: 100%;
      }
    }

    &__delimiter {
      margin: 0 clamp-it(0.5rem, 0.95rem);
    }

    &__small {
      @extend %body-small;
    }
  }

  &__favorite {
    position: absolute;
    left: clamp-it(0.8rem, 1.3rem);
    top: clamp-it(0.8rem, 1.3rem);
    cursor: pointer;
    z-index: 2;

    @include large {
      position: relative;
      left: initial;
      top: initial;
    }

    path {
      stroke: $secondary-accent-green !important;
      transition: fill 0.25s ease-in;
    }

    &:hover,
    &--active {
      path {
        fill: $secondary-accent-green;
      }
    }
  }

  &__rating {
    background-color: $secondary-accent-yellow;
    color: $primary;
    padding: clamp-it(0.25rem, 0.45rem) clamp-it(0.35rem, 0.55rem) clamp-it(0.35rem, 0.55rem);
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: clamp-it(0.75rem, 1rem);
    z-index: 2;

    > svg {
      @include fluid-fonts(0.95rem, 1.05rem, 100%, 100%);
      margin-right: clamp-it(0.25rem, 0.35rem);
      height: 1em;
      width: 1em;
    }

    > span {
      @include fluid-fonts(0.75rem, 0.8125rem, 100%, 100%);
      padding-top: 0.25rem;
    }

    @include large {
      right: initial;
      left: 0;
      top: 0;
    }
  }

  &__price {
    &__container {
      @include large {
        text-align: center;
      }

      h4 {
        font-weight: 500;
        margin-bottom: clamp-it(0.15rem, 1rem) !important;
      }
    }

    &__label {
      @extend %body-small;
    }

    &__button {
      &.theme-dark-green {
        background-color: inherit;
        color: inherit;
        text-align: right;

        > span {
          display: block;
        }

        .btn.btn-primary {
          white-space: nowrap;
          width: 100%;

          &:hover {
            color: $primary;
            border: 1px solid $primary;
            background-color: transparent;

            > span {
              color: $primary;
            }
          }

          &:disabled {
            background-color: $gray-900;
            border-color: $gray-900;
            color: $white;

            &:hover {
              background-color: $gray-900;
              border-color: $gray-900;
              color: $white;
            }
          }
        }
      }

      &--disabled {
        &.theme-dark-green .btn.btn-primary {
          background-color: $gray-900;
          border-color: $gray-900;
          color: $white;
          cursor: default;

          &:hover {
            background-color: $gray-900;
            border-color: $gray-900;
            color: $white;
          }
        }
      }
    }
  }
}
