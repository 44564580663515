@import '../variables';
@import '../base/macros';
@import '../base/grid';

.continent-map {
  @extend %dm-sans-font;
  display: flex;
  flex-direction: column;
  background-color: $primary-light-blue;

  &__container {
    padding: $section-spacing-y 0;
    background-color: $primary-light-blue;

    &:first-child {
      padding-top: clamp-it(7.5rem, 9rem);
    }
  }

  &__map-container {
    @extend .max-width;
    position: relative;
    width: 100%;
  }

  &__map-image {
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    position: relative;

    &:first-of-type {
      opacity: 1;
    }

    &:not(:first-of-type) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    &--active {
      opacity: 1;
      z-index: 1;
    }
  }

  &__link {
    @include fluid-fonts(0.65rem, 0.85rem, 120%, 145%);
    display: inline-block;
    position: absolute;
    background-color: $white;
    border: 1px solid $white;
    cursor: pointer;
    transform: translate(-50%, -100%);
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.25s ease-in;
    z-index: 2;

    &:hover {
      background-color: $primary;
      color: $white;
      text-decoration: none;

      &::before {
        background-color: $primary;
        border-color: $primary;
      }

      &::after {
        background-color: $primary;
        border-color: $white;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
      width: 14px;
      height: 14px;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $white;
      transition:
        background-color 0.25s ease-in,
        border-color 0.25s ease-in;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      width: 12px;
      height: 12px;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $primary;
      transition:
        background-color 0.25s ease-in,
        border-color 0.25s ease-in;
    }

    &:focus {
      outline: none;
    }
  }

  &__continent-list {
    @extend %dm-sans-font;
    @include fluid-fonts(1rem, 1.5rem, 110%, 110%);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $primary-light-blue;

    &__element {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
      transition: background-color 0.25s ease-in;
      padding: clamp-it(0.4rem, 1.5rem) 0;

      &:not(:last-child) {
        border-bottom: 1px solid $grey;
      }

      &:hover {
        background-color: $primary-light-blue-contrast;
        text-decoration: none;
      }

      &__label {
        display: flex;
        width: 60%;
        height: clamp-it(3rem, 4rem);
        margin-left: clamp-it(1rem, 2.5rem);
        align-items: center;
      }

      &__title {
        margin-right: clamp-it(0.8rem, 1.8rem);
      }

      &__icon {
        margin-right: clamp-it(1.5rem, 3rem);
      }

      &__count {
        @include fluid-fonts(0.8rem, 1.2rem, 100%, 100%);
        width: clamp-it(1.438rem, 2.063rem);
        height: clamp-it(1.438rem, 2.063rem);
        border-radius: 50%;
        background-color: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
