@import '../variables';

.svgs {
  &__play {
    path,
    circle {
      transition: fill 0.25s ease-in;
    }

    &:hover {
      circle {
        fill: $primary;
      }

      path {
        fill: $secondary;
      }
    }
  }
}

.fa-caret-down-thin,
.fa-button-caret,
.fa-caret-right-thin {
  path {
    fill: transparent !important;
    stroke: currentColor !important;
  }
}

.fa-database,
.fa-time,
.fa-globe {
  font-size: 1.2em;
}

.fa-database path {
  stroke: currentColor;
}

.svg-inline--fa {
  height: 1em;
  width: 1em;
}

.icon-checkbox-check {
  path {
    stroke: currentColor !important;
  }
}

.icon-cross-small,
.svg-exclamation-mark-circle {
  height: 1em;
  width: 1em;

  circle {
    fill: transparent !important;
    stroke: #ff0000 !important;
    stroke-width: 3px;
    stroke-opacity: 0.75;
  }

  path {
    stroke: transparent !important;
    fill: #ff0000 !important;
    fill-opacity: 0.75;
  }
}
