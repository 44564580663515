@import '../utils/functions';

.video-gallery {
  position: relative;
  height: 100dvh;

  > div {
    height: 100dvh;

    > div {
      height: 100dvh;
    }
  }

  &__modal {
    .video-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;

    &__modal {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    color: $white;
    margin-bottom: clamp-it(1rem, 2rem);
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
  }

  &__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    z-index: 1;
    display: none;

    @include medium {
      display: block;
    }

    &--prev {
      left: clamp-it(1.5rem, 3.31rem);
    }

    &--next {
      right: clamp-it(1.5rem, 3.31rem);
    }
  }

  &__overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__play {
    border: 0;
    background-color: transparent;

    > svg {
      height: clamp-it(1.875rem, 3.375rem);
      width: clamp-it(1.875rem, 3.375rem);
    }
  }

  &__indicator {
    width: clamp-it(0.62rem, 0.75rem);
    height: clamp-it(0.62rem, 0.75rem);
    border-radius: 50%;
    background-color: $primary;
    transition: background-color 0.3s ease-in;
    margin: 0 clamp-it(0.4rem, 0.475rem);
    cursor: pointer;

    &--selected {
      background-color: $secondary;
    }
  }

  .svgs__next,
  .svgs__previous {
    height: clamp-it(1.7rem, 3.25rem);
    width: clamp-it(1.7rem, 3.25rem);

    circle {
      fill: $secondary;
      transition: fill 0.25s ease-in;
      stroke: none;
    }

    path {
      stroke: $primary;
      transition: stroke 0.25s ease-in;
    }

    &:hover {
      circle {
        fill: $primary;
      }

      path {
        stroke: $secondary;
      }
    }
  }

  .control-dots {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto !important;
    bottom: clamp-it(2.13rem, 3.31rem) !important;
    margin: 0 !important;
  }

  .slider-wrapper {
    height: 100%;

    .slider {
      height: 100%;

      .slide {
        height: 100%;

        > div {
          height: 100%;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
}

.video-container {
  &__iframe {
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
      width: 100% !important;
      margin: 0 !important;
    }
  }
}
