@import '../utils/mediaQueries';
@import '../utils/functions';

.breadcrumb-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: clamp-it(0.75rem, 1.5rem);
}

.breadcrumb-item {
  margin-right: clamp-it(0.5rem, 0.75rem);

  &--bold {
    font-weight: 700;
  }
}

.breadcrumb-separator {
  margin-right: 8px;
}
