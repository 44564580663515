@import '../utils/functions';
@import '../base/macros';

.alb-datepicker {
  position: relative;
  height: clamp-it(2.75rem, 3.75rem);
  background-color: $white;
  border: 1px solid $gray-900;
  display: flex;
  align-items: center;

  &__label {
    @extend %body-large;
    @extend %ellipsis;
    display: flex;
    align-items: center;
    padding: 0 0 0 0.625rem;
    cursor: pointer;
    height: 100%;
    width: 100%;

    svg {
      height: 1rem;
      margin-right: 1rem;
      position: relative;
      top: -2px;
      color: $primary;
    }
  }

  &__popup {
    position: absolute;
    width: 100%;
    bottom: 100%;
    box-shadow: 0 0 6px -3px rgba(0, 0, 0, 0.5);
    @include fluid-fonts(1rem, 1.125rem, 170%, 170%);
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    max-height: 31.25rem;
    background-color: $white;

    &--hidden {
      max-height: 0;
    }

    &__inner {
      display: flex;
      width: 0;
      transition:
        transform 0.4s ease-in-out,
        width 0.2s ease-in-out;

      &--0 {
        transform: translateX(0);
      }

      &--1 {
        transform: translateX(100 / 3 * -1%);
      }

      &--2 {
        transform: translateX(100 / 3 * -2%);
      }

      &--open,
      &--opening {
        width: 300%;
      }
    }

    &__data {
      width: calc(100 / 3 * 1%);
      text-align: center;
      max-height: 0;
      overflow-y: auto;
      scrollbar-color: $grey lighten($grey, 30%);
      scrollbar-width: thin;
      transition: max-height 0.8s ease-in-out;

      &::-webkit-scrollbar {
        background-color: lighten($grey, 30%);
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $grey;
      }

      &::-webkit-scrollbar-track {
        background-color: lighten($grey, 30%);
      }

      &__inner {
        display: inline-block;
        text-align: left;
      }

      &--active {
        max-height: 25.625rem;
      }
    }

    &__back {
      display: flex;
      margin: 1.44rem 0 0.625rem 1.28rem;
      font-weight: 700;
      position: relative;
      cursor: pointer;

      svg {
        position: absolute;
        height: 0.75rem;
        top: 0.5rem;
        left: -2rem;
      }
    }
  }

  &__year {
    cursor: pointer;
    padding: 1rem 1.28rem;
    transition: font-weight 0.2s ease-in;

    &:first-child {
      padding-top: 3.75rem;
    }

    &:last-child {
      padding-bottom: 3.75rem;
    }

    &.alb-datepicker__year--active,
    &:hover {
      font-weight: 700;
    }
  }

  &__month,
  &__day {
    padding: 0.625rem 1.28rem;
    cursor: pointer;
    position: relative;
    transition: font-weight 0.2s ease-in;

    &::after {
      display: block;
      content: attr(title);
      font-weight: 700;
      height: 1px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }

    &:last-child {
      padding-bottom: 1.44rem;
      cursor: pointer;
    }

    &.alb-datepicker__month--active,
    &.alb-datepicker__day--active,
    &:hover {
      font-weight: 700;
    }
  }

  &__value {
    &.alb-datepicker__placeholder {
      color: $gray-900;
    }
  }
}
