@import '../variables';
@import '../base/macros';

$containerPadding: clamp-it(0.5rem, 1.2rem);
$containerBorder: 1px solid $gray-300;

%logo {
  height: fit-content;
  width: clamp-it(3.35rem, 4rem);
}

.flight {
  &-intro {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;

    &__segments {
      flex: 1 1 100%;
      max-width: 100%;

      @include xmedium {
        flex: 1 1 50%;
        max-width: 50%;

        &:not(:last-child) {
          border-right: $containerBorder;
        }
      }
    }
  }

  &-segments {
    &__label {
      @extend %body-small;
      font-weight: 600;
      padding: clamp-it(1.125rem, 1.375rem) clamp-it(1rem, 2rem);
      text-align: center;
      border-top: $containerBorder;
      border-bottom: $containerBorder;

      @include xmedium {
        border-top: 0;
      }
    }
  }

  &-legs {
    display: flex;
    justify-content: space-between;
    margin: clamp-it(1.125rem, 2.6875rem) clamp-it(0.75rem, 2.5rem);
    position: relative;

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      border-top: 1px dotted $black;
      width: 100%;
      left: 0;
      top: clamp-it(1.125rem, 1.35rem);
    }

    &__infos {
      text-align: center;
      padding-right: $containerPadding;
      background-color: $white;
      z-index: 1;

      &--destination {
        padding-right: 0;
        padding-left: $containerPadding;
      }
    }

    &__time {
      @include fluid-fonts(1.125rem, 1.35rem, 135%, 150%);
      font-weight: 500;

      > sup {
        font-size: 65%;
        left: 0.2rem;
        top: -0.95rem;
        color: $red;
      }
    }

    &__location {
      @extend %body-small;
    }

    &__center {
      background-color: $white;
      padding: 0 $containerPadding;
      z-index: 1;
      position: relative;

      &--stops {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        top: clamp-it(0.72rem, 0.895rem);
      }
    }

    &__duration {
      margin-bottom: 0.4rem;

      svg {
        margin-right: 0.5rem;
      }
    }

    &__image {
      display: flex;
      justify-content: center;

      img {
        @extend %logo;
      }
    }

    &__stops {
      height: clamp-it(0.875rem, 1rem);
      width: clamp-it(0.875rem, 1rem);
      border-radius: 50%;
      background-color: $gray-400;
      margin-bottom: clamp-it(0.2rem, 0.35rem);

      &__image {
        @extend %logo;
        display: none;
        position: relative;
        top: clamp-it(1.75rem, 1.95rem);

        @include medium {
          display: inline-block;
        }
      }
    }

    &__count {
      @include fluid-fonts(0.75rem, 0.875rem, 100%, 100%);
    }
  }
}
