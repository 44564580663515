@import '../utils/functions';
@import '../variables';

$singleColumnImageWidth: clamp-it(1.5rem, 4.5rem);

.intro {
  padding: clamp-it(0.85rem, 4.3rem) clamp-it(1.125rem, 5.75rem);
  max-width: map-get($grid-breakpoints, xxl);
  position: relative;
  margin: auto;

  @include xmedium {
    width: 90vw;
  }

  &__container {
    &__single {
      width: 100%;
      p {
        margin-bottom: 0;
      }
    }

    &__left {
      margin-bottom: clamp-it(3rem, 4rem);
      @include large {
        padding-right: clamp-it(0rem, 4.5rem);
        width: 50%;
        margin-bottom: 0;
      }
      span {
        margin-right: 0.625rem;
      }
    }

    &__right {
      @include large {
        padding-left: clamp-it(0rem, 4.5rem);
        width: 50%;
      }
      span {
        margin-right: 0.625rem;
      }
    }

    &__accordionHeadline {
      @include fluid-fonts(1rem, 1.125rem, 140%, 190%);
    }

    &__headline {
      @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
      margin-bottom: clamp-it(1rem, 2.5rem);
    }

    &__sub-headline {
      @include fluid-fonts(0.688rem, 0.875rem, 110%, 110%);
      font-weight: 500;
      margin-bottom: clamp-it(1rem, 1.25rem);
    }

    &__text {
      @include fluid-fonts(1.5rem, 1.75rem, 140%, 140%);
      font-family: var(--albatros-font);
      font-weight: 400;
      hyphens: auto;
    }

    &__item {
      width: 50%;
    }

    &__button {
      @include fluid-fonts(0.8rem, 1rem, 180%, 190%);
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }

  &__single-column {
    &__image {
      > img {
        margin-bottom: clamp-it(1rem, 1.15rem);
        margin-top: 0.5rem;

        @include large {
          float: left;
          width: calc(50% - $singleColumnImageWidth);
          margin-right: clamp-it(1.5rem, 1.9rem);
        }
      }

      &--right {
        > img {
          @include large {
            float: right;
            margin-right: initial;
            margin-left: clamp-it(1.5rem, 1.9rem);
          }
        }
      }
    }

    &__text {
      a {
        box-shadow: inset 0 -1px 0 currentColor;
      }
    }
  }
}

.headline-accordion {
  @include fluid-fonts(1.5rem, 2rem, 130%, 140%);
  font-family: var(--albatros-font);
  margin-bottom: clamp-it(1.5rem, 3rem);
}

.facts-accordion {
  margin: auto;
  @extend .max-width;

  &__container {
    @include xmedium {
      display: flex;
      flex: 0 0 42%;
      justify-content: space-between;
    }
    .accordion-svg {
      svg {
        rect,
        path {
          fill: none;
        }
      }
    }

    .accordion-icon {
      stroke-width: 0;
    }

    .accordion {
      flex: 0 0 40%;
    }
    .accordion-svg-information {
      path {
        fill: none;
      }
    }

    .accordion-element {
      &__button {
        svg {
          width: clamp-it(1.25rem, 1.875rem);
          margin-right: clamp-it(0.4rem, 0.65rem);
        }
      }

      &__group__header > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
