@import '../utils/functions';
@import '../base/macros';

.travel-information {
  &__header {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    padding: 0;
    white-space: nowrap;
    margin-bottom: clamp-it(1.25rem, 4rem);
    font-weight: 500;
  }

  &__subHeader {
    margin-top: 0.5em;
    margin-bottom: 0.65rem;
    @include fluid-fonts(0.688rem, 0.875rem, 130%, 110%);
  }

  &__facts--desktop {
    display: none;
    align-items: center;
    justify-content: flex-start;
    margin: clamp-it(1rem, 1.5rem) 0 clamp-it(0.6rem, 1.5rem);
    flex-wrap: nowrap;

    @include medium {
      display: flex;
    }
  }

  &__facts--mobile {
    align-items: center;
    justify-content: start;
    margin: 0;
    flex-wrap: wrap;

    @include medium {
      display: none !important;
    }

    &:first-of-type {
      margin-top: clamp-it(1.2rem, 1.75rem);
    }

    &:nth-child(2) {
      margin-top: clamp-it(2.8rem, 5.5rem);
    }
  }

  &__fact {
    &--desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 0 0 auto;
      margin-right: clamp-it(1.2rem, 2.2rem);

      svg {
        @include fluid-fonts(1.4rem, 1.45rem, 100%, 100%);
      }
    }

    &--mobile {
      flex: 0 0 50%;
      min-width: clamp-it(7.5rem, 10rem);
      padding: 0 1rem clamp-it(1.4rem, 1.85rem) 0;
      display: flex;

      svg {
        @include fluid-fonts(1.2rem, 1.4rem, 100%, 100%);
      }
    }

    svg {
      @include fluid-fonts(1.3rem, 1.68rem, 100%, 100%);
      min-width: clamp-it(1.75rem, 2.125rem);
    }

    &__data {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin: 0 clamp-it(0.4rem, 0.6rem) 0 clamp-it(0.4rem, 0.6rem);

      @include medium {
        margin-top: clamp-it(0.4rem, 0.6rem);
      }

      div {
        @include fluid-fonts(0.5rem, 0.688rem, 0.75rem, 1rem);

        &:first-child {
          margin-bottom: clamp-it(0.1rem, 0.2rem);
        }
      }

      p {
        @include fluid-fonts(0.688rem, 0.875rem, 110%, 1.375rem);
        margin-bottom: 0 !important;
        font-weight: 700;
      }
    }
  }

  .accordion-element__section {
    &__text {
      word-break: break-word;
    }
  }
}
