@import '../variables';
@import '../utils/functions';
@import '../base/grid';

$sliderHeight: clamp-it(2.5rem, 5rem);
$thumbsHeight: clamp-it(6.19rem, 6.312rem);
$modalPadding: clamp-it(0.9rem, 1.5rem);
$carouselMargin: clamp-it(1.5rem, 2.5rem);
$carouselMarginExpanded: clamp-it(3rem, 4rem);
$carouselMarginHalf: clamp-it(3rem, 3.9rem);
$carouselMarginThird: clamp-it(1.5rem, 2.2rem);
$buttonDiameter: clamp-it(1.5rem, 3.25rem);
$buttonsMargin: clamp-it(0.6rem, 1.5rem);

.expanded-gallery {
  &__image {
    width: auto;
    display: flex;
  }

  .carousel .carousel-status {
    bottom: 0;
    top: auto;
    right: auto;
    left: 0;
    font-size: 14px;
  }

  &__button {
    position: absolute;
    border: 0;
    padding: 0;
    display: block;
    top: 50%;
    z-index: 1;
    background-color: transparent;
    height: $buttonDiameter;
    width: $buttonDiameter;

    svg {
      height: $buttonDiameter;
      width: $buttonDiameter;

      circle {
        fill: $primary-light-green;
        stroke: $primary-light-green !important;
        transition: fill 0.25s ease-in;
      }

      path {
        stroke: $white !important;
        fill: none;
        transition: stroke 0.25s ease-in;
      }
    }

    &--previous {
      background: transparent;
      transform: translate(-50%, -50%);
      left: 0;
    }

    &--next {
      border-radius: 50px;
      transform: translate(50%, -50%);
      right: 0;
    }
  }

  &__with-caption.expanded-gallery .carousel {
    .thumbs-wrapper {
      margin-top: $carouselMarginExpanded;
    }
  }

  &__label {
    position: absolute;
    top: calc(100% - $thumbsHeight - $carouselMarginThird);

    .expanded-gallery.expanded-gallery__with-caption & {
      top: calc(100% - $thumbsHeight - $carouselMarginHalf);
    }
  }

  .slider {
    list-style-type: none;
  }

  .carousel {
    &.carousel-slider {
      height: auto;
      overflow: visible;
    }

    .control-dots {
      width: auto;
    }

    .slider-wrapper {
      margin: 0;
    }

    .slide {
      img {
        width: 100% !important;
        height: auto;
        max-width: 100%;
        max-height: 85dvh;
        object-fit: contain;
      }
    }

    .thumbs-wrapper {
      margin: $carouselMargin 0 0 0;
      transition: margin 0.25s ease-in;

      ul.thumbs {
        padding: 0;

        .animated {
          padding: 0;
        }

        .thumb {
          border: 0;
          position: relative;
          padding: 0;

          &.selected,
          &:hover {
            border: 0;
          }

          > img {
            height: auto;
            width: 120px;
          }

          > span {
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background: rgba(0, 0, 0, 0);
              transition: background 0.3s ease-in;
            }
          }

          &:not(.selected) {
            > span::after {
              background: rgba(0, 0, 0, 0.75);
            }
          }
        }
      }

      .control-arrow {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &.control-next:before {
          border-left: 8px solid $secondary;
        }

        &.control-prev:before {
          border-right: 8px solid $secondary;
        }
      }
    }
  }

  &__thumb {
    display: inline-block;
    position: relative;
    width: 100%;

    > img {
      color: transparent;
      height: initial;
    }
  }
}

.theme-dark-green,
.theme-medium-green {
  .expanded-gallery__button {
    &--previous,
    &--next {
      svg {
        circle {
          fill: $secondary;
          stroke: $secondary !important;
          transition: fill 0.25s ease-in;
        }

        path {
          stroke: $primary !important;
          fill: none;
          transition: stroke 0.25s ease-in;
        }
      }
    }
  }
}
