@import '../variables';
@import '../utils/functions';

.country-page {
  &__navigation {
    max-width: map-get($grid-breakpoints, xxl);
    margin: 0 auto;
    position: relative;

    @include xmedium {
      width: 90vw;
    }
  }
}
