@import '../utils/functions';
@import '../base/grid';

.media-codes {
  display: flex;
  flex-direction: column;
  background-color: $primary-light-green;
  color: $white;
  padding: clamp-it(2.5rem, 3.75rem) clamp-it(2rem, 3rem) clamp-it(2.5rem, 3.75rem)
    $section-spacing-x;
  margin: clamp-it(1.5rem, 3.5rem) 0;

  @include xmedium {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__text {
    margin-bottom: clamp-it(2rem, 4rem);

    @include xmedium {
      margin-bottom: 0;
      margin-right: clamp-it(1.5rem, 4rem);
      white-space: nowrap;
    }

    > div:first-child {
      @include fluid-fonts(1.4rem, 1.75rem, 140%, 150%);
      margin-bottom: 0.4rem;
    }
  }

  &__additional,
  &__dropdown {
    flex: 1;
    width: 100%;

    @include xmedium {
      width: initial;
    }

    .select-field__options {
      max-height: clamp-it(19.7rem, 20.3rem);
    }
  }

  &__select-container {
    display: flex;
    flex: 1;
    gap: clamp-it(1rem, 2rem);
    flex-wrap: wrap;

    > div {
      min-width: 16.4rem;
      max-width: 30rem;

      .select-field {
        width: 100%;
      }

      input[type='text'] {
        @include fluid-fonts(0.875rem, 1rem, 100%, 100%);
        padding: clamp-it(0.74rem, 1.01rem) 0.94rem;
        border-bottom: 0;

        &::placeholder {
          @include fluid-fonts(0.875rem, 1rem, 100%, 100%);
        }

        &:hover,
        &:focus,
        &:active {
          border-bottom: 0;
        }
      }
    }
  }

  .select-field {
    &__input {
      padding-top: clamp-it(0.65rem, 0.95rem);
      padding-bottom: clamp-it(0.65rem, 0.95rem);
      border: 2px solid $white;
      background-color: $white;
      color: $primary;
      min-width: clamp-it(10.5rem, 12.5rem);
    }

    &__options {
      color: $primary;
    }
  }

  &--error {
    color: $red;

    .select-field {
      &__input {
        border: 2px solid $red;
      }
    }
  }
}

.promo-code {
  padding-top: clamp-it(1.5rem, 2rem);
  padding-bottom: clamp-it(0.8rem, 1rem);

  &__text {
    margin-bottom: clamp-it(0.9rem, 1.2rem);

    > div:first-child {
      @include fluid-fonts(1.5rem, 1.75rem, 120%, 130%);
      margin-bottom: clamp-it(0.35rem, 0.4rem);
    }

    > div:last-child {
      @include fluid-fonts(0.875rem, 1rem, 105%, 110%);
    }
  }

  .payment__voucher__input {
    margin-bottom: clamp-it(0.9rem, 1.2rem);
    margin-top: 0;

    button {
      height: initial;
    }
  }
}
