@import '../variables';
@import '../utils/functions';

.gallery-overview {
  &__headline {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    font-family: var(--albatros-font);
    font-weight: 500;
    margin-bottom: clamp-it(1.281rem, 3rem);
  }

  &__item {
    position: relative;
    display: flex;

    .alb-link {
      align-items: initial;

      > span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child {
          border-bottom: 0 !important;
        }
      }

      &:hover > span:first-child {
        border-bottom: 0 !important;
      }
    }
  }

  &__link {
    box-shadow: none !important;
    border-bottom: 0 !important;
    transition: box-shadow 0.3s ease-in;
    height: 100%;
    align-items: flex-start;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &:first-child {
        border-bottom: 0 !important;
      }
    }

    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1) !important;
      outline: 0 !important;
    }
  }

  &__image {
    width: 100%;
    height: initial;
  }

  &__video {
    object-fit: cover;
    width: 100%;
    max-height: 17rem;
  }

  &__imageText {
    @include fluid-fonts(1.125rem, 1.375rem, 140%, 170%);
    font-weight: 500;
    margin-top: clamp-it(0.4rem, 0.8rem);
  }

  &__countText {
    @include fluid-fonts(0.688rem, 0.875rem, 130%, 150%);
    margin-bottom: clamp-it(0.875rem, 1rem);
  }

  &__generalCountText {
    @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &.link {
      text-decoration: underline;
      box-shadow: none !important;
      border-bottom: 0 !important;
      outline: 0 !important;
    }
  }
}
