@import '../utils/functions';

.price-information {
  .grid {
    grid-gap: 2%;
    grid-row-gap: clamp-it(1rem, 3rem);
  }

  h2 {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
    font-weight: 500;
    margin-bottom: clamp-it(1.25rem, 4rem);
  }

  p {
    margin-bottom: 0;
  }

  &__body {
    columns: 2;
    column-gap: 5%;

    &--unwrapped {
      padding-right: 2.5%;
    }
  }
}
