@import '../utils/functions';

.navBtn {
  padding: 0;
  position: relative;
  background: transparent;
  border: 0;
  display: block;

  &.navBtn--slide {
    position: absolute;
    margin: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in;
    display: block !important;

    &.navBtn--visible {
      opacity: 1;
    }

    &.navBtn--prev {
      left: clamp-it(2rem, 5rem);
    }

    &.navBtn--next {
      right: clamp-it(2rem, 5rem);
    }
  }
}
