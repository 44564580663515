@import '../utils/functions';

%albatros-font {
  font-family: var(--albatros-font);
}

%dm-sans-font {
  font-family: var(--dm-sans-font);
}

%body {
  @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
}

%body-large {
  @include fluid-fonts(1rem, 1.125rem, 140%, 190%);
}

%body-small {
  @include fluid-fonts(0.6875rem, 0.875rem, 130%, 150%);
}

%no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
