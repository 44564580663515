@import '../variables';
@import '../utils/functions';

.travelTypeTeaser {
  &__container {
    @include medium {
      display: flex;
      justify-content: space-between;
      padding-right: clamp-it(7rem, 9rem);
    }
    margin-bottom: clamp-it(2.188rem, 2.75rem);
  }

  &__headline {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    font-family: var(--albatros-font);
    font-weight: 500;

    @include small {
      margin-bottom: clamp-it(0.5rem, 0.875rem);
    }
  }

  &__box {
    display: flex;
  }

  &__item {
    object-fit: cover;
    width: 100%;
    height: clamp-it(23rem, 34.438rem);
    display: flex;
    justify-content: center;
    align-items: center;

    &__headline {
      @include fluid-fonts(1.375rem, 1.875rem, 160%, 140%);
      font-family: var(--albatros-font);
      font-weight: 400;
      color: white;
      position: absolute;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
    }
  }
}
