@import '../utils/functions';
@import '../base/grid';
@import '../base/macros';
@import '../base/typography';

$contentBottom: clamp-it(2.8rem, 3.75rem);

.top-product-teaser {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: block;

  &:hover {
    text-decoration: none;
  }

  &__container {
    padding-bottom: calc($section-spacing-y + 3rem) !important;
    position: relative;

    @include xxlarge {
      max-height: 100dvh;
    }

    > div {
      text-align: center;
    }
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: clamp-it(1.375rem, 3.125rem) clamp-it(1.5rem, 2.5rem) clamp-it(1.875rem, 3.125rem);
    text-align: left;

    @include large {
      position: absolute;
      max-width: 28rem;
      width: 100%;
      right: 8%;
      bottom: calc($contentBottom * -1);
    }
  }

  img {
    object-fit: cover;
    position: relative;
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 9;

    @include xxlarge {
      max-height: calc(100dvh - (($section-spacing-y * 2) + 3rem));
    }
  }

  &__pacific {
    display: flex;
    position: relative;
    padding-bottom: clamp-it(1.5rem, 2rem);

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: currentColor;
      bottom: clamp-it(0.75rem, 1rem);
    }

    &__infos {
      @include fluid-fonts(0.6875rem, 0.875rem, 110%, 110%);
      display: flex;
      font-weight: 700;
    }
  }

  &__title {
    @include fluid-fonts(1.25rem, 1.75rem, 150%, 130%);
    font-weight: 500;

    @include large {
      font-weight: 300;
      font-family: var(--albatros-font);
    }
  }

  &__divider {
    margin: 0 clamp-it(0.3rem, 0.42rem);
  }

  &__button {
    margin-top: clamp-it(1.5rem, 2rem);
  }
}
