@import '../variables';
@import '../utils/functions';

.city-page {
  &__navigation {
    width: 90vw;
    max-width: map-get($grid-breakpoints, xxl);
    margin: -200px auto 0 auto;
    position: relative;
  }
}
