@import '../variables';
@import '../utils/functions';
@import '../base/macros';

%data-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: clamp-it(0.75rem, 0.95rem);
  width: 100%;

  &::after {
    display: none;
  }

  > svg {
    height: 1em;
    width: 1em;
    margin-right: clamp-it(0.5rem, 1rem);
    margin-top: 0.025rem;

    @include xlarge {
      margin-top: 0.075rem;
    }
  }

  > div {
    flex: 1;
  }
}

.cart {
  &__title {
    @extend %body-small;
    margin-bottom: clamp-it(0.35rem, 0.55rem);

    + .cart__headline {
      margin-bottom: clamp-it(0.7rem, 1.2rem);
    }
  }

  &__headline {
    @extend %body-large;
    font-weight: 600;
    margin-top: 0.15rem;
    margin-bottom: clamp-it(0.35rem, 0.65rem);
  }

  &__mobile-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100dvh;
    width: 100%;
    z-index: 42;

    &__backdrop {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s ease-in;
      opacity: 1;
      cursor: pointer;

      &--hidden {
        opacity: 0;
      }
    }

    &__layer {
      padding: clamp-it(1.75rem, 2.5rem) clamp-it(0.85rem, 1rem) clamp-it(0.85rem, 1rem);
      width: 100%;
      max-width: 29rem;
      min-height: 14rem;
      background-color: $secondary-sand;
      border-radius: 1.5rem 1.5rem 0 0;
      transition: transform 0.3s ease-in;
      transform: translateY(0);
      position: absolute;
      left: 0;
      bottom: 0;

      &--hidden {
        transform: translateY(100%);
      }

      &__inner {
        @include set-scrollbar($gray-100, $gray-400);
        overflow-y: auto;
        max-height: calc(100dvh - 8rem);
      }

      &__close {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        top: clamp-it(0.5rem, 1rem);
        right: clamp-it(0.5rem, 1rem);

        > svg {
          @include fluid-fonts(2rem, 2.5rem, 100%, 100%);
          height: 1em;
          width: 1em;
          margin-right: 0 !important;

          circle {
            fill: $primary;
            stroke: $primary;
            transition: fill 0.25s ease-in;
          }

          rect {
            fill: $secondary-sand;
            transition: fill 0.25s ease-in;
          }
        }

        &:hover {
          > svg {
            circle {
              fill: $secondary-sand;
            }

            rect {
              fill: $primary;
            }
          }
        }
      }
    }
  }

  .checkout__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      @extend %data-item;

      &:last-child {
        margin-bottom: 0;
      }
    }

    * {
      @extend %body;
      line-height: 120% !important;
    }

    &__accommodation {
      margin-right: clamp-it(0.5rem, 0.8rem);
      @extend %data-item;
    }

    &__price {
      margin-top: clamp-it(0.35rem, 0.55rem);

      &:not(.checkout__data__price--inner) {
        font-weight: 500;
      }

      > div {
        display: flex;
        justify-content: space-between;
      }

      > svg {
        opacity: 0;
      }

      &--inner {
        display: flex;
        justify-content: space-between;

        > div {
          &:first-child {
            flex: 1 1 60%;
          }

          &:last-child {
            flex: 1 1 40%;
            text-align: right;
            justify-content: flex-end;
          }
        }
      }

      &__single-item {
        justify-content: flex-start !important;
      }

      &__alt {
        > div {
          &:first-child {
            flex: 1 1 60%;
          }

          &:last-child {
            flex: 1 1 40%;
            text-align: right;
            justify-content: flex-end;
          }
        }
      }
    }

    &__included {
      font-style: italic;
    }

    &__section {
      margin-top: clamp-it(0.35rem, 0.55rem);
    }

    &__icon {
      &--clickable {
        cursor: pointer;
      }
    }

    &--overview {
      .checkout__data__price--inner {
        > div {
          &:first-child {
            @include fluid-fonts(0.6875rem, 0.875rem, 100%, 100%);
          }
        }
      }
    }
  }

  &__total-price {
    margin-top: clamp-it(2.5rem, 3.5rem);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div:first-child {
      @include fluid-fonts(1rem, 1.25rem, 100%, 100%);
      font-weight: 700;
    }

    > div:last-child {
      @include fluid-fonts(1.3rem, 1.55rem, 100%, 100%);
      font-weight: 700;
    }
  }

  hr {
    margin: clamp-it(1.2rem, 1.6rem) 0;
    background-color: $primary;
  }
}
