@import '../variables';
@import '../utils/mediaQueries';
@import '../utils/functions';

$grid-columns: 12;
$section-spacing-y: clamp-it(2rem, 4rem);
$section-spacing-x: clamp-it(1.5rem, 8rem);

// Example:
// <GridContainer>
//  <div className="grid">
//    <div className="grid-col-6 grid-col-md-4-2 grid-col-xl-8-3" />
//  </div>
// </GridContainer>

.grid-section {
  position: relative;
  padding: $section-spacing-y $section-spacing-x;

  &.pull-up-sibling {
    margin-bottom: calc(($section-spacing-y + 11rem) * -1) !important;
    padding-bottom: calc(($section-spacing-y + 11rem)) !important;

    @include medium {
      margin-bottom: calc(($section-spacing-y + 13rem) * -1) !important;
      padding-bottom: calc(($section-spacing-y + 13rem)) !important;
    }
  }
}

.max-width {
  max-width: map-get($grid-breakpoints, xxl);
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat($grid-columns, minmax(0, 1fr));
  grid-gap: clamp-it(0.5rem, 1.5rem);
  grid-row-gap: clamp-it(1.875rem, 3.75rem);
  width: 100%;
  position: relative;
}

// Examples: grid-col-1, grid-col-lg-12, grid-col-xl-8-3
@each $name, $value in $grid-breakpoints {
  @for $i from 0 through $grid-columns {
    @for $j from 0 through ($grid-columns + 1 - $i) {
      @if $name == xs {
        @if $j == 0 {
          .grid-col-#{$i} {
            @include set-grid-columns($i, 0);
          }
        } @else {
          .grid-col-#{$i}-#{$j} {
            @include set-grid-columns($i, $j);
          }
        }
      } @else {
        @include break(map-get($grid-breakpoints, $name)) {
          @if $j == 0 {
            .grid-col-#{$name}-#{$i} {
              @include set-grid-columns($i, 0);
            }
          } @else {
            .grid-col-#{$name}-#{$i}-#{$j} {
              @include set-grid-columns($i, $j);
            }
          }
        }
      }
    }
  }
}
