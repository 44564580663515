@import '../variables';
@import '../utils/functions';

.select-field {
  position: relative;
  display: inline-block;

  .select-field__input {
    color: $primary;
    position: relative;
    background-color: transparent;
    border: 1px solid $primary;
    padding: 0.62rem 0.94rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.25s ease-in;
    width: 100%;

    > span:first-child {
      display: inline-block;
      margin-right: clamp-it(0.25rem, 0.5rem);
      text-align: left;
      line-height: 120%;
    }

    &--active {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &--down {
      transform: translateY(-5px);
    }
  }

  &__options {
    @include set-scrollbar(transparent, $gray-500, 2px, 3px);
    background-color: $white;
    border: 1px solid $gray-500;
    max-height: 10.1rem;
    overflow: auto;
    position: absolute;
    width: 100%;
    top: calc(100% + 0.5rem);
    transition: max-height 0.3s ease-in-out;
    z-index: 20;

    &--hidden {
      max-height: 0;
    }
  }

  &__option {
    padding: 0 0.62rem;
    transition: background-color 0.25s ease-in;
    cursor: pointer;

    &:first-child {
      padding-top: 0.63rem;
    }

    &:last-child {
      padding-bottom: 0.63rem;
    }

    &:not(:last-child) > div {
      border-bottom: 1px solid $gray-500;
    }

    &--selected,
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    > div {
      padding: 0.27rem clamp-it(1.35rem, 1.6rem) 0.22rem;
    }
  }
}
