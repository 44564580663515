@import '../base/grid';
@import '../utils/mediaQueries';
@import '../utils/functions';

$contentMargin: clamp-it(6rem, 12.5rem);
$contentGap: clamp-it(0.84rem, 1.24rem);
$loadingOverlayPadding: clamp-it(0.5rem, 2rem);

.results {
  z-index: 40;
  position: relative;

  .hero {
    height: initial;

    @include xmedium {
      height: 70dvh;
    }

    &--small.hero--lift-content {
      margin-bottom: -14rem;

      @include xmediumdown {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    @extend .max-width;
    position: relative;
    padding: clamp-it(1.125rem, 5.75rem);
    min-height: clamp-it(6rem, 16rem);
    z-index: 10;
    transition: height 0.3s ease-in-out;

    @include xmedium {
      width: 90dvw;
      margin: calc($contentMargin * -1) auto $section-spacing-y;
    }
  }

  &__list {
    position: relative;
    margin-top: clamp-it(1.5rem, 2.5rem);

    &__head {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-400;
      padding-bottom: clamp-it(0.5rem, 0.95rem);
      margin-bottom: clamp-it(1rem, 3.2rem);

      &__inner {
        display: flex;
        align-items: flex-end;
      }

      &__views {
        display: none;
        gap: clamp-it(0.4rem, 0.65rem);

        @include xmedium {
          display: flex;
        }
      }

      &__button.btn.btn-primary {
        @include fluid-fonts(0.6875rem, 0.75rem, 100%, 100%);
        border-color: $primary;
        background-color: transparent;
        color: $primary;
        padding: 0.625rem 0.9375rem;
        transition:
          background-color 0.25s ease-in,
          color 0.25s ease-in;

        &:hover,
        &.results__list__head__button--active {
          background-color: $primary;
          color: $white;
        }
      }
    }

    &__found {
      @include fluid-fonts(1rem, 1.125rem, 100%, 100%);
      font-weight: 500;
      margin-right: clamp-it(1.5rem, 2rem);
    }

    &__toggle {
      display: none !important;

      @include xmedium {
        display: inline-flex !important;
      }
    }

    &__content {
      position: relative;

      &__flipper {
        display: flex;
        flex-wrap: wrap;
        gap: clamp-it(0.94rem, 1.34rem) clamp-it(0.84rem, 1.24rem);

        h4 {
          font-weight: 700;
        }

        > div {
          margin-top: clamp-it(2rem, 2.5rem);
          flex: 1 1 100%;
          max-width: 100%;

          @include medium {
            flex: 1 1 calc(50% - ($contentGap / 2));
            max-width: calc(50% - ($contentGap / 2));
          }

          @include large {
            flex: 1 1 calc((100% / 3) - ($contentGap * 2 / 3));
            max-width: calc((100% / 3) - ($contentGap * 2 / 3));
          }

          @include xlarge {
            flex: 1 1 calc(25% - ($contentGap * 3 / 4));
            max-width: calc(25% - ($contentGap * 3 / 4));
          }
        }
      }

      &:not(.results__list__content--calendar) {
        > div > div > div {
          height: 100%;

          > div {
            height: 100%;

            > a {
              height: 100%;
            }
          }
        }
      }

      &--calendar {
        gap: 0;

        > div {
          @include medium {
            flex: 1 1 100%;
            max-width: 100%;
          }

          @include large {
            flex: 1 1 100%;
            max-width: 100%;
          }

          @include xlarge {
            flex: 1 1 100%;
            max-width: 100%;
          }

          h4 {
            margin-top: 1.5rem;
            @include large {
              margin-bottom: clamp-it(0.8rem, 1.5rem);
            }
          }

          > div {
            display: flex;
            flex-wrap: wrap;
            gap: 0 clamp-it(0.84rem, 1.24rem);

            > div {
              flex: 1 1 100%;
              max-width: 100%;

              @include medium {
                flex: 1 1 calc(50% - ($contentGap / 2));
                max-width: calc(50% - ($contentGap / 2));
              }

              @include large {
                flex: 1 1 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    &__sort {
      &--desktop {
        display: none;

        @include xmedium {
          display: flex;
          justify-content: flex-end;
        }
      }

      &--mobile {
        display: flex;

        @include xmedium {
          display: none;
        }
      }
    }

    &__loading {
      position: absolute;
      left: calc($loadingOverlayPadding * -1);
      top: calc($loadingOverlayPadding * -1);
      width: calc(100% + $loadingOverlayPadding * 2);
      height: calc(100% + $loadingOverlayPadding * 2);
      background-color: rgba(0, 0, 0, 0.14);
      opacity: 1;
      transition: opacity 0.25s ease-in;

      &--hidden {
        opacity: 0;
      }

      > svg {
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 6rem;
      }
    }
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 0.45s ease-in;

  &--transition {
    opacity: 1;
  }
}
