@import '../utils/functions';
@import '../base/typography';

.link-banner {
  padding: clamp-it(2rem, 4.125rem) clamp-it(1.6rem, 8rem) clamp-it(3.25rem, 5.375rem);

  p {
    margin-bottom: 0;
  }

  &__container {
    position: relative;
    z-index: 1;

    .max-width {
      max-width: map-get($grid-breakpoints, lg);
    }
  }

  &__headline {
    margin-bottom: clamp-it(1.8rem, 3.8rem);
  }

  &__links {
    column-count: 1;

    @include medium {
      column-count: 2;
    }
  }

  a {
    padding-left: 0;

    &:not([class*='btn']) {
      @extend %albatros-font;
      @include fluid-fonts(1.375rem, 2rem, 160%, 140%);
      font-weight: 300;

      @include medium {
        font-weight: 400;
      }
    }

    &.btn.alb-link--disabled,
    &.btn {
      margin-top: 0.5rem;

      > span:first-child {
        border-bottom: 0 !important;
      }

      &:hover {
        > span:first-child {
          border-bottom: 0 !important;
        }
      }
    }

    svg {
      @include fluid-fonts(0.75rem, 0.9rem, 100%, 100%);
    }
  }
}
