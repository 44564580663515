@import '../utils/functions';
@import '../variables';

.city-main {
  padding: clamp-it(1.125rem, 5.75rem);

  &__container {
    &__description {
      padding-right: clamp-it(0rem, 4.5rem);
      @include medium {
        width: 50%;
      }
    }

    &__link {
      border-bottom: solid 1px;
      padding-bottom: clamp-it(0.125rem, 0.313rem);
    }

    &__link:hover {
      text-decoration: none;
      cursor: pointer;
    }

    &__inner {
      padding-left: clamp-it(0rem, 4.5rem);
      @include medium {
        width: 50%;
      }

      &__citizens {
        margin-bottom: clamp-it(1.563rem, 2.375rem);
        width: 50%;
      }

      &__foundation {
        margin-bottom: clamp-it(1.563rem, 2.375rem);
        width: 50%;
      }

      &__language {
        margin-bottom: clamp-it(1.563rem, 3.5rem);
        width: 50%;
      }

      &__headline {
        @include fluid-fonts(0.688rem, 0.875rem, 190%, 190%);
        font-weight: 500;
        margin-bottom: clamp-it(1rem, 1.25rem);
      }

      &__text {
        @include fluid-fonts(1.5rem, 1.75rem, 140%, 140%);
        font-family: var(--albatros-font);
        margin-right: clamp-it(0.8rem, 0.906rem);

        svg {
          height: 0.9rem;
          width: 1rem;

          path {
            fill: none;
          }
        }
      }
    }
  }

  &__headline {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
  }
}
