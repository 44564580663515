@import '../variables';
@import '../utils/functions';
@import '../base/macros';

.confirmation {
  &__upper {
    background-color: $primary-light-blue;
    padding: clamp-it(1rem, 2.2rem);
    text-align: center;

    &__headline {
      @include fluid-fonts(1.75rem, 2.8rem, 130%, 140%);
      @extend %albatros-font;
    }

    &__sub-headline {
      @include fluid-fonts(1.125rem, 1.25rem, 145%, 160%);

      &--bold {
        font-weight: 500;
      }
    }

    &__booking-number {
      @extend %body-large;
      font-weight: 500;
    }
  }

  &__lower {
    background-color: $white;
    padding: clamp-it(1rem, 3.875rem) clamp-it(1rem, 10rem);

    &__hotel {
      display: flex;
      flex-direction: column;
      margin-bottom: clamp-it(1.8rem, 3rem);
      flex-wrap: wrap;

      @include xlarge {
        flex-direction: row;
      }

      &__name {
        @include fluid-fonts(1.3rem, 1.5rem, 100%, 100%);
        font-weight: 700;
        margin-right: clamp-it(0.5rem, 5rem);
        margin-bottom: clamp-it(0.95rem, 1.3rem);
      }

      &__info {
        flex: 1 1 auto;

        .checkout__data {
          font-size: clamp-it(0.688rem, 0.813rem);
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          @include xlarge {
            flex-direction: row;
          }

          @include xxlarge {
            justify-content: flex-end;
          }

          > div {
            position: relative;

            &:not(:last-child) {
              padding-right: clamp-it(1rem, 1.6rem);

              &::after {
                content: '';
                display: none;
                background-color: $gray-400;
                width: 1px;
                height: 100%;
                position: absolute;
                right: clamp-it(0.5rem, 0.8rem);

                @include xlarge {
                  display: inline-block;
                }
              }
            }

            > svg {
              margin-right: clamp-it(0.35rem, 0.6rem);
            }
          }
        }
      }
    }

    &__travelers {
      display: flex;
      flex-direction: column;

      @include xxlarge {
        flex-direction: row;
      }

      &__inner {
        @include xxlarge {
          flex: 1 1 75%;
          max-width: 75%;
        }

        @media screen and (min-width: 1650px) {
          flex: 1 1 70%;
          max-width: 70%;
        }
      }

      &__remarks {
        @include xxlarge {
          padding-left: 3.25rem;
          flex: 1 1 25%;
          max-width: 25%;
        }

        @media screen and (min-width: 1650px) {
          flex: 1 1 30%;
          max-width: 30%;
        }

        > .confirmation__lower__travelers__list__label {
          @include xlarge {
            flex: initial;
            min-width: initial;
          }
        }

        > div:last-child {
          @include fluid-fonts(0.875rem, 1rem, 110%, 115%);
        }
      }
    }

    &__applicant {
      display: flex;
      flex-direction: column;
      margin-bottom: clamp-it(1.35rem, 2rem);

      @include xlarge {
        flex-direction: row;
        justify-content: space-between;
      }

      &__container {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include xlarge {
          flex-direction: row;
        }
      }

      &__label {
        @include fluid-fonts(1rem, 1.125rem, 120%, 120%);
        font-weight: 600;
        margin-bottom: clamp-it(0.8rem, 1.4rem);
        padding-top: 0.18rem;

        @include xlarge {
          flex: 0 1 12rem;
          min-width: 12rem;
        }
      }
    }

    &__travelers__list {
      display: flex;
      flex-direction: column;
      margin-bottom: clamp-it(1.35rem, 2rem);

      @include xlarge {
        flex-direction: row;
      }

      @include xxlarge {
        margin-bottom: 0;
      }

      &__label {
        @include fluid-fonts(1rem, 1.125rem, 120%, 120%);
        font-weight: 600;
        margin-bottom: clamp-it(0.8rem, 1.4rem);
        padding-top: 0.18rem;

        @include xlarge {
          flex: 0 1 12rem;
          min-width: 12rem;
        }
      }

      table {
        width: 100%;
        display: inline-block;

        @include xlarge {
          display: table;
        }

        th {
          &:first-child {
            display: none;

            @include xlarge {
              display: table-cell;
            }
          }

          &:not(:first-child) {
            padding-right: 1rem;
          }
        }
      }
    }

    &__flight {
      &__headline {
        @include fluid-fonts(1rem, 1.125rem, 120%, 120%);
        font-weight: 600;
        margin-bottom: clamp-it(0.8rem, 1.4rem);
      }

      &__labels {
        display: none;

        @include xlarge {
          display: block;
        }
        > div {
          @extend %body;
          font-weight: 600;
          position: relative;

          @include xlarge {
            flex: 0 1 12rem;
            min-width: 12rem;
          }

          &:first-child {
            top: 0.813rem;
          }

          &:last-child {
            top: 2.438rem;
          }
        }
      }

      &__data {
        width: 100%;
      }

      &__inner {
        display: flex;
        flex-direction: column;

        @include xlarge {
          flex-direction: row;
        }

        @include xxlarge {
          width: 85%;
        }

        @media screen and (min-width: 1650px) {
          width: 65%;
        }
      }

      table {
        width: 100%;
        display: inline-block;

        @include xlarge {
          display: table;
        }

        th {
          font-weight: 400;
          height: 3.5rem;

          &:not(:last-child) {
            padding-right: 2rem;

            @include xlarge {
              padding-right: 0;
            }
          }

          img {
            max-height: clamp-it(1.813rem, 2.063rem);
            width: auto;
            top: 0;
          }
        }
      }
    }

    &__total {
      display: flex;
      justify-content: flex-end;
      @include fluid-fonts(1.25rem, 1.65rem, 100%, 100%);
      font-weight: 700;
    }
  }
}
