@import '../utils/functions';
@import '../variables';

.iso-modal {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100dvh;
  width: 100vw;
  max-height: 100dvh !important;
  max-width: 100vw !important;
  padding: clamp-it(0.9rem, 1.5rem);
  margin: 0;

  &__body {
    height: 100%;
    padding: 0;
  }

  .modal-content {
    border-radius: 0;
    height: 100%;
    background-color: $white;
    overflow: hidden;
    border: 0;
  }

  &__close {
    font-size: 1.8rem;
    cursor: pointer;

    &__button {
      svg {
        height: clamp-it(1.5rem, 3.25rem);
        width: clamp-it(1.5rem, 3.25rem);

        rect,
        .theme-dark-green svg path {
          fill: $black;
        }
      }
    }

    &__button:hover {
      cursor: pointer;
    }

    &__container {
      position: absolute;
      z-index: 1;
      right: 0;
      padding: clamp-it(0.62rem, 2.38rem) clamp-it(0.56rem, 3.75rem) 0 0;
    }
  }

  &__content {
    height: 100%;
    position: relative;
  }
}
