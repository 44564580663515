@import '../utils/functions';

.product-selection {
  padding: clamp-it(1.125rem, 2.6875rem) clamp-it(0.75rem, 2.5rem);
  background-color: $white;
  gap: clamp-it(1rem, 3rem);

  .select-field {
    width: 100%;

    &__input {
      border-color: $grey;
      height: 3.125rem;

      &--active {
        background-color: transparent;
      }

      svg {
        font-size: 0.85rem;
        top: 2px;
        position: relative;
      }

      .product-selection__option svg {
        position: relative;
        top: 0;
      }
    }

    &__options {
      border-color: $primary-dark-blue;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    }

    &__option {
      &--selected,
      &:hover {
        background-color: $primary-dark-blue;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $primary-dark-blue;
      }

      &:not(:last-child) > div {
        border-bottom: 0;
      }
    }
  }

  &__option {
    svg {
      font-size: 0.8rem;
      margin-right: 0.8rem;
    }
  }

  &__label {
    font-size: clamp-it(0.812rem, 0.875rem);
    white-space: nowrap;

    svg {
      margin-right: clamp-it(0.65rem, 1rem);
    }
  }
}
