@import '../utils/functions';
@import '../base/macros';

.payment {
  &.grid-section {
    padding-bottom: 0;
  }

  &__headline {
    @include fluid-fonts(1.5rem, 2rem, 135%, 120%);
    font-weight: 500;
    margin-bottom: clamp-it(1.7rem, 2.56rem);
    display: none;

    @include xmedium {
      display: block;
    }
  }

  &__selection {
    background-color: $white;

    &__mobile {
      &__option {
        background-color: $white;
        padding: clamp-it(1rem, 1.5rem);

        &:first-child {
          margin-bottom: 1.2rem;
        }

        &--disabled {
          display: none;
        }
      }

      &__chevron {
        transition: transform 0.25s ease-in;

        &--active {
          transform: rotate(90deg);
        }

        path {
          stroke-width: 2px;
        }
      }

      &__button.btn.btn-secondary {
        font-size: clamp-it(1.2rem, 1.6rem) !important;
        width: 100%;
        line-height: 1;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding: 0;
        opacity: 1;
        transition: opacity 0.25s ease-in;

        &:focus {
          outline: 0;
        }

        &--disabled {
          opacity: 0.25;
          cursor: default;
        }
      }

      &__header {
        text-align: left;
      }
    }
  }

  &__tabs {
    display: flex;
  }

  &__tab {
    @extend %body-large;
    font-weight: 500;
    padding: clamp-it(1.4rem, 2.313rem);
    text-align: center;
    flex: 1 1 auto;
    transition:
      box-shadow 0.25s ease-in,
      opacity 0.25s ease-in;
    cursor: pointer;
    box-shadow: 4px -2px 20px 5px rgba(0, 0, 0, 0.05) inset;
    opacity: 1;

    &--active {
      box-shadow: none;
    }

    &--disabled {
      display: none;
    }
  }

  &__content {
    padding: clamp-it(0.75rem, 3rem) 0 0;

    @include xmedium {
      padding: clamp-it(1.5rem, 3rem) clamp-it(1rem, 6.75rem);
    }
  }

  &__radio {
    border: 1px solid $primary;
    height: clamp-it(0.85rem, 1.1rem);
    width: clamp-it(0.85rem, 1.1rem);
    border-radius: 50%;
    transition: background-color 0.25s ease-in;
    flex: 0 0 clamp-it(0.85rem, 1.1rem);
  }

  &__label {
    flex: 1 1 auto;
    padding-left: clamp-it(1rem, 1.563rem);

    &__upper {
      display: flex;
      font-weight: 600;
      flex-direction: column;

      @include xmedium {
        flex-direction: row;
        margin-bottom: 0.6rem;
      }

      > div:first-child {
        margin-right: clamp-it(1.35rem, 2rem);
        line-height: 1.2;
        margin-bottom: clamp-it(0.6rem, 1rem);
      }

      &--elevated {
        margin-top: -0.3rem;
      }
    }

    &__lower {
      @extend %body-small;
    }

    &__icons {
      margin-bottom: clamp-it(0.6rem, 1rem);

      > img {
        height: auto;
        width: clamp-it(2.2rem, 3.8rem);
        margin-bottom: 0.5rem;

        &:not(:last-child) {
          margin-right: clamp-it(0.85rem, 1.35rem);
        }
      }
    }
  }

  &__single-icon {
    @include fluid-fonts(1.8rem, 2.4rem, 100%, 100%);
    margin-left: clamp-it(1rem, 1.563rem);
  }

  &__option {
    border: 1px solid $gray-900;
    padding: clamp-it(0.8rem, 1.625rem) clamp-it(0.8rem, 3rem);
    display: flex;
    cursor: pointer;
    transition: border-color 0.25s ease-in;

    &:not(:last-child) {
      margin-bottom: clamp-it(0.85rem, 1.2rem);
    }

    &--active {
      border-color: $primary;

      .payment__radio {
        background-color: $primary;
      }
    }

    &--disabled {
      cursor: default;
      opacity: 0.15;
    }
  }

  &__voucher {
    margin: clamp-it(2.5rem, 3.5rem) 0;

    &__headline {
      @include fluid-fonts(1.2rem, 1.5rem, 135%, 120%);
      font-weight: 500;
      margin-bottom: clamp-it(0.35rem, 0.55rem);

      + div {
        line-height: 150%;
      }
    }

    &__input {
      display: flex;
      margin-top: clamp-it(1rem, 1.5rem);

      input {
        @include fluid-fonts(0.75rem, 1rem, 100%, 100%);
        padding: 0 clamp-it(1rem, 1.35rem);
        border: 1px solid $gray-900 !important;
        max-width: 17rem;
        width: 100%;
        margin-right: clamp-it(0.85rem, 1.2rem);

        &:hover,
        &:active,
        &:focus {
          border-bottom: 0;
        }

        &::placeholder {
          @include fluid-fonts(0.75rem, 1rem, 100%, 100%);
        }
      }

      button {
        height: 2.5rem;
        padding-left: clamp-it(0.75rem, 0.95rem) !important;
        padding-right: clamp-it(0.75rem, 0.95rem) !important;
      }
    }

    &__status {
      @include fluid-fonts(0.72rem, 0.95rem, 100%, 100%);
      color: $red;
      margin-top: clamp-it(0.5rem, 0.75rem);
      flex: 1 0 100%;

      &--success {
        color: $material-green;
      }
    }
  }
}
