@import '../utils/functions';
@import '../base/grid';

.contact-bar {
  position: fixed;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.3125rem 0;
  transition: transform 0.6s ease-in;
  transform: translateY(0);
  box-shadow: 0 6px 8px 1px rgba(0, 0, 0, 0.5);

  &--hidden {
    transform: translateY(100%);
  }

  @include medium {
    justify-content: space-between;
    padding: 0.3125rem clamp-it(1rem, 5rem) 0.3125rem $section-spacing-x;
  }

  .separator {
    width: 1px;
    margin-right: clamp-it(1.2rem, 9rem);
    height: 50px;

    @include medium {
      margin-right: clamp-it(0.875rem, 2.5rem);
    }
  }

  &__text {
    @include fluid-fonts(0.75rem, 0.875rem, 120%, 110%);
    display: none;
    justify-content: center;
    padding-right: 1rem;

    @include medium {
      display: flex;
    }
  }

  &__contact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include medium {
      width: auto;
    }
  }

  &__contact-box {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      svg {
        @include fluid-fonts(0.55rem, 0.65rem, 100%, 100%);
        position: relative;
        top: -4px;
      }
    }

    &:last-child {
      .contact-bar__contact-text {
        @include medium {
          margin: 0 clamp-it(0.875rem, 0.5rem) 0 clamp-it(0.875rem, 1rem);
        }
      }

      svg {
        @include fluid-fonts(0.9rem, 1rem, 100%, 100%);
      }
    }
  }

  &__contact-text {
    @include fluid-fonts(0.75rem, 0.875rem, 120%, 110%);
    margin: 0 clamp-it(1.2rem, 9rem) 0 clamp-it(0.875rem, 1rem);
    white-space: nowrap;

    @include medium {
      margin: 0 clamp-it(0.875rem, 2.5rem) 0 clamp-it(0.875rem, 1rem);
    }

    &--desktop {
      display: none;

      @include medium {
        display: block;
      }
    }

    &--mobile {
      display: block;

      @include medium {
        display: none;
      }
    }
  }
}
