@import '../utils/functions';
@import '../base/typography';
@import '../base/macros';
@import '../base/grid';

$selectFieldsGap: clamp-it(1rem, 1.56rem);

.dates-prices {
  &--mobile {
    border-bottom: 2px solid $grey;

    @include xmedium {
      display: none;
    }

    h5 {
      text-transform: uppercase;
    }

    .dates-prices__link {
    }
  }

  &--desktop {
    display: none;

    @include xmedium {
      display: block;
    }
  }

  &.grid-section {
    padding-top: clamp-it(1.7rem, 3.4rem);
  }

  h2 {
    margin-bottom: clamp-it(1.8rem, 3.5rem);
  }

  table {
    width: 100%;

    h4 {
      font-weight: 500;
      text-transform: uppercase;
    }

    th {
      @include fluid-fonts(0.6875rem, 0.875rem, 100%, 100%);
      font-weight: 500;
    }

    tbody {
      tr {
        position: relative;

        &:not(.dates-prices__month) {
          cursor: pointer;
        }

        td {
          position: relative;

          &:first-child > span {
            margin-left: calc($section-spacing-x * -1);
            padding-left: $section-spacing-x;
          }

          &:last-child > span {
            margin-right: calc($section-spacing-x * -1);
            padding-right: $section-spacing-x;
          }
        }

        td {
          > span {
            display: block;
            padding: clamp-it(0.85rem, 1.35rem) 0;
          }

          &.dates-prices__button > span {
            padding-top: clamp-it(0.12rem, 0.62rem);
            padding-bottom: clamp-it(0.12rem, 0.62rem);
          }
        }

        hr {
          border-color: $black;
          height: 1px;
          width: 100%;
          margin: clamp-it(1.5rem, 2.5rem) 0 clamp-it(1.8rem, 3rem);
        }

        &:first-child {
          hr {
            margin-top: clamp-it(0.8rem, 1.8rem);
          }
        }
      }
    }
  }

  &__button.theme-dark-green {
    background-color: inherit;
    color: inherit;
    text-align: right;

    > span {
      display: block;
    }

    .btn.btn-primary {
      white-space: nowrap;

      &:hover {
        color: $primary;
        border: 1px solid $primary;
        background-color: $secondary-sand;

        > span {
          color: $primary;
        }
      }

      &:disabled {
        background-color: $gray-900;
        border-color: $gray-900;
        color: $white;

        &:hover {
          background-color: $gray-900;
          border-color: $gray-900;
          color: $white;

          > span {
            color: $white;
          }
        }
      }
    }
  }

  &__product {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc($section-spacing-x * -1);
    margin-right: calc($section-spacing-x * -1);
    padding: clamp-it(1rem, 1.8rem) $section-spacing-x;

    &:last-child {
      padding-bottom: clamp-it(1rem, 2rem);
    }

    &:not(:first-child) {
      margin-top: clamp-it(1.5rem, 2rem);
    }

    &--active {
      cursor: default !important;
    }

    &__item {
      flex: 1 1 50%;
      max-width: 50%;
      margin-top: clamp-it(1rem, 1.5rem);

      > div {
        &:first-child {
          @include fluid-fonts(0.6875rem, 0.8125rem, 100%, 100%);
          font-weight: 700;
          margin-bottom: clamp-it(0.5rem, 0.85rem);
        }

        &:last-child {
          @extend .list;
        }
      }

      &--variant {
        flex: 1 1 100%;
        max-width: 100%;
        margin-top: 0;

        .dates-prices__link {
          position: relative;
          top: 0;
          transform: none;
        }

        + .dates-prices__product__item {
          margin-top: clamp-it(0.75rem, 1rem);
        }
      }

      &__link {
        display: inline-block;
        cursor: pointer;

        svg {
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }
      }
    }

    &__button.dates-prices__button {
      flex: 1 1 100%;
      max-width: 100%;
      margin-top: clamp-it(1.6rem, 2.5rem);
      text-align: left;
    }
  }

  &__link {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(-150%, -50%) translateY(-2px);

    &:hover {
      box-shadow: none !important;
    }

    svg {
      font-size: clamp-it(1.3rem, 1.375rem);
    }
  }

  &__variant {
    &-popover {
      box-shadow: 0 0 3px -1px $gray-500;

      &__head {
        font-weight: 700;
      }

      .popover {
        &-header {
          background-color: $gray-400;
          color: $primary;
        }

        &-body {
          @extend %body;
          color: $primary;
        }
      }
    }

    &-row {
      &--active {
        cursor: default !important;
      }
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;
    margin-bottom: clamp-it(1.85rem, 2.4rem);
    gap: $selectFieldsGap;

    @include xmediumdown {
      background-color: $secondary-sand-darker;
      margin: 0 calc($section-spacing-x * -1) clamp-it(1.85rem, 2.4rem);
      padding: clamp-it(0.85rem, 1.2rem) $section-spacing-x;
    }

    @include xmedium {
      flex-direction: row;
      width: 75%;
    }

    @include large {
      width: 50%;
    }

    .select-field {
      background-color: $secondary-sand;
      min-width: 13.4375rem;

      @include xmedium {
        width: calc(50% - $selectFieldsGap / 2);
      }

      .select-field__input {
        &--active {
          background-color: darken($secondary-sand, 5%);
        }

        > span:first-child {
          margin-right: 1rem;
        }
      }

      &__options {
        background-color: $secondary-sand;
      }

      &__option {
        &--selected,
        &:hover {
          background-color: darken($secondary-sand, 5%);
        }
      }
    }

    &__departure {
      .select-field__option:first-child {
        &.select-field__option--selected {
          background-color: $secondary-sand;
        }
      }
    }
  }
}

.theme-sand {
  table {
    tbody {
      tr {
        &:not(.dates-prices__month):hover {
          @extend .contrast;

          td > span {
            @extend .contrast;
          }

          td:first-child > span {
            @extend .outline;
          }

          td:last-child > span {
            @extend .outline-double;
          }
        }
      }
    }
  }

  .dates-prices {
    &__variant-row {
      &--active {
        @extend .contrast;

        td > span {
          @extend .contrast;
        }

        td:first-child > span {
          @extend .outline;
        }

        td:last-child > span {
          @extend .outline-double;
        }
      }
    }

    &--mobile {
      .dates-prices__product--active {
        @extend .contrast;
      }
    }
  }
}

.theme-dark-green {
  table {
    tbody {
      tr {
        &:not(.dates-prices__month):hover {
          @extend .contrast;

          td > span {
            @extend .contrast;
          }

          td:first-child > span {
            @extend .outline;
          }

          td:last-child > span {
            @extend .outline-double;
          }
        }
      }
    }
  }

  .dates-prices {
    &__variant-row {
      &--active {
        @extend .contrast;

        td > span {
          @extend .contrast;
        }

        td:first-child > span {
          @extend .outline;
        }

        td:last-child > span {
          @extend .outline-double;
        }
      }
    }

    &__filters {
      @include xmediumdown {
        background-color: lighten($primary-light-green, 20%) !important;
      }

      .select-field {
        &__input {
          @extend .contrast;
          color: $white;

          &.select-field__input--active {
            @extend .contrast;
            color: $white;
          }
        }

        &__options {
          @extend .contrast;
          color: $white;
          border: 0;
        }

        &__option {
          &:hover,
          &--selected {
            background-color: lighten($primary-light-green, 12%) !important;
          }
        }
      }
    }

    &--mobile {
      .dates-prices__product--active {
        @extend .contrast;
      }
    }
  }
}

.theme-blue {
  table {
    tbody {
      tr {
        &:not(.dates-prices__month):hover {
          @extend .contrast;

          td > span {
            @extend .contrast;
          }

          td:first-child > span {
            @extend .outline;
          }

          td:last-child > span {
            @extend .outline-double;
          }
        }
      }
    }
  }

  .dates-prices {
    &__variant-row {
      &--active {
        @extend .contrast;

        td > span {
          @extend .contrast;
        }

        td:first-child > span {
          @extend .outline;
        }

        td:last-child > span {
          @extend .outline-double;
        }
      }
    }

    &__filters {
      @include xmediumdown {
        background-color: darken($primary-dark-blue, 14.2%) !important;
      }

      .select-field {
        &__input {
          @extend .contrast;

          &.select-field__input--active {
            @extend .contrast;
          }
        }

        &__options {
          @extend .contrast;
        }

        &__option {
          &:hover,
          &--selected {
            background-color: darken($primary-dark-blue, 12%) !important;
          }
        }
      }
    }

    &--mobile {
      .dates-prices__product--active {
        @extend .contrast;
      }
    }
  }
}

.theme-white {
  table {
    tbody {
      tr {
        &:not(.dates-prices__month):hover {
          @extend .contrast;

          td > span {
            @extend .contrast;
          }

          td:first-child > span {
            @extend .outline;
          }

          td:last-child > span {
            @extend .outline-double;
          }
        }
      }
    }
  }

  .dates-prices {
    &__variant-row {
      &--active {
        @extend .contrast;

        td > span {
          @extend .contrast;
        }

        td:first-child > span {
          @extend .outline;
        }

        td:last-child > span {
          @extend .outline-double;
        }
      }
    }

    &__filters {
      @include xmediumdown {
        background-color: darken($primary-light-blue-contrast, 14%) !important;
      }

      .select-field {
        &__input {
          @extend .contrast;

          &.select-field__input--active {
            @extend .contrast;
          }
        }

        &__options {
          @extend .contrast;
        }

        &__option {
          &:hover,
          &--selected {
            background-color: darken($primary-light-blue-contrast, 10%) !important;
          }
        }
      }
    }

    &--mobile {
      .dates-prices__product--active {
        @extend .contrast;
      }
    }
  }
}
