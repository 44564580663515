@import '../utils/functions';

.station-selection {
  .seat-selection__headline {
    margin-bottom: clamp-it(0.65rem, 1.4rem);
  }

  &__container {
    > div {
      @include xmedium {
        flex: 1 1 40%;
        max-width: 40%;
      }
    }

    label {
      @include fluid-fonts(0.75rem, 0.875rem, 100%, 100%);
      line-height: 1;
      margin-top: clamp-it(1rem, 1.75rem);
    }

    .select-field {
      display: block;

      &__input {
        border: 3px solid rgba(220, 220, 220, 0.3);
        border-radius: 1px;

        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__option {
        line-height: 1.2;

        > div {
          padding: clamp-it(0.4rem, 0.65rem) 0;
        }
      }
    }

    @include xmedium {
      display: flex;
      gap: clamp-it(1rem, 4rem);
      justify-content: center;
    }
  }
}
