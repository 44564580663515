@import '../utils/mediaQueries';
@import '../utils/functions';

$componentSpacingBottom: clamp-it(1rem, 1.5rem);
$containerHorizontalPadding: clamp-it(0.5rem, 8rem);
$closeButtonMargin: clamp-it(1rem, 1.5rem);

.accommodation {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    position: relative;

    .accordion-element & {
      grid-column: span 12;

      @include medium {
        grid-column: initial;
      }
    }

    @include medium {
      opacity: 0;
    }

    &--visible {
      opacity: 1;
    }

    &--exiting {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &__name {
      flex: 0 0 100%;
    }

    &__short-description {
      padding-bottom: $componentSpacingBottom;
      flex: 0 0 100%;

      .theme-dark-green & {
        color: $white;
      }
    }

    &__star-rating {
      padding-bottom: $componentSpacingBottom;

      .theme-dark-green & {
        svg {
          stroke: $white;
        }
      }
    }

    &__address {
      padding-bottom: $componentSpacingBottom;
    }

    &__more-information {
      padding-bottom: $componentSpacingBottom;
    }

    &__wrapper {
      @include medium {
        flex: 0 0 40%;
        box-sizing: border-box;
      }
    }

    &__description-top-right {
      padding-bottom: $componentSpacingBottom;
      @include medium {
        flex: 0 0 40%;
        box-sizing: border-box;
      }
    }

    &__description {
      position: relative;

      &__remaining {
        padding-bottom: 20px;
        transition: opacity 0.5s ease-in;
        opacity: 1;
        &--hidden {
          opacity: 0;
        }
      }
    }

    &__read-more {
      @include fluid-fonts(0.75rem, 0.875rem, 130%, 130%);
      display: inline-block;
      margin-bottom: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__modal {
    background-color: $primary-light-blue;
    color: $primary;
    padding: clamp-it(1rem, 6rem) $containerHorizontalPadding;
    display: flex;
    align-items: flex-end;

    @include xmedium {
      display: block;
    }

    &__container {
      display: flex;
      height: 100%;
      background-color: $white;
      align-items: flex-start;

      @include xmedium {
        display: block;
      }

      .accommodation__content {
        &__name__container {
          padding: clamp-it(1rem, 2.5rem);
        }
      }
    }

    .iso-modal__close {
      &__container {
        padding: 0;
        transform: translateY(-100%) translateY(calc($closeButtonMargin * -1));

        @include xmedium {
          transform: translateX(100%) translateX(clamp-it(0.75rem, 1.5rem));
        }
      }

      &__button {
        circle {
          fill: transparent;
          stroke: $primary;
        }

        rect {
          stroke: $primary;
        }
      }
    }

    .accommodation {
      &__content {
        color: $primary;
        background-color: $white;
        padding-top: 0;

        @include medium {
          opacity: 1;
        }

        &__single-image {
          width: 100%;
          height: clamp-it(12rem, 20rem);
          object-fit: cover;
        }

        &__name {
          margin-bottom: clamp-it(0.65rem, 1.2rem);
        }

        &__container {
          padding: clamp-it(1rem, 3.875rem) clamp-it(1.5rem, 6.25rem);

          .rating-star {
            stroke: $primary !important;

            path:first-child {
              stroke: $primary !important;
            }

            &--half {
              path:last-child {
                fill: $primary !important;
              }
            }

            &--full {
              path:first-child {
                fill: $primary !important;
              }

              path:last-child {
                fill: $primary !important;
              }
            }
          }
        }
      }
    }

    > .modal-content {
      max-width: map-get($grid-breakpoints, xl);
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.35);
      border: 1px solid $gray-900;
      margin: 0 auto;
      height: calc(100% - 2rem);
      overflow: initial;

      @include xmedium {
        height: 100%;
      }
    }

    .iso-modal__content {
      @include set-scrollbar($gray-300, $primary-light-green);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.singleHotel {
  position: relative;
  &__background {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: -1;
    top: 15.625rem;
    @include small {
      top: clamp-it(15.625rem, 28.125rem);
    }
  }
}
