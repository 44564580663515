@import '../variables';
@import '../utils/functions';
@import '../base/macros';

.sort-dropdown {
  display: inline-block;
  position: relative;

  &__label {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &__static {
      @extend %body-small;
      font-weight: 700;

      @include xmedium {
        font-weight: 500;
      }
    }

    &__delimiter {
      @extend %body-small;
      display: none;

      @include xmedium {
        display: inline-block;
        margin: 0 clamp-it(0.15rem, 0.25rem);
      }
    }

    &__option {
      @extend %body-large;
      font-weight: 500;
      display: none;

      @include xmedium {
        display: block;
      }
    }

    &__icon {
      @include fluid-fonts(0.6875rem, 0.875rem, 100%, 100%);
      margin-left: clamp-it(0.32rem, 0.42rem);
    }
  }

  &__menu {
    background-color: $white;
    padding: clamp-it(0.35rem, 0.55rem);
    position: absolute;
    right: 0;
    top: calc(100% + 0.4rem);
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    min-width: 11.25rem;
  }

  &__option {
    padding: clamp-it(0.15rem, 0.315rem) clamp-it(0.75rem, 1.25rem);
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-400;
    }

    &--active {
      font-weight: 700;
    }
  }
}
