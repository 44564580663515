@import '../utils/functions';
@import '../base/macros';
@import './introduction';

.product-reviews {
  width: 100%;
  max-width: 40.25rem;
  margin: 0 auto;
  position: relative;

  &__container {
    padding-top: 0 !important;
    margin-top: calc($gridBottomSpacing * -1);
  }

  &__inner {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transition: transform 0.65s ease-in-out;
  }

  &__item {
    padding: clamp-it(0.25rem, 0.35rem) clamp-it(0.85rem, 6rem) clamp-it(1rem, 1.5rem);
  }

  .nav_btn.expanded-gallery__button {
    display: inline-block;
    top: 50%;
    bottom: initial;
    transform: initial;
    margin: 0;
    left: 0;
    right: initial;
    z-index: 10;
    transition: opacity 0.25s ease-in;

    &:hover {
      circle {
        fill: $secondary-accent-green !important;
      }

      path {
        stroke: $primary !important;
      }
    }

    &--next {
      right: 0;
      left: initial;
      transform: translate(50%, -50%);

      &.product-reviews--previous {
        left: 0;
        right: initial;
        transform: translate(-50%, -50%);
      }
    }

    &.product-reviews--inactive {
      opacity: 0;
      cursor: default;
    }
  }

  &__rating {
    margin-bottom: clamp-it(1.25rem, 1.95rem);

    svg {
      @include fluid-fonts(0.85rem, 1rem, 100%, 100%);
      width: 1em;
      height: 1em;
      margin-right: 0.25rem;
    }
  }

  &__name {
    @extend %body-small;
    line-height: 1;
    margin-bottom: clamp-it(0.225rem, 0.36rem);
    font-weight: 200;
  }

  &__text {
    @extend %body-large;
    font-weight: 500;
    margin-bottom: clamp-it(0.75rem, 1.2rem);
  }

  &__link {
    &:hover {
      box-shadow: none !important;
    }
  }

  &__bars {
    display: flex;
    gap: clamp-it(0.188rem, 0.313rem);
    justify-content: space-between;
    margin-bottom: clamp-it(0.85rem, 1.4rem);
    padding: clamp-it(0.3rem, 0.55rem);

    &__item {
      flex: 1 1 auto;
      height: clamp-it(0.24rem, 0.35rem);
      border-radius: 6px;
      background-color: lighten($primary-light-green, 50%);
      transition: background-color 0.25s ease-in;

      &--active {
        background-color: $primary-light-green;
      }
    }
  }
}

.theme-dark-green,
.theme-medium-green {
  .product-reviews {
    .nav_btn.expanded-gallery__button {
      &:hover {
        circle {
          fill: $primary !important;
        }

        path {
          stroke: $secondary-accent-green !important;
        }
      }
    }

    &__bars {
      &__item {
        background-color: rgba(255, 255, 255, 0.3);

        &--active {
          background-color: $white;
        }
      }
    }

    &__link {
      color: $white;

      > span {
        color: $white !important;
      }

      &:hover {
        > span {
          color: $white !important;
        }
      }
    }
  }
}
