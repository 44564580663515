@import '../variables';
@import '../utils/functions';

.product-page {
  &__content {
    > div {
      min-height: 22rem;
    }
  }

  .hero {
    height: calc(100dvh - 3rem);

    &__down .circle-caret-down {
      > div {
        circle {
          fill: $white;
        }

        path {
          stroke: $primary;
        }
      }
    }
  }

  h1.hero__head {
    @include fluid-fonts(1.75rem, 6rem, 115%, 115%);
  }

  .accommodation {
    .accommodation__title {
      justify-content: center;
      @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
      font-weight: 500;
      margin-bottom: clamp-it(1.25rem, 3rem);
      text-align: center;
    }
  }

  .singleHotel .accommodation__content {
    opacity: 1;
  }

  .header__outer {
    position: absolute;
  }
}
