@import '../utils/functions';

.alb-link {
  @include fluid-fonts(1rem, 1-125rem, 190%, 190%);
  padding: 0.625rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > span:first-child {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.25s ease-in;
  }

  &:focus,
  &:hover {
    text-decoration: none;

    > span:first-child {
      border-bottom: 1px solid currentColor;
    }
  }

  &:focus {
    outline: 0.2px solid currentColor;
  }

  &--disabled {
    color: $grey;
    cursor: default;

    &:focus,
    &:hover {
      color: $grey;

      > span:first-child {
        border-bottom: 1px solid transparent;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  .fa-button-caret {
    margin-left: 0.62rem;
  }

  &__menu {
    @include fluid-fonts(28px, 32px, 140%, 140%);
  }
}
