@import '../variables';
@import '../utils/functions';
@import '../base/macros';
@import './selectionItem';

.hotel-selection {
  &__component {
    background-color: $primary-light-blue;
    margin-top: clamp-it(1.5rem, 3.5rem);
  }

  &__date-group {
    @extend %body-small;
    background-color: $primary-light-blue;
    padding: clamp-it(0.3rem, 0.65rem) clamp-it(0.75rem, 1.5rem);
    font-weight: 500;
    margin-bottom: 0;
  }
}
