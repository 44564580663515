@import '../utils/functions';
@import '../base/grid';

.magnolia-form {
  .form-label {
    margin-bottom: clamp-it(0.45rem, 0.55rem);
  }

  &__field-set {
    &:not(:last-child) {
      border-bottom: 1px solid $secondary;
      padding-bottom: clamp-it(2.8rem, 3.6rem);
    }
  }

  &__field {
    position: relative;
    margin-top: clamp-it(2.8rem, 3.6rem);

    &.form-item-s {
      flex: 1;
    }

    &.form-item-m {
      flex: 2;
    }

    &.form-item-l {
      flex: 3;
    }

    &__error {
      color: $red;
      margin-top: 0.4rem;
      line-height: 135%;
    }
  }

  &__selection,
  &__input {
    position: relative;
    display: flex;

    > * {
      width: 100%;
    }

    > label {
      position: absolute;
      transform: translateY(-120%);
    }
  }

  &__input {
    &__subtitle {
      position: absolute;
      bottom: 0;
      transform: translateY(120%);
      font-size: clamp-it(0.75rem, 0.875rem);
      line-height: 120%;
    }
  }

  &__button {
    button.btn.btn-primary {
      display: inline-block;
      width: inherit;
      padding-left: clamp-it(2rem, 4rem);
      padding-right: clamp-it(2rem, 4rem);
      position: relative;

      .spinner-border {
        width: clamp-it(1.2rem, 1.5rem);
        height: clamp-it(1.2rem, 1.5rem);
        position: absolute;
        right: clamp-it(0.4rem, 0.8rem);
        top: 0.8rem;

        .visually-hidden {
          display: none;
        }
      }
    }
  }

  &__form-group {
    > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: clamp-it(1rem, 2rem);

      @include xmedium {
        flex-wrap: nowrap;
        flex-direction: row;
      }

      > .magnolia-form__field {
        flex: 1;
      }
    }
  }

  &__checkbox-group {
    .form-check {
      padding-left: 0;

      .checkbox.radio {
        justify-content: flex-start;

        > .checkbox__field {
          order: 0;
          margin-right: clamp-it(0.6rem, 1.5rem);
        }
      }
    }

    &--horizontal {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -2rem;

      > .form-check {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: clamp-it(1.2rem, 2rem);

        @include medium {
          flex: 1 1 50%;
          max-width: 50%;
        }

        @include xmedium {
          flex: 1 1 calc(100% / 3);
          max-width: calc(100% / 3);
        }

        @include large {
          flex: 1 1 calc(100% / 6);
          max-width: calc(100% / 6);
        }

        input[type='radio'] {
          + .checkbox__field {
            .radio__active {
              display: none;
            }
          }

          &:checked {
            + .checkbox__field {
              .radio__active {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &__select {
    select {
      height: clamp-it(2.5rem, 3.125rem);
      padding: 0 1rem;
      width: 100%;
    }
  }

  &__form-state {
    @include fluid-fonts(1rem, 1.25rem, 120%, 135%);
    margin-top: clamp-it(0.85rem, 1.5rem);
    font-weight: 500;

    > div + div {
      margin-top: clamp-it(0.3rem, 0.5rem);
    }

    &--success {
      color: $material-green;
    }

    &--error {
      color: $material-red;
    }
  }

  &__reopen {
    padding-top: clamp-it(2rem, 3rem);
    padding-bottom: clamp-it(2rem, 3rem);
  }

  .form-check-input {
    margin-left: 0 !important;
  }

  .form-check-label {
    margin-left: 1.25rem;
  }

  input {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  form > .magnolia-form__field-set:first-child {
    height: 0;
    padding: 0;
    visibility: hidden;
    overflow: hidden;
  }
}

.interest-list {
  &__title {
    @include fluid-fonts(1.2rem, 1.5rem, 120%, 130%);
    padding: clamp-it(1rem, 3rem) $section-spacing-x 0;
  }
}
