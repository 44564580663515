@import '../utils/mediaQueries';
@import '../utils/functions';
@import '../base/macros';

$componentSpacingBottom: clamp-it(1rem, 1.5rem);

.sightseeing {
  &__content {
    padding: clamp-it(0.5rem, 0.8rem) 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    max-width: 65%;
    @include medium {
      opacity: 0;
    }

    &--visible {
      opacity: 1;
    }

    &__short-description {
      padding-bottom: $componentSpacingBottom;
      flex: 0 0 100%;
      @include fluid-fonts(0.8rem, 1.2rem, 190%, 190%);
      max-width: 100%;
      overflow-wrap: break-word;
    }

    &__name {
      @extend %albatros-font;
      @include fluid-fonts(1rem, 1.8rem, 140%, 140%);
      flex: 0 0 100%;
      margin-bottom: clamp-it(2rem, 3rem);
    }

    &__wrapper {
      @include medium {
        flex: 0 0 40%;
        box-sizing: border-box;
      }
    }

    &__description {
      position: relative;
    }
  }
}
