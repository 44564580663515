@import '../utils/functions';
@import '../variables';

.continent-page {
  max-width: map-get($grid-breakpoints, xxl);
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include xmedium {
    width: 90vw;
  }

  .intro__container__right {
    padding-left: 0;
  }

  &__intro {
    .intro__container__left {
      @include large {
        width: 55%;
      }
    }

    .intro__container__right {
      @include large {
        width: 45%;
      }
    }
  }

  &__headline-box {
    display: flex;
    justify-content: space-between;
  }

  &__headline {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
    margin-bottom: clamp-it(1rem, 2.5rem);
    font-weight: 500;
    font-family: var(--albatros-font);
  }

  &__button {
    height: fit-content;
  }

  &__contentBox {
    @include large {
      display: flex;
      flex: 0 0 40%;
    }
  }

  &__countrylist {
    flex: 0 0 66%;
    column-count: 2;
    &.grid {
      grid-row-gap: 0;
    }
    @include large {
      column-count: 3;
    }

    &__link {
      @include fluid-fonts(1.125rem, 1.25rem, 175%, 190%);
      font-weight: 500;
    }

    &__image {
      width: 100%;
      height: auto;
    }
  }

  &__container {
    padding-left: clamp-it(0rem, 4.5rem);

    @include medium {
      width: 50%;
    }

    &__link {
      border-bottom: solid 1px;
      padding-bottom: clamp-it(0.125rem, 0.313rem);
    }

    &__link:hover {
      text-decoration: none;
      cursor: pointer;
    }

    &__infoheadline {
      @include fluid-fonts(0.688rem, 0.875rem, 110%, 110%);
      font-weight: 500;
      margin-bottom: clamp-it(1rem, 1.25rem);
    }

    &__infoblock {
      margin-bottom: clamp-it(1.563rem, 2.375rem);
      width: 50%;
    }

    &__infotext {
      @include fluid-fonts(1.5rem, 1.75rem, 140%, 140%);
      font-family: var(--albatros-font);
      margin-right: clamp-it(0.8rem, 0.906rem);

      svg {
        height: 0.9rem;
        width: 1rem;

        path {
          fill: none;
        }
      }
    }
  }
}
