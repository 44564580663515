@import '../utils/mediaQueries';
@import '../utils/functions';
@import '../base/grid';

$titlePadding: clamp-it(2rem, 4rem);
$gridBottomSpacing: clamp-it(9rem, 12rem);

.introduction {
  &__container {
    display: flex;
    flex-direction: column;

    &__inner {
      display: flex;
      flex-direction: column;

      @include medium {
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 0 auto;
        gap: clamp-it(0.2rem, 2rem);
      }
    }
  }

  &__grid {
    &--spacing {
      padding-bottom: calc($section-spacing-y + $gridBottomSpacing) !important;
    }
  }

  &__factsheet {
    padding-bottom: clamp-it(2rem, 5rem);

    @include medium {
      order: 1;
      flex: 1 1 18%;
      box-sizing: border-box;
      max-width: min(37%, 211px);
      padding-bottom: 0;
    }

    @include xlarge {
      flex: 1 1 15%;
    }
  }

  &__description {
    position: relative;

    @include medium {
      order: 2;
      flex: 1 1 60%;
      box-sizing: border-box;
      top: -42px;
    }

    @include xlarge {
      flex: 1 1 10%;
      display: flex;
    }

    &--desktop {
      display: none;

      @include medium {
        display: block;
      }
      @include xlarge {
        padding-right: clamp-it(0.2rem, 2rem);
      }
    }

    &--mobile {
      @include medium {
        display: none;
      }

      &__remaining {
        transition: opacity 0.5s ease-in;
        opacity: 1;

        &--hidden {
          opacity: 0;
        }
      }
    }
  }

  &__title {
    padding-bottom: calc($titlePadding);

    @include medium {
      order: 0;
      flex: 0 1 auto;
      padding-bottom: calc($titlePadding + 30px);
    }
  }

  &__read-more {
    @include fluid-fonts(0.75rem, 0.875rem, 130%, 130%);
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: underline;
    cursor: pointer;

    @include medium {
      display: none;
    }
  }

  &__map {
    position: relative;
    padding-top: clamp-it(1.75rem, 4rem);
    display: flex;
    flex-direction: column;

    @include medium {
      order: 3;
      flex: 1 0 100%;
      text-align: center;
      padding-top: 0;
      display: block;
    }

    @include xlarge {
      max-width: 20%;
    }

    &__container {
      margin: auto;
    }

    &__text {
      text-align: left;
      font-size: clamp-it(0.688rem, 0.813rem);
      line-height: normal;
    }

    img {
      object-fit: contain;
      width: clamp-it(16rem, 22rem);
      cursor: pointer;
      display: flex;
      height: auto;
      margin-bottom: clamp-it(0.375rem, 0.625rem);

      @include xlarge {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-height: 100%;
      }
    }
  }

  &__modal {
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  &__interest-list {
    position: relative;
    top: -42px;
    margin-bottom: clamp-it(1.5rem, 2.5rem);
    display: flex;
    align-items: center;
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    padding: clamp-it(0.5rem, 0.72rem) 0;

    > span {
      &:first-child {
        @include fluid-fonts(1.35rem, 2rem, 100%, 100%);
        font-weight: 700;
        display: inline-block;
        margin-right: clamp-it(0.85rem, 1.5rem);
      }

      &_last-child {
        @include fluid-fonts(1rem, 1.25rem, 100%, 100%);
      }
    }
  }
}
