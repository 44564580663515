@import '../variables';
@import '../utils/mediaQueries';
@import '../utils/functions';

.fact-sheet {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--dm-sans-font), Arial, Helvetica, sans-serif;
  font-size: clamp-it(0.6875rem, 0.875rem);

  @include medium {
    display: block;
  }

  .bullet-points {
    padding: 10px 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    @include medium {
      padding: 65px 20px 20px 20px;
      flex-direction: column;
      align-items: normal;
    }

    .bullet-point {
      display: flex;
      align-items: center;
      padding: 15px;
      flex: 0 0 50%;

      .icon {
        display: flex;

        > svg {
          height: clamp-it(1.25rem, 1.875rem);
          width: 30px;
        }
      }

      > span:first-child {
        margin-right: clamp-it(0.6rem, 1.2rem);
      }

      #price-tooltip {
        cursor: pointer;
        margin-left: clamp-it(0.5rem, 1rem);
      }

      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: 1px solid $black;
        border-top: 1px solid $black;

        @include medium {
          border: none;
        }
      }

      .badge {
        background-color: $secondary-accent-yellow;
        display: flex;
        align-items: center;
        height: 2rem;
        color: $black;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .date-items {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    border: 1px solid $primary-light-blue;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);

    .date-item {
      flex: 1 0 48%;
      white-space: nowrap;
      padding: 0 8px;

      svg {
        @include fluid-fonts(0.5rem, 0.6rem, 100%, 100%);
      }

      a {
        @include fluid-fonts(0.688rem, 0.875rem, 130%, 150%);
        color: $gray-500;
        text-decoration-color: $gray-500;
      }

      &:first-child {
        font-weight: 500;
        font-size: clamp-it(0.875rem, 1rem);
      }

      > * {
        margin-right: 5px;
      }

      @include medium {
        padding: 5px 5px 0 5px;
        display: block;
      }
    }

    @include medium {
      flex-direction: column;
      padding: 0.45rem 0.6rem;
      position: absolute;
      justify-content: center;
      width: 84%;
      left: 8%;
      top: -42px;
      border: 1px solid $primary-light-blue;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    }

    .link {
      font-size: clamp-it(0.6875rem, 0.875rem);
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.custom-tooltip {
  .tooltip.show.bs-tooltip-auto {
    opacity: 1;

    .tooltip-inner {
      font-family: var(--albatros-font);
      border-radius: 0;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
      padding: clamp-it(0.6rem, 1.2rem);
      max-width: 400px;
      text-align: left;
    }
  }
  .theme-white {
    background-color: $primary-light-blue;
  }
  .theme-medium-green {
    background-color: $primary-light-blue;
    color: $primary-dark-green;
  }
  .theme-dark-green {
    background-color: $primary-light-blue;
    color: $primary-dark-green;
  }
}

.theme-blue {
  .introduction__factsheet {
    .date-items {
      background-color: $primary-light-blue;
      color: $primary-dark-green;

      path {
        stroke: $gray-500 !important;
      }
    }
  }
}

.theme-sand {
  .introduction__factsheet {
    .contrast {
      background-color: $secondary-dark-sand;
    }

    .date-items {
      background-color: $secondary-sand;
      color: $primary-dark-green;
      border: none;

      path {
        stroke: $gray-500 !important;
      }
    }
  }
}

.theme-dark-green {
  .introduction__factsheet {
    .contrast {
      background-color: $primary-light-green;
    }

    .date-items {
      background-color: $primary-light-blue;
      border: 1px solid var(--Primary-Light-Blue, $primary-light-blue);
      color: $primary-dark-green;

      path {
        stroke: $gray-500 !important;
      }
    }
  }
}

.theme-medium-green {
  .introduction__factsheet {
    .contrast {
      background-color: $primary-dark-green;
    }

    .date-items {
      background-color: $primary-light-blue;
      color: $primary-dark-green;

      path {
        stroke: $gray-500 !important;
      }
    }
  }
}
