@import '../variables';

.image {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;

  img {
    width: 100%;
    object-fit: cover;
  }

  &__caption {
    display: flex;
    justify-content: space-between;
  }

  :global(.thumbnail) {
    width: 75px;
    height: 75px;
  }
}
