@import '../utils/functions';
@import '../variables';

$boxMeasures: clamp-it(0.875rem, 1.125rem);

.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__field {
    height: calc($boxMeasures + 2px);
    min-height: calc($boxMeasures + 2px);
    width: calc($boxMeasures + 2px);
    min-width: calc($boxMeasures + 2px);
    border: 1px solid $primary;
    position: relative;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      height: $boxMeasures;
      width: $boxMeasures;
      color: $white;
    }
  }

  &__label {
    margin-left: clamp-it(0.3rem, 0.65rem);
    line-height: 120%;
  }

  &--checked {
    .checkbox {
      &__field {
        background-color: $primary;
      }
    }
  }

  &--disabled {
    color: $gray-900;
    cursor: initial;

    input {
      cursor: initial;
    }

    .checkbox {
      &__field {
        border-color: $gray-900;
        background-color: transparent;
      }
    }

    .icon-checkbox-check {
      rect:first-child {
        fill: $gray-900;
      }

      rect:nth-child(2) {
        stroke: $gray-900;
      }
    }
  }

  &-results {
    display: flex;
    align-items: center;

    &__amount {
      @include fluid-fonts(0.58rem, 0.75rem);
      margin-left: clamp-it(0.3rem, 0.65rem);
      height: clamp-it(1.16rem, 1.54rem);
      min-height: clamp-it(1.16rem, 1.54rem);
      width: clamp-it(1.16rem, 1.54rem);
      min-width: clamp-it(1.16rem, 1.54rem);
      background-color: $primary-light-blue;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &--checked {
        background-color: $primary;
        color: $white;
      }

      &--disabled {
        background-color: $gray-900;
        color: $white;
      }
    }
  }

  &.radio {
    width: 100%;
    justify-content: space-between;
    padding-right: clamp-it(1.2rem, 1.8rem);
    padding-left: clamp-it(0.55rem, 0.85rem);

    .checkbox {
      &__field {
        border-radius: 50%;
        order: 2;
      }

      &__label {
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-right: clamp-it(0.3rem, 0.65rem);

        > div {
          &:first-child {
            font-weight: 500;
          }

          &:last-child {
            margin-left: clamp-it(0.55rem, 0.75rem);
          }
        }
      }
    }

    &.checkbox--disabled {
      .radio__active {
        background-color: $gray-900;
      }
    }

    .radio__active {
      height: calc($boxMeasures - 2px);
      width: calc($boxMeasures - 2px);
      border-radius: 50%;
      background-color: $primary;
      position: relative;
      top: 1px;
      left: 1px;
    }

    &.checkbox--checked {
      .checkbox__field {
        background-color: transparent;
      }
    }
  }
}

.radio-results {
  display: flex;
  align-items: center;
  padding: clamp-it(1.3rem, 1.8rem) 0;
  border-bottom: 1px solid $gray-400;
}

label {
  margin-bottom: 0;
}
