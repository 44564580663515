@import '../utils/functions';

input.form-control,
input {
  @include fluid-fonts(16px, 20px, 130%, 120%);

  border-bottom: 2px solid lighten($black, 70%);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  height: initial;
  padding-bottom: 0.94rem;

  &::placeholder {
    @include fluid-fonts(16px, 20px, 130%, 120%);
    color: lighten($black, 70%);
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: $black;
  }

  &:disabled,
  .disabled {
    border-color: $grey !important;
    color: $grey;

    &::placeholder {
      color: $grey;
    }

    &:focus {
      border-color: $grey;
    }
  }

  &.error {
    border-color: lighten($red, 30%) !important;
    color: lighten($red, 30%) !important;

    &:focus {
      border-color: lighten($red, 30%) !important;
    }
  }
}
