@import '../utils/functions';
@import '../variables';

.introMainCountry {
  padding: clamp-it(1.125rem, 5.75rem);
  &__container {
    &__description {
      padding-right: clamp-it(0rem, 4.5rem);
      @include medium {
        width: 50%;
      }
    }
    &__link {
      border-bottom: solid 1px;
      padding-bottom: clamp-it(0.125rem, 0.313rem);
    }
    &__link:hover {
      text-decoration: none;
      cursor: pointer;
    }
    &__inner {
      padding-left: clamp-it(0rem, 4.5rem);
      @include medium {
        width: 50%;
      }
      &__citizens {
        margin-bottom: clamp-it(1.563rem, 2.375rem);
      }
      &__capital {
        margin-bottom: clamp-it(1.563rem, 2.375rem);
      }
      &__language {
        margin-bottom: clamp-it(1.563rem, 3.5rem);
      }
      &__headline {
        @include fluid-fonts(0.688rem, 0.875rem, 110%, 110%);
      }
    }
  }
  &__headline {
    @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
  }
}
