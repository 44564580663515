@import '../variables';
@import '../utils/functions';
@import '../base/macros';

.accordion {
  &__title {
    margin-bottom: clamp-it(1.3rem, 1.8rem);
    @include fluid-fonts(1.5rem, 2rem, 150%, 170%);
    font-weight: 700;
  }
}

.accordion-element {
  position: relative;
  border-top: 1px solid $grey;

  &__button {
    background-color: $white;
    color: initial;
    width: 100%;
    border-radius: 0;
    border: 0;
    text-align: left;
    position: relative;
    text-transform: initial;
    display: flex !important;
    justify-content: space-between !important;
    padding: clamp-it(0.6rem, 1.5rem) 0 !important;
    font-family: var(--dm-sans-font), Arial, Helvetica, sans-serif !important;
    span {
      border: unset !important;
    }
    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important;
      outline: unset !important;
    }
  }

  &__chevron {
    color: currentColor;
    position: absolute;
    top: 50%;
    font-size: 1.2rem;
    right: clamp-it(0.65rem, 1.4rem);
    max-width: 1.05rem;
    transition: transform 0.3s ease-in;
    transform: translateY(-50%) rotate(90deg);

    path {
      stroke: currentColor;
    }

    &--up {
      transform: translateY(-50%) rotate(0deg);
    }
  }

  &__group__header {
    @include fluid-fonts(1.125rem, 1.375rem, 100%, 100%);
    width: 100%;
    font-weight: 500;
    padding-right: 2.2rem;
  }

  &__section {
    padding-bottom: clamp-it(1.5rem, 2.2rem);

    &__container {
      display: block;
    }

    &__header {
      @extend %body-large;
      font-weight: 500;
      margin: 0 0 clamp-it(1.3rem, 1.8rem);
    }
  }

  &__card {
    border: 0;
    padding: 0 !important;

    .rich-text {
      padding: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.page__center--faq {
  .accordion {
    padding-bottom: 0;
  }
}
