@import '../variables';
@import '../utils/functions';
@import '../base/grid';

.extension-teaser {
  &__title {
    @include fluid-fonts(1.375rem, 3rem, 160%, 140%);
    margin-bottom: clamp-it(1.87rem, 5.81rem);
    text-align: center;
  }

  &__container {
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: clamp-it(1.5rem, 2.69rem);
  }

  &__image {
    width: 100%;
    height: clamp-it(12.8125rem, 17.1875rem);
    object-fit: cover;
  }

  &__info-section {
    padding: clamp-it(1.43rem, 2.94rem) clamp-it(2.12rem, 3.125rem) 0;
    width: 80%;

    @include medium {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      padding-bottom: clamp-it(1.43rem, 2.94rem);
    }
  }

  &__icon {
    margin-right: clamp-it(0.31rem, 0.4rem);
  }

  &__duration {
    @include fluid-fonts(0.688rem, 0.875rem, 110%, 110%);
    display: flex;
    margin-bottom: clamp-it(1.313rem, 1.438rem);
    border-bottom: 1px solid $primary;
    width: fit-content;
    padding-bottom: clamp-it(0.375rem, 0.698rem);

    .theme-dark-green &,
    .theme-medium-green & {
      border-bottom: 1px solid $white;
    }
  }

  &__name {
    @include fluid-fonts(1.25rem, 1.75rem, 160%, 140%);
    margin-bottom: clamp-it(0.63rem, 1.25rem);
  }

  &__description {
    @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
  }

  &__price-section {
    padding: clamp-it(1.23rem, 2.5rem) clamp-it(2.12rem, 3.125rem) clamp-it(1.43rem, 2.94rem);
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @include medium {
      padding-top: clamp-it(1.43rem, 2.94rem);
    }
  }

  &__currency {
    @include fluid-fonts(1.125rem, 0.875rem, 140%, 110%);
    margin: auto;
  }

  &__price {
    @include fluid-fonts(1.125rem, 1.828rem, 140%, 170%);
    margin: auto;

    @include medium {
      margin-bottom: clamp-it(0.875rem, 1.69rem);
    }
  }

  &__button {
    .btn.btn-primary {
      padding: 0.625rem clamp-it(0.938rem, 1.875rem);
      margin: auto;
    }
  }
}
