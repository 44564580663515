@import '../variables';

.rating-star {
  stroke: $black;

  &--empty {
    path:first-child {
      fill: transparent;
    }

    path:last-child {
      fill: transparent;
      stroke: none;
    }
  }

  &--half {
    path:first-child {
      fill: transparent;
    }

    path:last-child {
      fill: $black;
      stroke: none;
    }
  }

  &--full {
    path:first-child {
      fill: $black;
    }

    path:last-child {
      fill: $black;
      stroke: none;
    }
  }
}

.theme-dark-green,
.theme-medium-green {
  .rating-star {
    stroke: $white;

    &--empty {
      path:first-child {
        fill: transparent;
      }

      path:last-child {
        fill: transparent;
        stroke: none;
      }
    }

    &--half {
      path:first-child {
        fill: transparent;
      }

      path:last-child {
        fill: $white;
        stroke: none;
      }
    }

    &--full {
      path:first-child {
        fill: $white;
      }

      path:last-child {
        fill: $white;
        stroke: none;
      }
    }
  }
}
