@import '../utils/mediaQueries';
@import '../utils/functions';

.image-slider {
  width: 100%;
  &__image {
    padding-top: 65.25%;
    position: relative;
    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__button {
    background: transparent;
    display: block;
    position: absolute;
    border: none;
    svg {
      height: clamp-it(1.5rem, 3.25rem);
      width: clamp-it(1.5rem, 3.25rem);
      circle {
        fill: $secondary;
        stroke: $secondary;
        transition: fill 0.25s ease-in;
      }
      path {
        fill: none;
        stroke: $black;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $primary;
          stroke: $secondary !important;
        }
        path {
          fill: none;
          stroke: $white !important;
        }
      }
    }
    &--next {
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
    }
    &--previous {
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .carousel-root {
    flex: 0 0 100%;
    padding-bottom: clamp-it(1rem, 1.5rem);
  }

  .carousel.carousel-slider {
    overflow: visible;
  }
}
