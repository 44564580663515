@import '../utils/mediaQueries';
@import '../utils/functions';

.accommodation {
  &__content {
    @media print {
      justify-content: normal;
    }
    &--visible {
      @media print {
        display: none;
      }
    }
    &__name {
      @media print {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 20px;
      }
    }
    &__short-description {
      @media print {
        flex: 0 0 70%;
        padding-right: 11px;
        border-right: 1px solid black;
      }
    }
    &__wrapper {
      @media print {
        flex: 0 0 30%;
        padding-left: 11px;
      }
    }
  }
  &__button {
    @media print {
      display: none;
    }
  }
  &__container {
    @media print {
      display: block !important;
    }
  }
  &__list {
    @media print {
      display: none;
    }

    &__print {
      display: none;
      @media print {
        display: block;
      }
    }
  }
  &__headline {
    > h2 {
      @media print {
        display: none;
      }
    }
  }
}

.accordion-element {
  @media print {
    border-top: 0 !important;
    display: none;
  }
  &__print {
    @media print {
      border-top: 0 !important;
      display: block;
      grid-column: span 12;
      padding-top: 32px;
    }
  }
  &__chevron {
    @media print {
      display: none;
    }

    &--down {
      @media print {
        display: none;
      }
      transform: translateY(-50%) rotate(90deg);
    }
  }
  &__group__header {
    .grid {
      @media print {
        display: block;
      }
    }
  }
  &__body {
    @media print {
      border-top: 0 !important;
      padding-top: 10px;
    }
  }
  &__section {
    @media print {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: clamp(0.5rem, 1.429vw + 0.21rem, 1.5rem);
      grid-row-gap: clamp(1.875rem, 2.679vw + 1.34rem, 3.75rem);
      width: 100%;
      position: relative;
    }
    &__container {
      @media print {
        grid-column: span 6;
      }
    }
    &__print {
      display: none;
      @media print {
        display: block;
        color: $black;
      }
    }
    &__line {
      @media print {
        display: none !important;
      }
    }
    &__break {
      display: none;
      @media print {
        display: block;
      }
    }
  }
}
.checkout {
  &--last {
    .header__outer {
      @media print {
        display: none !important;
      }
    }
  }
}
.hero__scroll-ref,
.content-navigation {
  @media print {
    display: none !important;
    z-index: 0 !important;
    opacity: 0 !important;
  }
}

.excursion {
  &__container {
    &__no-print {
      @media print {
        display: none;
      }
    }
  }
  &__list {
    @media print {
      display: none;
    }

    &__print {
      display: none;
      @media print {
        display: block;
      }
    }

    &__image {
      display: none;
      @media print {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

.introduction__factsheet {
  display: block;
  @media print {
    display: none;
  }
}

.product-reviews__container {
  @media print {
    display: none;
  }
}

.footer {
  @media print {
    display: none;
  }
}

.header {
  @media print {
    display: none;
  }
  &__print {
    display: none;
    @media print {
      display: block;
      width: 100%;
      position: fixed;
      top: 0;
    }
  }
}

.burger-wrapper {
  @media print {
    display: none;
  }
}

.highlight {
  @media print {
    display: none;
  }
}

.image-gallery {
  @media print {
    display: none;
  }
}

#images {
  @media print {
    display: none;
  }
}

.image-slider {
  @media print {
    display: none !important;
  }
}

.product-teasers {
  @media print {
    display: none;
  }
}

#departure-dates {
  @media print {
    display: none;
  }
}

.accommodation__content {
  .image-slider {
    @media print {
      display: block;
    }
    .control-dots {
      @media print {
        display: none !important;
      }
    }
  }
  #Carousel-NavBtn-next {
    @media print {
      display: none !important;
    }
  }
  #Carousel-NavBtn-previous {
    @media print {
      display: none !important;
    }
  }
}

.accommodation__content {
  &__image {
    display: none;
    @media print {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 25px;
    }
  }
}

.product-page {
  .accommodation {
    .accommodation__title {
      @media print {
        display: none;
      }
    }
  }
  .header__outer {
    @media print {
      position: fixed;
      transform: none;
      z-index: 100;
      display: none !important;
    }
  }
}

.travel-information {
  &__wrapper {
    @media print {
      text-align: center;
    }
  }
  &__header {
    @media print {
      text-align: center;
      @include fluid-fonts(2.5rem, 3rem, 125%, 140%);
      font-weight: 500;
      line-height: 140%;
    }
  }
  &__subHeader {
    @media print {
      display: none;
    }
  }
  &__facts--desktop {
    @media print {
      display: flex !important;
      justify-content: space-between !important;
      padding: clamp-it(1rem, 1.5rem) 0 clamp-it(0.6rem, 1.5rem);
      border-bottom: 1px solid grey;
      max-width: 100%;
    }
  }
  &__facts--mobile {
    @media print {
      display: none !important;
    }
  }
  &__fact {
    &--desktop {
      @media print {
        width: 20%;
      }
    }
    &--mobile {
      @media print {
        width: 20%;
      }
    }
  }
}

.price-information {
  .grid {
    @media print {
      display: block;
    }
  }
  &__banner {
    display: none;
    @media print {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: clamp(0.5rem, 1.429vw + 0.21rem, 1.5rem);
      grid-row-gap: clamp(1.875rem, 2.679vw + 1.34rem, 3.75rem);
      width: 100%;
      position: relative;
      border-bottom: 1px solid $grey;
      margin-bottom: 14px;
      padding-bottom: 11px;
    }
    &__headline {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }
  }

  &__container {
    margin-bottom: 24px;
    .grid {
      @media print {
        display: block;
      }
    }
  }

  &__headline {
    @media print {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  &__image {
    display: none;
    @media print {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 23px;
    }
  }

  &__line {
    @media print {
      display: none;
    }
  }
}

.introduction {
  &__description {
    @media print {
      display: flex;
    }
    &--desktop {
      @media print {
        display: block;
        font-size: 18px;
        font-weight: 400;
      }
    }
    &--mobile {
      @media print {
        display: none;
      }
    }
  }
  &__print {
    @media print {
      display: block;
      flex: 0 0 70%;
      border-right: 1px solid;
      padding-right: 20px;
    }

    &__image {
      display: none;
      @media print {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 19px;
      }
    }
  }
  &__title {
    @media print {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  &__map {
    @media print {
      padding-top: 0;
      display: block;
      padding-left: 20px;
      max-width: 30%;
    }
    &__image {
      @media print {
        margin-bottom: 27px;
      }
    }

    &__text {
      @media print {
        display: none;
      }
    }
    &__headline {
      display: none;
      @media print {
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 18px;
      }
    }
  }
  &__highlighttext {
    display: none;
    @media print {
      display: block;
      text-align: start;
      font-size: 18px;
      font-weight: 700;
    }
  }
  &__highlightitem {
    @media print {
      margin-bottom: 15px;
    }
  }
}

.header,
.header-space,
.footer,
.footer-space {
  @media print {
    height: 100px;
  }
}
.header {
  @media print {
    position: fixed;
    top: 0;
  }
}
.footer {
  @media print {
    position: fixed;
    bottom: 0;
  }
}

.extension-teaser {
  &__title {
    &__print {
      display: none;
      @media print {
        display: block;
        text-align: center;
        margin-bottom: clamp-it(1.87rem, 5.81rem);
        @include fluid-fonts(2.5rem, 3rem, 125%, 140%);
      }
    }
  }

  &__print {
    display: none;
    @media print {
      display: block;
    }
  }
  &__image {
    @media print {
      width: 100%;
      height: auto;
    }
  }
  &__info-section {
    @media print {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 25px 0 0 0;
    }
  }
  &__duration {
    @media print {
      display: none;
    }

    &__print {
      display: flex;
    }
  }
  &__description {
    @media print {
      width: 50%;
    }
  }
  &__price-section {
    @media print {
      display: none !important;
    }
  }
  &__price {
    &__print {
      @media print {
        display: flex;
      }
    }
  }
  &__button {
    @media print {
      display: none;
    }
  }
}

.hero {
  @media print {
    height: calc(100dvh - 40mm) !important;
    position: relative;
    top: -20mm !important;
    width: calc(100dvw - 40mm) !important;
    left: 20mm !important;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75) !important;
  }
  &__video {
    @media print {
      display: none;
    }
  }
  &__image {
    @media print {
      height: 100dvh !important;
      opacity: 0 !important;
    }
    &__pdf {
      opacity: 0;
      width: 0 !important;
      height: 0 !important;

      @media print {
        opacity: 1;
        display: block !important;

        position: absolute;
        top: 20mm !important;
        left: 0;
        width: 100% !important;
        height: calc(100dvh - 40mm) !important;
        object-fit: cover;
        margin: 0 !important;
        padding: 0 !important;
        @page {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
  &__line {
    display: none;
    @media print {
      display: block;
      position: absolute;
      color: white;
      width: 20%;
      top: 85%;
      left: 40%;
      border-top: 0.5px solid;
      opacity: 0.5;
    }
  }

  &__infobox {
    display: none;
    @media print {
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 90%;
      color: white !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75) !important;
      padding: 16px;
      background-color: rgba(8, 46, 51, 0.6);
    }

    &__item {
      display: flex;
      margin: 0 25px;
      @media print {
        display: flex;
        margin: 0 25px;
      }
      &__text {
        @media print {
          text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75) !important;
          font-size: 22px;
        }
      }
      &__icon {
        margin: auto;
        padding-right: 15px;
        @media print {
          margin: auto;
          padding-right: 15px;
          text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75) !important;
          font-size: 22px;
        }
      }
    }
  }
  &__logo {
    display: none;

    @media print {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      padding-top: 100px;
      margin-top: 45px;
    }
  }
  &__head {
    @media print {
      color: white !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75) !important;
    }
  }
  &__additionalContent {
    @media print {
      display: none;
    }
  }
  &__tagline {
    @media print {
      display: none;
    }
  }
  &__down {
    @media print {
      display: none;
    }
    display: block;
  }
}

.itinerary {
  &__header {
    font-size: 38px;
    font-weight: 500;
    line-height: 140%;
    @media print {
      color: $black;
    }
  }
  &__wrapper {
    @media print {
      display: flex !important;
      justify-content: center !important;
      margin-bottom: 16px;
    }
    @include medium {
      @media print {
        justify-content: center;
      }
    }
  }
  &__btn {
    @media print {
      display: none !important;
    }
  }
  &__accordion {
    @media print {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: clamp(0.5rem, 1.429vw + 0.21rem, 1.5rem);
      grid-row-gap: clamp(1.875rem, 2.679vw + 1.34rem, 3.75rem);
      width: 100%;
      position: relative;
    }

    &__image {
      display: none;
      @media print {
        display: block;
        margin-bottom: 26px;
        width: 100%;
        height: auto;
      }
    }
    &__header {
      &__text {
        @media print {
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 140% !important;
        }
      }
      &__days {
        @media print {
          background-color: #7ff1c5 !important;
          print-color-adjust: exact;
          -webkit-print-color-adjust: exact;
          padding: 15px;
          width: fit-content;
        }
        &__wrapper {
          @media print {
            display: block;
          }
        }
      }
    }
  }
}
.video {
  @media print {
    height: 100dvh !important;
  }
}

@page {
  size: A4;
  padding: 10mm 0 20mm 0;
  margin: 0;
}

@page :first {
  size: A4;
  padding: 0 !important;
  margin: 0 !important;
}

@media print {
  .introduction__title,
  .itinerary__header,
  .price-information__headline,
  .travel-information__header,
  .excursion__headline,
  .singleHotel,
  .extension-teaser__container {
    page-break-before: always !important;
  }

  .accordion-element__card,
  .card,
  .itinerary__accordion__group,
  .itinerary__accordion__group__text,
  .accommodation__content__more-information {
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    p {
      page-break-before: avoid !important;
      page-break-inside: avoid !important;
    }
  }

  .itinerary__accordion__group__text > p {
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
  }
  .itinerary__accordion__group__text {
    page-break-after: always;
  }
  .itinerary__accordion__header__text {
    page-break-inside: avoid !important;
  }
  .price-information__body--unwrapped > ul {
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
  }

  .accordion-element__section__text > p {
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
  }
  .accommodation__content--print {
    page-break-after: always;
  }
  .introduction__title {
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
  }
  .accordion-element__group__header,
  .itinerary__accordion__header__text,
  .itinerary__accordion__header__days__wrapper,
  .itinerary__accordion__header__days,
  .itinerary__accordion__image,
  .accordion-element__section__header {
    page-break-after: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }
  .contact-bar {
    display: none !important;
  }
}

@media print {
  * {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  body {
    margin: 20mm 0 20mm 0;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  img {
    opacity: 1 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .cookie-information-template-wrapper {
    display: none !important;
  }

  .footer__product-code {
    display: none !important;
  }

  .theme-blue,
  .theme-dark-green,
  .theme-green,
  .theme-medium-green,
  .theme-sand,
  .theme-white,
  .theme-yellow {
    background-color: $white !important;
    color: $primary !important;
  }

  #reviews {
    display: none !important;
  }

  #Coi-Renew {
    display: none !important;
  }
}
