@import '../utils/functions';

.offcanvas {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  background-clip: padding-box;
  outline: 0;
  transform: translateX(0);
  transition: transform 0.3s ease-in;
  width: 100%;

  @include xmedium {
    width: 66%;
  }

  &--hidden {
    display: none;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100dvh;
    background-color: $black;
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
    cursor: pointer;

    &--hidden {
      opacity: 0;
    }
  }

  &__body {
    padding: clamp-it(1.5rem, 3rem) clamp-it(0.75rem, 4rem);
    overflow: auto;

    &__inner {
      position: relative;
    }
  }

  &__close-button {
    @include fluid-fonts(1rem, 1.8rem, 100%, 100%);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}
