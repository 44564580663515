@import '../utils/functions';
@import '../base/macros';
@import '../base/grid';

.header {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 1001;
  align-items: center;
  padding: 1rem $section-spacing-x;

  @include medium {
    padding: 0 0 0 $section-spacing-x;
    justify-content: space-between;
  }

  &__outer {
    position: fixed;
    height: clamp-it(4.5rem, 6rem);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    transition: background 0.3s ease-in;
    width: 100%;
    left: 0;
    z-index: 7;

    .header {
      transition: background-color 0.3s ease-in;
    }

    &--scrollup {
      .header {
        background-color: $white;
      }
    }

    &--scrolldown {
      .header {
        background-color: $white;
      }
    }

    &--scrolltop {
      transform: translateY(0);

      header {
        background-color: transparent;
      }
    }

    &--open {
      overflow-x: initial;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 1s ease-in-out;
    cursor: pointer;

    &--hidden {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  a {
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }
}

.topnav {
  display: flex;
  flex: 1 1;
}

.topnav .spacer {
  margin-left: auto;
  display: none;
  @include xxlarge {
    display: block;
  }
}

.topnav .logo {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  > img {
    width: auto;
    height: clamp-it(1.5rem, 2.3rem);
  }
}

.topnav .topnav-link {
  @include fluid-fonts(0.75rem, 1rem, 180%, 180%);
  @extend %dm-sans-font;
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  transition: opacity 0.8s ease-in;
  opacity: 1;

  &--hidden {
    opacity: 0;
  }

  @include xxlarge {
    display: inline-flex;
  }
}

.topnav-scrolltop a {
  color: white !important;
}

.burger-desktop-sidenav-wrapper {
  margin-top: 3em;
}

.burger-sidenav-wrapper {
  margin-top: clamp-it(1rem, 3rem);
  max-width: 500px;
}

.burgernav {
  flex: 0 1;
  transition: background-color 0.2s;
  background-color: rgba(51, 51, 51, 0);
  display: flex;
  margin-left: auto;
  width: 100%;
  position: relative;
  top: 5px;

  @include medium {
    display: none;
  }

  &__close {
    position: relative;
    right: -10px;
  }
}

.burgernav-scrolldown {
  background-color: $white !important;
}

.burgernav-scrollup {
  background-color: $white !important;
}

.burgernav-scrolltop {
  background-color: transparent !important;
}

.burgernav-desktop {
  position: relative;
  transition: background-color 0.2s;
  background-color: $secondary-accent-green;
  display: none;
  z-index: 2;
  margin-left: auto;
  width: 100%;
  justify-content: space-between;

  @include medium {
    display: flex;
    align-items: center;
    padding-left: clamp-it(1rem, 2rem);
    padding-right: $section-spacing-x;
  }

  &__close {
    position: relative;
  }
}

.burgernav-desktop-content {
  background-color: $secondary-accent-green;
  height: 100dvh;
  min-width: 100%;
  width: calc(100 / 3 * 1vw);
  padding: clamp-it(3.4375rem, 5.125rem) clamp-it(1.5rem, 3rem) 0;
  position: absolute;
  transform: translate(100%, -100%);
  transition: transform 0.75s ease-in;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  > * {
    opacity: 0;
    transition: opacity 0.5s 0.75s ease-in;
  }

  &.open {
    transform: translate(0, 0);

    > * {
      opacity: 1;
    }
  }
}

.burger-wrapper {
  display: none;
  position: relative;
  padding-left: clamp-it(1rem, 2rem);
  background-color: $secondary-accent-green;

  @include medium {
    padding-left: 0;
    display: block;
  }
}

.burger-topnav-link {
  @include fluid-fonts(20px, 20px, 160%, 160%);
  @extend %dm-sans-font;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.burger-topnav-link-wrapper {
  margin-top: clamp-it(1rem, 3rem);
}

.burger-desktop-topnav-link-wrapper {
  margin-top: 2em;
}

.burger-desktop-topnav-link {
  @include fluid-fonts(20px, 28px, 160%, 140%);
  @extend %dm-sans-font;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.burger-desktop-sidenav-link {
  @include fluid-fonts(14px, 16px, 180%, 190%);
  @extend %dm-sans-font;
  margin-bottom: 1.4em;
}

.burger-sidenav-link {
  @include fluid-fonts(14px, 14px, 180%, 180%);
  @extend %dm-sans-font;
  margin-bottom: 1em;
}

.burger-desktop-footer-info {
  @include fluid-fonts(11px, 14px, 110%, 110%);
  @extend %dm-sans-font;
}

.burger-footer-info {
  @include fluid-fonts(11px, 11px, 110%, 110%);
  @extend %dm-sans-font;
}

.burger-footer-content {
  margin-bottom: 1rem;
}

.burger-desktop-footer-content {
  margin-bottom: 1em;
}

.burger-footer {
  margin-top: auto;
}

.burger-desktop-header-link {
  @include fluid-fonts(0.75rem, 1rem, 180%, 190%);
  @extend %dm-sans-font;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: clamp-it(0.75rem, 1.6rem) clamp-it(1rem, 2rem) clamp-it(0.75rem, 1.6rem) 0;

  svg {
    position: relative;
    top: -4px;
    @include fluid-fonts(0.5rem, 0.75rem, 100%, 100%);
  }

  &:first-child {
    svg {
      top: -5px;
    }
  }

  &__container {
    padding: clamp-it(0.75rem, 2.35rem) 0 clamp-it(0.75rem, 1.6rem) 0;
    height: 2.4rem;
  }
}

.burger-header-link {
  @include fluid-fonts(14px, 14px, 180%, 180%);
  @extend %dm-sans-font;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  &:not(:last-of-type) {
    margin-right: clamp-it(0.5rem, 8rem);
  }

  svg {
    position: relative;
    top: -4px;
    @include fluid-fonts(0.5rem, 0.75rem, 100%, 100%);
  }

  &:first-child {
    svg {
      top: -5px;
    }
  }
}

.burgernav-desktop-scrolldown {
  background-color: $secondary-accent-green !important;
}

.burgernav-desktop-scrollup {
  background-color: $secondary-accent-green !important;
}

.burgernav-desktop-scrolltop {
  background-color: $secondary-accent-green !important;
}

.burgernav-desktop .link-text {
  padding-left: clamp-it(0.625rem, 0.875rem);
  color: black;
}

.burger-header-link .link-text {
  padding-left: 15px;
  color: black;
}

a.menu {
  white-space: nowrap;
}

.menu-icon {
  justify-content: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: clamp-it(2.5rem, 3.125rem);

  svg {
    @include fluid-fonts(1.5rem, 2.2rem, 100%, 100%);
  }

  &__top {
    svg {
      rect,
      path {
        fill: $white;
      }

      path {
        stroke: $white;
      }
    }
  }

  &__magnify svg {
    @include fluid-fonts(1.2rem, 1.5rem, 100%, 100%);
  }
}
