@import '../utils/mediaQueries';
@import '../utils/functions';

.month-selector {
  position: relative;
  overflow: hidden;
  width: 100%;

  @include xmedium {
    display: flex;
  }

  &__inner {
    position: relative;
    display: flex;
    width: 200%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    flex-wrap: nowrap;

    @include xmedium {
      width: 100%;
    }

    &--transformed {
      transform: translateX(-50%);

      @include xmedium {
        transform: translateX(0);
      }
    }

    > div {
      width: 50%;

      @include xmedium {
        width: 100%;
      }
    }
  }

  &__years {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: clamp-it(2rem, 3rem);

    &--desktop {
      display: flex;
      justify-content: center;

      .month-selector__year {
        @include medium {
          @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
          width: initial;
          text-align: initial;
        }

        &:hover,
        &--selected {
          background-color: $primary-light-blue;
        }
      }
    }
  }

  &__selected-year {
    &--mobile {
      @include fluid-fonts(0.9rem, 1.1rem, 100%, 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      cursor: pointer;

      @include xmedium {
        display: none;
      }

      svg {
        @include fluid-fonts(0.5rem, 0.7rem, 100%, 100%);
        margin-right: clamp-it(0.4rem, 0.65rem);
        transform: rotate(180deg);
      }
    }

    &--desktop {
      display: none;

      @include medium {
        display: block;
      }

      .month-selector__year {
        margin: 0 clamp-it(0.32rem, 0.6rem);
      }
    }
  }

  &__year {
    @include fluid-fonts(1.5rem, 2rem, 180%, 190%);
    padding: clamp-it(0.42rem, 0.62rem);
    transition: background-color 0.3s ease-in;
    cursor: pointer;
    width: 100%;
    text-align: center;

    @include xmedium {
      @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
      width: initial;
      text-align: initial;
    }

    &:hover,
    &--selected {
      background-color: $primary-light-blue;
    }
  }

  &__months {
    padding: clamp-it(0.55rem, 0.9rem) clamp-it(0.55rem, 0.9rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .search__content__submit {
      padding: 0;
    }

    &--desktop {
      margin-top: clamp-it(1.5rem, 2.5rem);
      column-count: 3;

      @include large {
        column-count: 4;
      }

      .checkbox-results {
        position: relative;
      }
    }

    &--mobile {
      margin-top: clamp-it(1rem, 2rem);
      margin-bottom: clamp-it(0.5rem, 1rem);
    }
  }

  &--mobile {
    @include xmedium {
      display: block;
    }

    .month-selector {
      &__inner {
        @include xmedium {
          width: 200%;
        }

        &--transformed {
          @include xmedium {
            transform: translateX(-50%);
          }
        }

        > div {
          @include xmedium {
            width: 50%;
          }
        }
      }

      &__selected-year {
        &--mobile {
          @include xmedium {
            display: flex;
          }
        }

        &--desktop {
          @include xmedium {
            display: none;
          }
        }
      }

      &__year {
        @include xmedium {
          @include fluid-fonts(1.5rem, 2rem, 180%, 190%);
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
