@import '../utils/functions';
@import '../base/macros';

.seat-selection {
  padding: clamp-it(1rem, 2rem) 1rem clamp-it(1rem, 2.5rem);
  background-color: $white;

  .bus-teasers &,
  .opera-teasers & {
    margin-top: clamp-it(1.5rem, 2rem);
  }

  &--error {
    .seat-selection {
      &__travelers__item,
      &__headline {
        color: $red !important;
      }
    }
  }

  &__headline {
    text-align: center;
  }

  &__image {
    margin-top: clamp-it(2rem, 4rem);
    text-align: center;

    img {
      height: auto;
      width: 100%;

      @include medium {
        width: 85%;
      }

      @include large {
        width: 75%;
      }

      @include xlarge {
        width: 70%;
      }
    }

    &--padded {
      padding: 0 clamp-it(0.5rem, 6rem);
      margin-top: clamp-it(1.3rem, 1.85rem);
    }

    &__inner {
      position: relative;

      img {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        @include medium {
          width: 100%;
        }

        @include large {
          width: 100%;
        }

        @include xlarge {
          width: 100%;
        }
      }
    }
  }

  &__travelers {
    margin-top: clamp-it(1.5rem, 2.2rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    &__item {
      @include fluid-fonts(1.125rem, 1.375rem, 120%, 130%);
      text-decoration: underline;
      cursor: pointer;
      transition:
        font-weight 0.2s ease-in,
        color 0.2s ease-in;
      display: inline-block;

      &:not(:first-child) {
        margin-top: clamp-it(0.6rem, 1.1rem);
      }

      &:hover {
        font-weight: 700;
      }

      &--active {
        color: $material-green;
        font-weight: 700;
      }

      > span {
        @include fluid-fonts(0.75rem, 0.875rem, 100%, 100%);
      }
    }
  }

  &__mobile-dropdown {
    margin: clamp-it(2rem, 3rem) 0;

    .select-field {
      &__input {
        width: clamp-it(16rem, 21.875rem);
      }

      &__options {
        max-height: 12rem;
      }

      &__option > div {
        padding: clamp-it(0.4rem, 0.65rem) 0;
      }
    }
  }

  &__legend {
    margin-top: clamp-it(2rem, 3rem);
    display: flex;
    flex-wrap: wrap;
    gap: clamp-it(0.8rem, 1.5rem) clamp-it(1.5rem, 2.2rem);

    &__item {
      display: flex;
      align-items: center;
    }

    &__color {
      height: clamp-it(0.5rem, 1.4rem);
      width: clamp-it(1rem, 2rem);
      margin-right: clamp-it(0.5rem, 0.75rem);

      + div {
        @include fluid-fonts(0.75rem, 0.875rem, 100%, 100%);
        font-weight: 600;
      }
    }
  }

  &__map {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    //background-color: lighten($gray-100, 2%);

    &__seat {
      position: absolute;
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      cursor: pointer;

      > div {
        @include fluid-fonts(0.5rem, 1rem, 100%, 100%);
        display: inline-block;
      }

      &--unavailable {
        background-color: $red;
        cursor: default;
      }

      &--selected {
        background-color: $material-light-green !important;
        box-shadow: inset 0 0 0 clamp-it(0.063rem, 0.188rem) $material-green !important;
        cursor: default;
        color: $primary;
      }

      &--cat0 {
        background-color: $material-blue;
      }

      &--cat1 {
        background-color: $material-purple;
      }

      &--cat2 {
        background-color: $orange;
      }

      &--cat3 {
        background-color: $material-light-blue;
      }

      &--cat4 {
        background-color: $material-lime;
      }

      &--cat5 {
        background-color: $material-turquoise;
      }

      &--disabled {
        cursor: default !important;
      }
    }
  }

  &__selections {
    margin-top: clamp-it(0.75rem, 1.4rem);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include large {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__upper {
    padding: 0 clamp-it(0rem, 3rem);
  }

  &__selection {
    @extend %body-small;
    line-height: 1;
    display: flex;
    border: 1px solid $gray-300;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    margin: clamp-it(0.45rem, 0.625rem) clamp-it(0.75rem, 1rem);
    padding: clamp-it(0.6rem, 1.05rem) clamp-it(0.65rem, 1rem);

    > span:first-child {
      margin-right: clamp-it(0.5rem, 1rem);

      > span {
        &:first-child {
          margin-right: clamp-it(0.35rem, 0.55rem);
        }

        &:nth-child(2) {
          color: $red;
        }
      }
    }

    > .checkbox {
      pointer-events: none;
    }
  }

  .bus-teasers & {
    .seat-selection {
      &__selections {
        justify-content: flex-start;
      }

      &__selection {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
