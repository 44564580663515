@import '../utils/functions';
@import '../utils/mediaQueries';
@import '../variables';

.content-module {
  h3 {
    @include fluid-fonts(36px, 48px, 140%, 140%);
  }

  .content-row__container {
    margin-bottom: clamp-it(1.812rem, 3.75rem);
  }

  .content-row {
    &:not(:first-child) {
      padding-top: clamp-it(0.312rem, 1.25rem);
    }

    &:not(:last-child) {
      padding-bottom: clamp-it(0.312rem, 1.25rem);
      border-bottom: 1px solid $gray-200;
    }

    &__image {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin: auto;
      @include medium {
        max-width: 50%;
      }

      img {
        max-width: 100%;
        height: auto;
        width: auto;
        object-fit: contain;
        flex: 1;
        padding-bottom: clamp-it(0.375rem, 0.625rem);
      }

      &--left {
        @include medium {
          float: left;
          margin-right: clamp-it(1rem, 1.5rem);
        }
      }

      &--right {
        @include medium {
          float: right;
          margin-left: clamp-it(1rem, 1.5rem);
        }
      }
      .image__caption {
        display: inline-block;
        color: inherit;
        font-size: clamp-it(0.688rem, 0.813rem);
        line-height: normal;
        padding-bottom: 0;
      }
    }

    &__text {
      h4 {
        margin-bottom: 1.88rem;
      }

      p:last-of-type {
        margin-bottom: 0;
      }

      ul {
        list-style: outside disc !important;
        margin-left: 1rem !important;
        padding-left: 0 !important;

        li {
          position: relative;
          left: 1em;
        }
      }
    }

    &__cta {
      margin-top: clamp-it(1.5rem, 2.5rem);
    }
  }

  .content-row::after {
    content: '';
    clear: both;
    display: table;
  }
}
