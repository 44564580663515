@import '../utils/functions';
@import '../variables';
@import '../base/macros';

.country-map {
  padding: clamp-it(1.125rem, 5.75rem);

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    @extend %albatros-font;
    @include fluid-fonts(3rem, 3.5rem, 140%, 140%);
  }

  &__description {
    @include fluid-fonts(0.8rem, 1rem, 190%, 190%);
  }

  &__map-wrapper {
    margin-top: 4rem;
  }
}
