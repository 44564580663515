.circle-caret-down {
  position: relative;

  > svg:first-child {
    position: absolute;
    left: -6px;
    top: -6px;
  }

  &__text-path {
    font-size: 0.8rem;
    font-weight: 700;
  }
}
