@import '../../variables';

.theme-white {
  @extend .theme-blue;
  background-color: $white;

  .btn.btn-primary {
    background-color: $secondary-accent-green;
    border: 1px solid $secondary-accent-green;
    color: $primary-light-green;

    .svg-arrow-down path {
      stroke: $primary;
    }
  }

  .btn.btn-secondary {
    color: $primary-light-green;

    .svg-button-caret path {
      stroke: $primary-light-green;
    }

    &:focus {
      outline: 0.2px solid $primary-light-green;

      > span:first-child {
        border-bottom: 1px solid $primary-light-green;
      }
    }

    &:hover {
      > span:first-child {
        border-bottom: 1px solid $primary-light-green;
      }
    }
  }

  .accordion-element {
    border-top-color: $primary;
  }

  .image-slider__button,
  .navBtn {
    svg {
      circle {
        fill: $primary;
        stroke: $primary;
        transition: fill 0.25s ease-in;
      }
      path {
        stroke: $white;
        fill: transparent;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $white;
          stroke: $primary !important;
        }
        path {
          stroke: $primary !important;
          fill: none;
        }
      }
    }
  }

  .contrast {
    background-color: $primary-light-blue-contrast;
  }

  .outline {
    outline: 1px solid $primary-light-blue-contrast;
  }

  .outline-double {
    outline: 2px solid $primary-light-blue-contrast;
  }

  &.contrast-border,
  .contrast-border {
    border: 1px solid $white;
  }

  .separator {
    background-color: $primary;
  }

  .non-contrast {
    background-color: $white;
  }

  .card {
    background-color: $white;
  }

  .drinking-water {
    fill: $white;
  }

  .drinking-water {
    stroke: $white;
  }
}
