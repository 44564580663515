@import '../utils/functions';

.mobile-overlay {
  display: flex;
  flex-direction: column;
  transform: translate(100%, -100%);
  transition: transform 0.75s ease-in;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  background-color: $secondary-accent-green;
  overflow-x: hidden;
  padding: clamp-it(1rem, 8rem);
  text-shadow: none;

  > * {
    opacity: 0;
    transition: opacity 0.5s 0.75s ease-in;
  }

  &--open {
    transform: translate(0, 0);

    > * {
      opacity: 1;
    }

    @include medium {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    z-index: 20;

    &__inner {
      white-space: nowrap;
    }
  }

  &__menu-icon {
    background-color: $secondary-accent-green;
    top: 5px;
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    width: clamp-it(2.2rem, 3.5rem);
    height: clamp-it(2.2rem, 3.5rem);

    svg {
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
    }
  }
}
