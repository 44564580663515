@import '../utils/mediaQueries';
@import '../utils/functions';

.sightseeing {
  margin: auto;
  @extend .max-width;
  &__container {
    @include medium {
      display: flex;
    }
  }
  &__list {
    flex: 1 0 25%;
    text-align: left;

    &__sticky {
      top: 3rem;
      position: sticky;
    }
  }
  &__content {
    flex: 1 0 65%;
    transition: height 2s ease-in-out;
  }

  &__headline {
    > h2 {
      flex: 1 0 25%;
      display: flex;
    }

    > div {
      flex: 1 0 65%;
    }
    display: flex;
    margin-bottom: clamp-it(2rem, 2.5rem);
  }

  &__name {
    padding: 0.8rem 0.4rem;
    margin-bottom: clamp-it(0.5rem, 0.8rem);
    display: flex;
    opacity: 50%;
    cursor: pointer;
    @include fluid-fonts(1rem, 1.8rem, 140%, 140%);

    &.active {
      opacity: 100%;
    }
  }
}
