@import '../utils/functions';
@import '../base/macros';

.extension {
  background-color: $white;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;

  &__outer {
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
  }

  &__content {
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;

    @include xmedium {
      flex-direction: row;
      justify-content: space-between;
    }

    &__left {
      padding: clamp-it(1.4rem, 2.25rem);

      @include xmedium {
        flex: 1 1 75%;
      }
    }

    &__right {
      text-align: center;
      padding: 0 clamp-it(1.4rem, 2.25rem) clamp-it(1.4rem, 2.25rem);
      border-left: 1px solid $gray-300;
      display: flex;
      align-items: center;

      @include xmedium {
        padding: clamp-it(1.4rem, 2.25rem);
        flex: 1 1 25%;
        justify-content: center;
      }

      &__inner {
        display: flex;
        flex: 1;
        justify-content: space-between;

        @include xmedium {
          flex-direction: column;
          flex: initial;
          justify-content: initial;
        }
      }
    }

    &__duration {
      margin-bottom: clamp-it(0.9rem, 1.22rem);
      display: inline-block;
      border-bottom: 1px solid $gray-400;

      > svg {
        margin-right: clamp-it(0.35rem, 0.5rem);
      }
    }

    &__name {
      @include fluid-fonts(1.25rem, 1.75rem, 120%, 130%);
      margin-bottom: clamp-it(1.1rem, 1.45rem);
    }

    &__price {
      @include fluid-fonts(1.35rem, 1.813rem, 120%, 138%);

      @include xmedium {
        margin-bottom: clamp-it(0.85rem, 1.2rem);
      }

      &__container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        @include xmedium {
          flex-direction: column;
          align-items: initial;
        }
      }

      &__label {
        @extend %body-small;
        font-weight: 500;
        position: relative;
        top: -5px;
        margin-right: clamp-it(0.35rem, 0.55rem);

        @include xmedium {
          top: initial;
          margin-right: initial;
        }
      }
    }
  }

  &:first-child {
    margin-top: clamp-it(1rem, 1.4rem);
  }

  &__image {
    width: 100%;
    height: auto;
    max-height: clamp-it(13rem, 17rem);
    object-fit: cover;
  }
}

.related-service {
  &__container {
    background-color: $white;
    padding: clamp-it(1rem, 2.25rem);

    .single-excursions,
    .hotel-selection__component {
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    }
  }

  &__headline {
    @include fluid-fonts(1.375rem, 1.75rem, 120%, 135%);
    margin-bottom: clamp-it(1rem, 2.5rem);
  }
}
