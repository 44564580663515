@import '../variables';
@import '../utils/functions';
@import '../base/macros';
@import '../utils/mediaQueries';

.climate-table {
  overflow: hidden;
  border-collapse: collapse;
  background-color: $white;
  width: 100%;

  &__container {
    @include set-scrollbar($gray-400, $primary-light-green);
    background-color: $white;
    padding: clamp-it(0.5rem, 1rem) 0 clamp-it(0.5rem, 1rem) clamp-it(1rem, 1.5rem);

    &:not(:first-child) {
      margin-top: clamp-it(2rem, 5rem);
    }

    @include medium {
      padding-right: clamp-it(1rem, 1.5rem);
      overflow: auto;
    }
  }

  &__wrapper {
    display: flex;
    font-size: 0.6875rem;
  }

  &__month-wrapper {
    height: 1.4rem;
  }

  &__fixed-wrapper {
    padding: 0.6rem 0.8rem;
    border-right: 1px solid $gray-500;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &__swipe-container {
    @extend %no-scrollbar;
    overflow: auto;
  }

  &__swipe-item {
    padding: 0 0.5rem;

    &__container {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__swipe-wrapper {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    & > div {
      padding: 0.5rem 0;
    }
  }

  &--desktop {
    display: none;
    @include medium {
      display: table;
    }
  }

  &--mobile {
    @include medium {
      display: none;
    }

    .fa-thermometer {
      height: 1.3rem !important;
    }

    .fa-droplet {
      height: 1.1rem !important;
    }
  }

  &__month-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;

    &__arrow-left-wrapper {
      margin: 0 1rem -0.2rem -1.2rem;
      .fa-arrow-left {
        width: 0.3rem;
      }
    }

    &__arrow-right-wrapper {
      margin: 0 -1.2rem -0.2rem 1rem;
      .fa-arrow-right {
        width: 0.3rem;
      }
    }

    &__bottom-spacer {
      padding-bottom: 1rem;
    }
  }

  &__header {
    &--mobile {
      font-size: clamp-it(1.3rem, 1.8rem);
      font-weight: 500;
      line-height: 140%;
      padding: clamp-it(0.75rem, 0.95rem);
      border-bottom: 1px solid $gray-500;
    }

    &--desktop {
      font-size: clamp-it(1.15rem, 1.5rem);
      font-weight: 500;
      line-height: 140%;

      @include medium {
        min-width: 8rem;
      }

      &__icon {
        font-size: clamp-it(1.3rem, 1.8rem);
        margin-bottom: 0.2rem;
      }
    }
  }

  &__row-header {
    @include fluid-fonts(0.6rem, 1rem, 110%, 110%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:first-child {
      margin-bottom: 1.6rem;
    }

    &__text {
      font-size: 0.6875rem;
      margin-top: 0.1rem;
    }
  }

  &__header-col {
    font-size: 0.6875rem;
    line-height: 130%;
    text-transform: uppercase;
  }

  th {
    text-align: center;
    border: none; /* Remove default borders */
    padding: 1rem;
  }

  td {
    text-align: center;
    border: none; /* Remove default borders */
    font-size: 0.6875rem;
    line-height: 130%;

    &:last-child {
      padding-right: 0.4rem;
    }
  }

  /* Borders for the first row */
  tr:first-child th {
    border-bottom: 1px solid $gray-500;
    font-weight: 400;
  }

  /* Borders for the first column */
  td:first-child {
    border-right: 1px solid $gray-500;
    padding: 0.5rem 0.25rem 0.5rem 0.5rem;

    @include medium {
      padding: 1rem 0.5rem 1rem 1rem;
      min-width: 8rem;
    }
  }

  .navBtn.navBtn--slide {
    transform: translate(0);
    top: 0.35rem;

    &.navBtn--prev {
      left: 0.5rem;
    }

    &.navBtn--next {
      right: 0.5rem;
    }
  }
}

.precipitation-element {
  display: flex;
  padding: clamp-it(0.1rem, 0.3rem) clamp-it(0.5rem, 0.6rem);
  margin-left: 0.2rem;
  border-radius: 3rem;

  @include medium {
    padding: clamp-it(0.3rem, 0.5rem) clamp-it(1rem, 1.3rem) !important;
    margin-left: 1rem !important;
    border-radius: 6rem !important;
  }
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  &.dry {
    background-color: $precipitation-dry;
  }
  &.low {
    background-color: $precipitation-low;
  }
  &.mid {
    background-color: $precipitation-mid;
  }
  &.high {
    background-color: $precipitation-high;
  }
}

.temperature-element {
  display: flex;
  padding: clamp-it(0.1rem, 0.2rem) clamp-it(0.5rem, 0.8rem);
  margin-bottom: 2rem;
  margin-left: 0.2rem;
  border-radius: 6rem;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  @include medium {
    padding: clamp-it(0.3rem, 0.5rem) clamp-it(0.6rem, 1rem) !important;
    margin-left: clamp-it(0.5rem, 0.8rem) !important;
    margin-bottom: 0 !important;
  }

  &.cold {
    background-color: $temperature-cold;
  }
  &.low {
    background-color: $temperature-low;
  }
  &.mid {
    background-color: $temperature-mid;
  }
  &.high {
    background-color: $temperature-high;
  }
}
