@import '../../variables';
@import '.././macros';

.theme-yellow {
  @extend .theme-green;
  background-color: $secondary-accent-yellow;
  color: $primary;

  .contrast {
    background-color: $secondary-accent-yellow-contrast;
  }

  .outline {
    outline: 1px solid $secondary-accent-yellow-contrast;
  }

  .outline-double {
    outline: 2px solid $secondary-accent-yellow-contrast;
  }

  &.contrast-border,
  .contrast-border {
    border: 1px solid $secondary-accent-yellow;
  }

  .separator {
    background-color: $primary;
  }

  .accordion-element {
    border-top-color: $primary;
  }

  .card {
    background-color: $secondary-accent-yellow;
  }

  .drinking-water {
    fill: $secondary-accent-yellow;
  }

  .drinking-water {
    stroke: $secondary-accent-yellow;
  }

  .image-slider__button,
  .navBtn {
    svg {
      circle {
        fill: $primary;
        stroke: $primary;
        transition: fill 0.25s ease-in;
      }
      path {
        stroke: $white;
        fill: transparent;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $secondary-accent-yellow;
          stroke: $primary !important;
        }
        path {
          stroke: $primary !important;
          fill: none;
        }
      }
    }
  }

  .navigation {
    &__cta {
      background: $primary-dark-green;
      color: $secondary-accent-green;
      &:hover {
        text-decoration-color: $white;
      }
      span {
        color: $white;
        svg {
          path {
            fill: $white;
            stroke: $white;
          }
        }
      }
    }
  }
}
