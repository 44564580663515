@import '../variables';
@import '../utils/functions';
@import '../base/grid';

.highlight {
  &.grid-section {
    padding: 0;

    @include large {
      padding: $section-spacing-y $section-spacing-x;
    }
  }

  &__title {
    text-align: center;
    margin: clamp-it(2.25rem, 4rem) $section-spacing-x clamp-it(1.25rem, 4.75rem);

    @include large {
      margin-top: 0;
    }
  }

  &__container {
    margin-left: 9.1%;

    &__inner {
      display: flex;
      position: relative;
      padding: clamp-it(1rem, 5.6rem) 0;
    }

    &--mobile {
      display: grid;
      justify-items: center;
    }
  }

  &__text {
    font-weight: 600;
    padding: clamp-it(0.875rem, 2.5rem) $section-spacing-x clamp-it(3rem, 4rem);
    @include fluid-fonts(1.125rem, 1.5rem, 110%, 110%);
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    transition:
      opacity 0.4s ease-in-out,
      height 0.4s ease-in-out;
    opacity: 0;

    &__container {
      display: flex;
      position: relative;
      margin-left: -10%;
      flex: 1 1 52%;
      min-height: 18.75rem;
    }

    &--visible {
      opacity: 1;
    }

    &--exiting {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &--mobile {
      width: 100%;
      height: auto;
      margin-bottom: clamp-it(
        1rem,
        2rem,
        map-get($grid-breakpoints, sm),
        map-get($grid-breakpoints, lg)
      );
    }
  }

  &__link {
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.3s ease;
    font-size: clamp-it(1rem, 1.125rem);

    &:not(:last-child) {
      margin-bottom: clamp-it(1.5rem, 2rem);
    }

    > a {
      display: inline-block;
      padding: 0 10px;

      &:hover {
        box-shadow: none;
      }
    }

    &--active {
      box-shadow: -2px 0 0 0 currentColor;
      font-weight: 500;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
    }

    &__container {
      flex: 1 1 48%;
      padding: clamp-it(
          2.5rem,
          4.5rem,
          map-get($grid-breakpoints, lg),
          map-get($grid-breakpoints, xxl)
        )
        clamp-it(1.5rem, 3rem, map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxl));
    }
  }
}
