@import '../variables';

.hover-icon {
  &__container {
    position: relative;
  }

  &__button {
    position: absolute;
    border-radius: 50%;
    padding: 0;
    border: 0;
    opacity: 1;
    background-color: $secondary-sand;
    transition:
      opacity 0.3s ease-in,
      background-color 0.3s ease-in;

    &:hover {
      background-color: $secondary-accent-green;
    }

    &--hidden {
      opacity: 0;
    }

    svg {
      height: clamp-it(1.5rem, 3.25rem);
      width: clamp-it(1.5rem, 3.25rem);

      circle {
        stroke: $primary-light-green;
        fill: none;
      }

      path {
        stroke: $primary-light-green;
        fill: none;
      }
    }
  }
}
