@import '../variables.scss';
@import '../utils/functions';

.footer {
  display: flex;
  flex-wrap: wrap;
  min-height: 100dvh;
  position: relative;
  z-index: 6;
  align-items: center;

  > div {
    &:nth-child(odd):not(.footer__contact) {
      @include large {
        padding-right: clamp-it(1rem, 4rem);
      }
    }

    &:nth-child(2) {
      @include large {
        margin-bottom: clamp-it(2rem, 3rem);
      }
    }
  }

  &__wrapper.grid-section {
    padding-bottom: clamp-it(3.8rem, 4.2rem);
  }

  &__product-code {
    margin-top: clamp-it(0.8rem, 1rem);
    text-align: right;
    white-space: nowrap;
    color: lighten($primary, 4%);
  }

  &__container {
    &__small,
    &__large {
      @include large {
        margin-bottom: 0;
      }
    }

    &__small {
      flex: 1 1 100%;
      margin-bottom: clamp-it(2.5rem, 6rem);

      @include large {
        flex: 1 1 50%;
      }

      @include xlarge {
        flex: 1 1 40%;
      }

      @include xxlarge {
        flex: 1 1 35%;
      }
    }

    &__large {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;
      align-items: flex-start;

      @include large {
        flex: 1 1 50%;
      }

      @include xlarge {
        flex: 1 1 60%;
      }

      @include xxlarge {
        flex: 1 1 65%;
      }

      > div {
        flex: 1 1 100%;

        @include medium {
          flex: 1 1 50%;
        }

        @include xlarge {
          &:first-child {
            flex: 1 1 57%;
          }

          &:last-child {
            flex: 1 1 43%;
          }
        }
      }

      h4 {
        margin-bottom: clamp-it(1rem, 2rem);
      }
    }
  }

  &__links {
    margin-bottom: clamp-it(2.5rem, 6rem);

    @include large {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: clamp-it(2rem, 3.5rem);
      }
    }

    ul {
      display: table;
      list-style-type: none;
      padding-left: 0;
      padding-right: clamp-it(1rem, 1.3rem);
    }

    li {
      margin-bottom: clamp-it(0.5rem, 0.8rem);
    }
  }

  &__link {
    display: block;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    order: 2;
    margin-top: clamp-it(3.5rem, 4rem);
    margin-bottom: 0;

    @include large {
      order: initial;
      margin-top: 0;
      align-items: flex-end;
    }

    > div {
      &:first-child {
        padding-right: clamp-it(1rem, 1.5rem);
      }
    }

    p {
      @include fluid-fonts(0.668rem, 1rem, 200%, 200%);

      margin-bottom: 0;
    }
  }

  &__logo {
    width: auto;
    height: clamp-it(3.15rem, 5.0625rem);
    margin-bottom: clamp-it(2rem, 5rem);
  }

  input.form-control,
  &__input {
    background-color: $primary-light-green;
    border-bottom: none;
    padding: 10px;
    height: clamp-it(2.6525rem, 3.0625rem);
    margin-right: clamp-it(0.44rem, 0.56rem);
    color: $white;

    &::placeholder {
      color: $white;
    }

    @include large {
      max-width: 80%;
      width: 100%;
    }

    input.form-control,
    input .footerInput::placeholder {
      @include fluid-fonts(14px, 16px, 110%, 110%);
    }
  }

  .btn.btn-secondary,
  &__button {
    background: transparent;
    box-shadow: inset 0 0 1px 1px $secondary-accent-green !important;
    height: clamp-it(2.6525rem, 3.0625rem);
    width: clamp-it(2.6525rem, 3.0625rem);
    color: $secondary-accent-green;
    transition: opacity 0.25s ease-in;
    position: relative;

    .spinner-border {
      width: clamp-it(1.2rem, 1.5rem);
      height: clamp-it(1.2rem, 1.5rem);
      position: absolute;
      right: clamp-it(0.4rem, 0.8rem);
      top: 0.75rem;

      .visually-hidden {
        display: none;
      }
    }

    .svg-button-caret {
      margin-left: 0;
    }

    .svg-button-caret path {
      stroke: $secondary-accent-green;
    }

    svg {
      font-size: clamp-it(1.2rem, 1.4rem);

      > path {
        fill: none;
        stroke: $green;
      }
    }

    &:disabled {
      opacity: 0.35;
    }
  }

  .newsletter {
    padding-top: 2.4rem;
    position: relative;

    &__error {
      font-weight: 500;
      color: $red;
      position: absolute;
      top: 0;
      line-height: 130%;
    }

    &__success {
      font-weight: 500;
      color: $material-green;
      position: absolute;
      top: 0;
      line-height: 130%;
    }
  }
}
