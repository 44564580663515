@import '../variables';
@import './headlines';
@import './buttons';
@import './grid';
@import './links';
@import './input';
@import './svgs';
@import './themes/themes';

html {
  font-size: $base-font-size;
  color: $black;
}

body {
  @extend %body;
  overflow-x: hidden;
}

.col-xxl-0 {
  flex: 0 0 0 !important;
  max-width: 0 !important;

  @include xxlarge {
    display: none !important;
  }
}

[class^='static_richText'] {
  a {
    text-decoration: underline;
  }
}

.no-opacity {
  opacity: 0;
}

.page-loading-progress-bar {
  position: fixed;
  top: 0;
  height: clamp-it(0.32rem, 0.5rem);
  box-shadow: 0 1px 9px -1px rgba(0, 0, 0, 0.75);
  width: 100dvw;
  background-color: $material-light-blue;
  z-index: 99999999;
}

.next-image {
  opacity: 0;
  transition: opacity 0.3s ease-in;

  &--visible {
    opacity: 1;
  }
}

.visually-hidden {
  display: none;
}
