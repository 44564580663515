@import '../utils/functions';
@import '../base/macros';

.participants-forms {
  &__form {
    &:not(:first-child) {
      margin-top: clamp-it(2.5rem, 6rem);
    }

    input {
      @extend %ellipsis;
      background-color: $secondary-sand;

      &:focus,
      &:active {
        background-color: $secondary-sand;
      }

      &::placeholder {
        @extend %ellipsis;
        color: $gray-500;
      }
    }

    textarea {
      resize: none;
    }

    + form:not(.participants-forms__form) {
      margin-top: clamp-it(1.5rem, 2.5rem);
    }
  }

  &__copy.btn.btn-primary {
    margin-top: clamp-it(0.5rem, 1rem);
    height: 2.75rem;
    border-color: $secondary;
    background-color: $secondary;
    color: $primary;
    line-height: 1.2;

    &:hover {
      border-color: $secondary;
      background-color: transparent;
    }
  }

  .element-renderer {
    margin-top: clamp-it(1.8rem, 2.75rem);

    label {
      margin-bottom: 0;

      + .sub-label {
        margin-bottom: clamp-it(0.5rem, 1rem);
      }
    }

    &--external {
      display: none;

      @include large {
        display: flex;
      }
    }
  }

  .form-control {
    padding: 0 0 0 0.625rem;
    display: flex;
    align-items: center;
    height: clamp-it(2.75rem, 3.75rem);
  }

  .select {
    &__control {
      height: clamp-it(2.75rem, 3.75rem);
      border-radius: 0;
      border-color: $gray-900;
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding-left: 0.625rem;
    }

    &__placeholder {
      @extend %body-large;
      @extend %ellipsis;
      color: $gray-500;
      position: relative;
      top: -1px;
      line-height: 1;
    }

    &__single-value,
    &__input {
      @extend %body-large;
      @extend %ellipsis;
      position: relative;
      top: -1px;
      line-height: 1;
    }
  }

  .form__error {
    top: clamp-it(3rem, 4.25rem);
    line-height: 1.05;
  }

  .alb-datepicker + .form__error,
  .react-select + .form__error {
    top: clamp-it(4.8rem, 6.1rem);
  }

  .input--error {
    svg {
      position: relative;
      right: initial;
    }

    .select__indicator svg {
      bottom: 0;
    }
  }

  .form__textarea {
    &__collapse {
      margin-left: clamp-it(0.9rem, 1.75rem);
      transition: transform 0.3s ease-in;
      position: relative;
      top: 1px;

      &--open {
        transform: rotate(180deg);
      }
    }

    label {
      cursor: pointer;
    }
  }

  .royal-albatros-input .form-input {
    > label {
      line-height: 130%;

      @include large {
        position: absolute;
        bottom: 130%;
      }
    }
  }

  .form-input {
    .custom-checkbox-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $primary;
      padding: clamp-it(0.4rem, 0.8rem) clamp-it(0.65rem, 1rem);
      cursor: pointer;
    }

    .input--hidden {
      display: none;

      + .checkbox__field {
        > svg {
          display: none;
        }
      }

      &:checked + .checkbox__field {
        > svg {
          display: block;
        }
      }
    }
  }

  .royal-albatros-input {
    margin-top: 3.8rem;
    margin-bottom: 1.2rem;

    @include large {
      margin-top: clamp-it(1.8rem, 2.75rem);
      margin-bottom: 0;
    }

    > .form-input {
      position: relative;
      top: 1.3rem;

      @include large {
        top: initial;
      }

      &::after {
        @include fluid-fonts(1.8rem, 2.5rem, 100%, 100%);
        content: '+';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: clamp-it(1.8rem, 2.5rem);
        background-color: $secondary;
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      label {
        position: absolute;
        top: -3.4rem;

        @include large {
          top: initial;
        }
      }

      input {
        background-color: rgba(255, 255, 255, 0.75);
      }
    }
  }
}

.form-input {
  &__collapse {
    @include set-scrollbar($gray-300, $primary-light-green);

    &__option {
      cursor: pointer;

      &:hover {
        background-color: $secondary-dark-sand;
      }

      .postal-code-option {
        padding: clamp-it(0.2rem, 0.35rem) clamp-it(0.4rem, 1rem);
        transition: background-color 0.25s ease-in;

        > span {
          &:first-child {
            font-weight: 500;
          }

          &:last-child {
            @extend %body-small;
            font-weight: 200;
          }
        }
      }
    }
  }

  &__clear {
    color: $gray-500;
    right: clamp-it(0.5rem, 0.9rem);
  }
}

textarea {
  padding: clamp-it(0.45rem, 0.75rem) clamp-it(0.75rem, 1rem);
}

.select__indicator.select__dropdown-indicator {
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: $primary !important;
}
