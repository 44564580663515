@import '../variables';
@import './macros';

h1,
.h1 {
  @extend %albatros-font;
  @include fluid-fonts(2.5rem, 8.125rem, 110%, 110%);
  font-weight: 500;
  margin-bottom: 0;
}

h2,
.h2 {
  @extend %albatros-font;
  @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
  font-weight: 500;
  margin-bottom: 0;
}

h3,
.h3 {
  @extend %albatros-font;
  @include fluid-fonts(1.375rem, 2rem, 160%, 140%);
  margin-bottom: 0;
}

h4,
.h4 {
  @include fluid-fonts(1.25rem, 1.75rem, 160%, 140%);
  font-weight: 400;
  margin-bottom: 0;
}

.h4-medium {
  @extend .h4;
  font-weight: 500;
  margin-bottom: 0;
}

.h4-albatros {
  @extend .h4;
  @extend %albatros-font;
  margin-bottom: 0;
}

h5,
.h5 {
  @include fluid-fonts(1.125rem, 1.375rem, 140%, 170%);
  margin-bottom: 0;
}

.h5-medium {
  @extend .h5;
  font-weight: 500;
  margin-bottom: 0;
}

.h5-albatros {
  @extend .h5;
  @extend %albatros-font;
  margin-bottom: 0;
}
