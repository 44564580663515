@import '../variables';
@import '../utils/functions';
@import '../base/grid';
@import '../base/typography';

.highlight-banner {
  margin-top: 18%;

  @include large {
    margin-top: 0;
  }

  &.grid-section {
    padding: 0;

    @include large {
      padding: $section-spacing-y $section-spacing-x;
    }
  }

  &__container {
    padding: 0 $section-spacing-x $section-spacing-y;
    display: flex;
    justify-content: end;
    position: relative;
    align-items: center;
    flex-direction: column;

    @include large {
      flex-direction: row;
      padding: clamp-it(1rem, 3.5rem) 0;
    }

    &__inner {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;

      @include large {
        width: 77%;
      }
    }
  }

  &__text {
    @extend %albatros-font;
    @include fluid-fonts(1.25rem, 1.75rem, 160%, 140%);
    font-weight: 400;
    margin-bottom: clamp-it(
      1rem,
      2rem,
      map-get($grid-breakpoints, sm),
      map-get($grid-breakpoints, md)
    );
    box-shadow: 0 0 0 0;
    transition: box-shadow 0.3s ease;

    &:not(:last-child) {
      margin-bottom: clamp-it(1.5rem, 2rem);
    }

    &__container {
      order: 2;
      margin-top: clamp-it(1rem, 2.5rem);

      @include large {
        flex: 1 0 48%;
        padding: 0 clamp-it(1rem, 2rem) 0 clamp-it(2rem, 3.5rem);
        order: initial;
        margin-top: initial;
      }
    }
  }

  &__image {
    height: inherit;
    width: 100%;

    &__container {
      width: 100%;
      margin-top: -18%;

      @include large {
        flex: 1 0 52%;
        max-width: 52%;
        width: initial;
        margin-top: 0;
      }

      &__inner {
        position: relative;
      }
    }
  }

  &__button {
    width: 100%;
    padding: 0;

    > a {
      width: 100%;
    }

    @include large {
      width: initial;

      > a {
        width: initial;
      }
    }
  }
}
