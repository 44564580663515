@import '../variables';
@import '../utils/functions';
@import '../base/grid';

$bottomPaddingSpacer: clamp-it(1rem, 1.25rem);

.offcanvas__content {
  &__travel-info {
    text-decoration: underline;
    text-underline-offset: 0.625rem;
    padding-bottom: $bottomPaddingSpacer;
  }

  &__headline {
    padding-bottom: 10px;
  }

  &__hotel-section {
    @include medium {
    }

    &__content {
      text-align: left;
      &__hotel {
        padding-bottom: $bottomPaddingSpacer;

        &:not(:first-child) {
          padding-top: clamp-it(1rem, 1.25rem);
        }

        &--name {
          padding-bottom: $bottomPaddingSpacer;
        }

        &--short-description {
          padding-bottom: $bottomPaddingSpacer;
        }

        &--more-information {
          padding-bottom: $bottomPaddingSpacer;
        }

        &--image-slider {
          height: clamp-it(10rem, 16rem);
        }
      }
    }
  }

  &__price-section {
    @include medium {
      padding-bottom: clamp-it(1.5rem, 2rem);
    }

    &__content {
      text-align: left;
    }
  }

  &__booking-info {
    &__content {
      text-align: left;
    }
  }
}
