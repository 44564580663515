@import '../variables';
@import '../utils/functions';
@import '../utils/mediaQueries';

.excursion {
  &__container {
    @include medium {
      display: flex;
    }

    &__no-print {
      flex: 1 1 75%;
      max-width: 75%;
      position: relative;
    }
  }

  &__list {
    flex: 1 1 25%;
    max-width: 25%;
    text-align: left;

    &__sticky {
      top: 48px;
      position: sticky;
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 90%;
    bottom: 0;
    z-index: -1;
  }

  &__headline {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    display: flex;
    justify-content: center;
    font-weight: 500;
    margin-bottom: clamp-it(1.25rem, 4rem);
  }

  &__name {
    padding: 10px 5px;
    margin-bottom: clamp-it(1rem, 1.5rem);
    display: flex;
    opacity: 50%;
    cursor: pointer;
    transition: opacity 0.25s ease-in;

    &:hover,
    &.active {
      opacity: 100%;
    }
  }

  &__contentBox {
    @include medium {
      display: flex;
      justify-content: space-between;
    }
  }

  &__content {
    flex: 1 0 65%;
    padding: 10px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;

    @include medium {
      opacity: 0;
      padding: 10px clamp-it(1rem, 1.875rem) 0 0;
      flex: 1 0 75%;
    }

    &--visible {
      opacity: 1;
    }

    &--exiting {
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    &__name {
      flex: 0 0 100%;
    }

    &__description {
      position: relative;

      &__remaining {
        padding-bottom: 20px;
        transition: opacity 0.5s ease-in;
        opacity: 1;

        &--hidden {
          opacity: 0;
        }
      }
    }
  }
}
