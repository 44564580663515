@import '../../variables';
@import '.././macros';

.theme-green {
  background-color: $secondary;
  color: $primary;

  a {
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 -1px 0 $primary;
    }
  }

  .accordion > div {
    border-top: 1px solid $primary-light-green;
  }

  .bar {
    background-color: $primary-light-green;
  }

  .card {
    background-color: $secondary;
  }

  .accordion-element {
    border-top-color: $primary;
  }

  .btn.btn-primary {
    background-color: $primary-light-green;
    border: 1px solid $primary-light-green;
    svg {
      margin-left: 10px;
      rect,
      path {
        fill: none;
      }

      path {
        stroke: $white;
      }
      circle {
        stroke: $white;
      }
    }
    &:hover {
      background-color: transparent;
      color: $primary-light-green;
      .fa-button-caret path {
        stroke: $primary-light-green;
      }
      .svg-button-caret path {
        stroke: $primary-light-green;
      }
    }
  }

  .btn.btn-secondary {
    color: $primary-light-green;
    .svg-button-caret path {
      stroke: $primary-light-green;
    }
    &:focus {
      outline: 0.2px solid $primary-light-green;

      > span:first-child {
        border-bottom: 1px solid $primary-light-green;
      }
    }
    &:hover {
      > span:first-child {
        border-bottom: 1px solid $primary-light-green;
      }
    }
  }

  svg {
    rect,
    path {
      fill: $primary;
    }
    path {
      stroke: $primary;
    }
    circle {
      stroke: $primary;
    }
  }

  .drinking-water {
    fill: $secondary;
  }

  .drinking-water {
    stroke: $secondary;
  }

  .separator {
    background-color: $primary;
  }

  .contrast {
    background-color: $secondary-contrast;
  }

  .outline {
    outline: 1px solid $secondary-contrast;
  }

  .outline-double {
    outline: 2px solid $secondary-contrast;
  }

  &.contrast-border,
  .contrast-border {
    border: 1px solid $secondary;
  }

  .hover-icon__button {
    background-color: $secondary-sand;
  }

  button {
    background: none;
    border: 0;
  }

  .image-slider__button,
  .navBtn {
    svg {
      circle {
        fill: $primary;
        stroke: $primary;
        transition: fill 0.25s ease-in;
      }
      path {
        stroke: $white;
        fill: transparent;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $secondary;
          stroke: $primary !important;
        }
        path {
          stroke: $primary !important;
          fill: none;
        }
      }
    }
  }

  .navigation {
    &__cta {
      background: $primary-dark-green;
      color: $secondary-accent-green;
      &:hover {
        text-decoration-color: $white;
      }
      span {
        color: $white;
        svg {
          path {
            fill: $white;
            stroke: $white;
          }
        }
      }
    }
  }
}
