$grid-breakpoints: (
  xs: 0px,
  sm: 320px,
  md: 600px,
  xm-d: 767px,
  xm: 768px,
  lg-d: 991px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

@mixin break($media, $type: min) {
  @media only screen and (#{$type}-width: $media) {
    @content;
  }
}

@mixin xxlarge {
  @include break(map-get($grid-breakpoints, xxl)) {
    @content;
  }
}

@mixin xlarge {
  @include break(map-get($grid-breakpoints, xl)) {
    @content;
  }
}

@mixin large {
  @include break(map-get($grid-breakpoints, lg)) {
    @content;
  }
}

@mixin largedown {
  @include break(map-get($grid-breakpoints, lg-d), max) {
    @content;
  }
}

@mixin medium {
  @include break(map-get($grid-breakpoints, md)) {
    @content;
  }
}

@mixin xmediumdown {
  @include break(map-get($grid-breakpoints, xm-d), max) {
    @content;
  }
}

@mixin xmedium {
  @include break(map-get($grid-breakpoints, xm)) {
    @content;
  }
}

@mixin xmediumdown {
  @include break(map-get($grid-breakpoints, xm-d), max) {
    @content;
  }
}

@mixin small {
  @include break(map-get($grid-breakpoints, sm)) {
    @content;
  }
}
