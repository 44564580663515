@import '../../variables';
@import '.././macros';

.theme-sand {
  @extend .theme-green;

  background-color: $secondary-sand;
  color: $primary;

  .card {
    background-color: $secondary-sand;
  }
  .separator {
    background-color: $primary;
  }

  .accordion-element {
    border-top-color: $primary;
  }

  .dates-prices__link {
    svg {
      path {
        fill: $secondary;
        stroke: $primary;
      }
    }
    .exclamation-circle {
      stroke: transparent;
    }
    .exclamation {
      fill: $primary;
    }
  }

  svg {
    rect,
    path {
      fill: $primary;
    }

    path {
      stroke: $primary;
    }

    circle {
      stroke: $primary;
    }
  }

  .drinking-water {
    fill: $secondary-sand;
  }

  .drinking-water {
    stroke: $secondary-sand;
  }

  .contrast {
    background-color: $secondary-dark-sand;
  }

  .outline {
    outline: 1px solid $secondary-dark-sand;
  }

  .outline-double {
    outline: 2px solid $secondary-dark-sand;
  }

  &.contrast-border,
  .contrast-border {
    border: 1px solid $secondary-sand;
  }

  .image-slider__button,
  .navBtn {
    svg {
      circle {
        fill: $primary;
        stroke: $primary;
        transition: fill 0.25s ease-in;
      }
      path {
        stroke: $white;
        fill: transparent;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $secondary-sand;
          stroke: $primary !important;
        }
        path {
          stroke: $primary !important;
          fill: none;
        }
      }
    }
  }
}
