@import '../utils/mediaQueries';
@import '../utils/functions';

.accommodation {
  &__container {
    position: relative;

    @include medium {
      display: flex;
    }

    .image-slider {
      position: relative;
    }
  }

  &__list {
    flex: 1 0 25%;
    text-align: left;

    &__sticky {
      top: 48px;
      position: sticky;
    }
  }

  &__content {
    transition: height 2s ease-in-out;

    &__container {
      flex: 1 0 65%;
      position: relative;
    }
  }

  &__headline {
    justify-content: center;
    display: flex;
    margin-bottom: clamp-it(1.25rem, 4rem);

    > h2 {
      @include fluid-fonts(1.5rem, 3.5rem, 150%, 140%);
      font-weight: 500;
    }

    > div {
      flex: 1 0 65%;
    }
  }

  &__name {
    padding: 10px 5px;
    margin-bottom: clamp-it(1rem, 1.5rem);
    display: flex;
    opacity: 50%;
    cursor: pointer;
    transition: opacity 0.25s ease-in;

    &.active {
      opacity: 100%;
      cursor: default;
    }
  }
}
