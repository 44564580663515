@import '../utils/functions';
@import '../variables';
@import '../base/macros';

.map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;

    &__wrapper {
      @extend %no-scrollbar;

      width: 100%;
      overflow: auto;

      .hover-icon__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__controls {
    position: absolute;
    right: clamp-it(0.9rem, 1.5rem);
    top: clamp-it(0.9rem, 1.5rem);

    &__zoom__in {
      &:hover {
        cursor: pointer;
      }
    }

    &__zoom__out {
      margin-top: clamp-it(0.4rem, 0.75rem);

      &:hover {
        cursor: pointer;
      }
    }

    &__zoom__out.map__controls__zoom__out,
    &__zoom__in.map__controls__zoom__in {
      &--disabled {
        svg {
          cursor: default;

          path:first-child {
            fill: $gray-900;
          }

          &:hover {
            path:first-child {
              fill: $gray-900;
            }

            path:last-child {
              stroke: $white;
            }
          }
        }
      }
    }

    svg {
      path:first-child {
        fill: $primary-dark-green;
        stroke: none;
        transition: fill 0.25s ease-in;
      }

      path:last-child {
        stroke: $white;
        transition: stroke 0.25s ease-in;
      }

      &:hover {
        path:first-child {
          fill: $secondary-accent-green;
        }

        path:last-child {
          stroke: $primary-dark-green;
        }
      }
    }
  }
}
