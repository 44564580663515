@import '../utils/functions';

.custom-spinner {
  width: clamp-it(7.5rem, 12.125rem);
  height: clamp-it(7.5rem, 12.125rem);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(
    0deg,
    lighten($primary-light-blue, 0.5%) 33%,
    darken($primary-dark-blue, 8%) 100%
  );
  //background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 10%, #ff3d00 42%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: clamp-it(6.9rem, 10.8rem);
    height: clamp-it(6.9rem, 10.8rem);
    border-radius: 50%;
    background: $white;
  }

  &__container {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 58%;
      height: auto;

      path {
        fill: $primary-light-blue-contrast;
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
