@import '../variables';
@import './macros';

.albatros-font {
  @extend %albatros-font;
}

.body {
  @extend %body;
}

.body-medium {
  @extend %body;
  font-weight: 500;
}

.body-large {
  @extend %body-large;
}

.body-large-medium {
  @extend %body-large;
  font-weight: 500;
}

.body-small {
  @extend %body-small;
}

.body-small-medium {
  @extend %body-small;
  font-weight: 500;
}

.label {
  @include fluid-fonts(0.6875rem, 0.875rem, 110%, 110%);
}

.label-bold {
  @extend .label;
  font-weight: 700;
}

.list {
  @include fluid-fonts(1rem, 1.125rem, 110%, 110%);
}

.list-medium {
  @extend .list;
  font-weight: 500;
}
