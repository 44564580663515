@import '../utils/functions';

$itemPadding: clamp-it(1.25rem, 2rem);

.insurance {
  .checkout__headline + p {
    margin-bottom: clamp-it(0.6rem, 1rem);
  }

  .alb-link {
    padding: 0;

    > span:first-child {
      border-bottom: 1px solid currentColor;
    }

    &:hover {
      outline: 0;
    }
  }

  &__loader {
    min-height: 16rem;
  }

  &__travelers {
    margin-top: clamp-it(2.8rem, 3.75rem);

    &__item {
      &:not(:first-child) {
        margin-top: clamp-it(3rem, 5.4rem);
      }
    }

    &__label {
      @include fluid-fonts(1.18rem, 1.375rem, 120%, 130%);
      margin-bottom: clamp-it(1.4rem, 1.85rem);
      font-weight: 500;
    }
  }

  &__items {
    @include large {
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    }
  }

  &__item {
    background-color: $white;
    padding: clamp-it(1rem, 1.4rem);
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
    position: relative;

    &:not(:last-child) {
      margin-bottom: clamp-it(1.375rem, 1.875rem);

      @include large {
        margin-bottom: 0;
      }
    }

    @include large {
      box-shadow: none;
      display: flex;
      padding: 0;
      border-bottom: 1px solid $gray-400;
    }

    &--recommended {
      @include large {
        background-color: $primary-light-blue;
      }
    }

    &__recommended {
      @include fluid-fonts(0.688rem, 1.1rem, 110%, 110%);
      padding: clamp-it(0.5rem, 0.65rem) clamp-it(1.375rem, 1.5rem);
      background-color: $secondary;
      position: relative;
      left: calc($itemPadding * -1);
      top: calc($itemPadding * -1);
      width: calc(100% + $itemPadding * 2);
      text-align: center;

      @include large {
        position: absolute;
        left: 0;
        top: 0;
        width: 1rem;
        transform: translateX(-100%);
        height: 100%;
        text-align: center;
        font-weight: 500;

        > span {
          display: inline-block;
          position: absolute;
          transform: rotate(-90deg) translateY(50%);
          top: 50%;
          left: -50%;
        }
      }
    }

    &__price {
      @include fluid-fonts(1.4rem, 1.5rem, 110%, 115%);
      font-weight: 500;

      &--mobile {
        text-align: center;
        margin-bottom: clamp-it(1rem, 1.4rem);

        > .insurance__item__price__inner {
          @include fluid-fonts(1.25rem, 1.4rem, 120%, 130%);
          display: inline-block;
          font-weight: 500;
          padding: clamp-it(0.5rem, 0.75rem);
          border-bottom: 1.5px solid $gray-400;
        }
      }

      @include large {
        margin-bottom: clamp-it(0.6rem, 1rem);
        text-align: center;
      }
    }

    &__header {
      margin-bottom: clamp-it(2.62rem, 3rem);
      text-align: center;

      @include large {
        text-align: left;
        margin-bottom: 0;
        padding: clamp-it(1rem, 1.4rem) 0 clamp-it(1rem, 1.4rem) clamp-it(1rem, 1.4rem);
        flex: 1 1 39%;
        max-width: 39%;
      }

      &--full {
        @include large {
          flex: 1 1 79%;
          max-width: 79%;
        }
      }
    }

    &__headline {
      @include fluid-fonts(1.25rem, 1.5rem, 120%, 140%);
      font-weight: 500;
      margin-bottom: clamp-it(0.75rem, 1.25rem);

      &--sub {
        @include fluid-fonts(0.875rem, 1rem, 120%, 140%);
        font-weight: 500;
      }
    }

    &__features {
      li {
        @include fluid-fonts(0.875rem, 1rem, 140%, 155%);
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1.25rem;

          @include large {
            margin-bottom: 1rem;
          }
        }

        svg {
          margin-right: clamp-it(0.685rem, 1rem);
        }
      }

      @include large {
        flex: 1 1 40%;
        max-width: 40%;
        padding: clamp-it(1rem, 1.4rem);
      }
    }

    &__footer {
      .selection-item__select {
        margin-top: clamp-it(1.8rem, 2.5rem);

        > button {
          width: 100%;
          padding-top: clamp-it(0.65rem, 0.85rem);
          padding-bottom: clamp-it(0.65rem, 0.85rem);
          justify-content: space-between;
        }
      }

      @include large {
        border-left: 1px solid $gray-400;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 21%;
        max-width: 21%;
        padding: clamp-it(1rem, 1.4rem);
      }
    }
  }

  &__footer {
    padding: clamp-it(0.65rem, 0.82rem) 0;

    @include large {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      background-color: $white;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.05);
      padding: clamp-it(0.65rem, 0.82rem) clamp-it(1rem, 5rem);
    }

    &__link.alb-link {
      display: block;
      margin-bottom: 0.5rem;

      &:hover {
        text-decoration: underline;
      }

      > span:first-child {
        @include fluid-fonts(0.7rem, 0.813rem);
        border-bottom: 0;
      }

      @include large {
        margin-bottom: 0;
        margin-right: clamp-it(1rem, 2.5rem);
      }
    }

    &__supplier {
      > div:first-child {
        @include fluid-fonts(0.6rem, 0.688rem, 100%, 100%);
        margin-bottom: 0.32rem;
      }

      img {
        height: clamp-it(1.6rem, 2.1rem);
        width: auto;
      }
    }
  }
}
