@import '../../variables';
@import '.././macros';

.theme-dark-green {
  background-color: $primary;
  color: $white;

  .bar {
    background-color: $white;
  }

  .card {
    background-color: $primary;
  }

  a {
    color: $white;
    &:hover {
      text-decoration: none;
      box-shadow: inset 0 -1px 0 #fff;
    }
    span:not([class^='contact-bar']):not([class^='ignore-style']) {
      color: $black;
      svg {
        path {
          fill: $black;
          stroke: $black;
        }
      }
    }
  }

  hr {
    background-color: $white;
  }

  .separator {
    background-color: $white;
  }

  .dates-prices__link {
    svg {
      path {
        fill: $secondary;
        stroke: $primary;
      }
    }
    .exclamation-circle {
      stroke: transparent;
    }
    .exclamation {
      fill: $primary;
    }
  }

  svg {
    rect,
    path {
      fill: $white;
    }

    path {
      stroke: $white;
    }

    circle {
      stroke: $white;
    }
  }

  .drinking-water {
    fill: $black;
  }

  .drinking-water {
    stroke: $black;
  }

  .btn.btn-primary {
    background-color: $secondary-accent-green;
    border: 1px solid $secondary-accent-green;
    color: $primary-light-green;
    transition: all 0.3s linear;

    svg {
      margin-left: 10px;
      rect,
      path {
        fill: none;
      }

      path {
        stroke: $primary-light-green;
      }

      circle {
        stroke: $primary-light-green;
      }
    }

    .svg-button-caret path {
      stroke: $primary;
    }

    &:hover {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
      > span {
        color: $white;
      }

      .fa-button-caret path {
        stroke: $white;
      }

      .svg-button-caret path {
        stroke: $white;
      }
    }
  }

  .btn.btn-secondary {
    color: $white;

    .svg-button-caret path {
      stroke: $white;
    }

    &:focus {
      outline: 0.2px solid $white;

      > span:first-child {
        border-bottom: 1px solid $white;
      }
    }

    &:hover {
      > span:first-child {
        border-bottom: 1px solid $white;
      }
    }
  }

  .image-slider__button,
  .navBtn {
    svg {
      circle {
        fill: $secondary;
        stroke: $secondary;
        transition: fill 0.25s ease-in;
      }
      path {
        fill: transparent;
        stroke: $primary;
        transition: stroke 0.25s ease-in;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $primary;
          stroke: $secondary;
          transition: fill 0.25s ease-in;
        }
        path {
          fill: none;
          stroke: $white;
          transition: stroke 0.25s ease-in;
        }
      }
    }
  }

  .contrast {
    background-color: $primary-light-green;
  }

  .outline {
    outline: 1px solid $primary-light-green;
  }

  .outline-double {
    outline: 2px solid $primary-light-green;
  }

  &.contrast-border,
  .contrast-border {
    border: 1px solid $primary;
  }

  .navigation {
    &__item {
      &--active,
      &:hover {
        text-decoration: none;
        box-shadow: inset 0 -2px 0 $white;
        font-weight: 700;
      }
    }
  }
}
